@font-face {
  font-family: 'Proxima Nova';
  src: url("../../fonts/proxima-nova/ProximaNova-Extrabld.eot");
  src: url("../../fonts/proxima-nova/ProximaNova-Extrabld.eot?#iefix") format("embedded-opentype"), url("../../fonts/proxima-nova/ProximaNova-Extrabld.woff2") format("woff2"), url("../../fonts/proxima-nova/ProximaNova-Extrabld.woff") format("woff"), url("../../fonts/proxima-nova/ProximaNova-Extrabld.ttf") format("truetype"), url("../../fonts/proxima-nova/ProximaNova-Extrabld.svg#ProximaNova-Extrabld") format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url("../../fonts/proxima-nova/ProximaNova-Semibold.eot");
  src: url("../../fonts/proxima-nova/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"), url("../../fonts/proxima-nova/ProximaNova-Semibold.woff2") format("woff2"), url("../../fonts/proxima-nova/ProximaNova-Semibold.woff") format("woff"), url("../../fonts/proxima-nova/ProximaNova-Semibold.ttf") format("truetype"), url("../../fonts/proxima-nova/ProximaNova-Semibold.svg#ProximaNova-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url("../../fonts/proxima-nova/ProximaNova-Bold.eot");
  src: url("../../fonts/proxima-nova/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/proxima-nova/ProximaNova-Bold.woff2") format("woff2"), url("../../fonts/proxima-nova/ProximaNova-Bold.woff") format("woff"), url("../../fonts/proxima-nova/ProximaNova-Bold.ttf") format("truetype"), url("../../fonts/proxima-nova/ProximaNova-Bold.svg#ProximaNova-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url("../../fonts/proxima-nova/ProximaNova-Regular.eot");
  src: url("../../fonts/proxima-nova/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/proxima-nova/ProximaNova-Regular.woff2") format("woff2"), url("../../fonts/proxima-nova/ProximaNova-Regular.woff") format("woff"), url("../../fonts/proxima-nova/ProximaNova-Regular.ttf") format("truetype"), url("../../fonts/proxima-nova/ProximaNova-Regular.svg#ProximaNova-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url("../../fonts/proxima-nova/ProximaNova-Light.eot");
  src: url("../../fonts/proxima-nova/ProximaNova-Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/proxima-nova/ProximaNova-Light.woff2") format("woff2"), url("../../fonts/proxima-nova/ProximaNova-Light.woff") format("woff"), url("../../fonts/proxima-nova/ProximaNova-Light.ttf") format("truetype"), url("../../fonts/proxima-nova/ProximaNova-Light.svg#ProximaNova-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url("../../fonts/proxima-nova/ProximaNova-Thin.eot");
  src: url("../../fonts/proxima-nova/ProximaNova-Thin.eot?#iefix") format("embedded-opentype"), url("../../fonts/proxima-nova/ProximaNova-Thin.woff2") format("woff2"), url("../../fonts/proxima-nova/ProximaNova-Thin.woff") format("woff"), url("../../fonts/proxima-nova/ProximaNova-Thin.ttf") format("truetype"), url("../../fonts/proxima-nova/ProximaNova-Thin.svg#ProximaNova-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
}
.font-proxima-nova {
  font-family: 'Proxima Nova';
}
._primary {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #ec6ead;
}
._text-primary {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #ec6ead;
}
._hover-primary:hover {
  background: #ec6ead;
}
._secondary {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #3494e6;
}
._text-secondary {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #3494e6;
}
._hover-secondary:hover {
  background: #3494e6;
}
._aqua {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #7fdfff;
}
._text-aqua {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #7fdfff;
}
._hover-aqua:hover {
  background: #7fdfff;
}
._blue {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #0074d9;
}
._text-blue {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #0074d9;
}
._hover-blue:hover {
  background: #0074d9;
}
._navy {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #001f3f;
}
._text-navy {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #001f3f;
}
._hover-navy:hover {
  background: #001f3f;
}
._teal {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #39cccc;
}
._text-teal {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #39cccc;
}
._hover-teal:hover {
  background: #39cccc;
}
._green {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #2ecc40;
}
._text-green {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #2ecc40;
}
._hover-green:hover {
  background: #2ecc40;
}
._olive {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #3d9970;
}
._text-olive {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #3d9970;
}
._hover-olive:hover {
  background: #3d9970;
}
._lime {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #01ff70;
}
._text-lime {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #01ff70;
}
._hover-lime:hover {
  background: #01ff70;
}
._yellow {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #ffdc00;
}
._text-yellow {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #ffdc00;
}
._hover-yellow:hover {
  background: #ffdc00;
}
._orange {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #ff851b;
}
._text-orange {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #ff851b;
}
._hover-orange:hover {
  background: #ff851b;
}
._red {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #ff4136;
}
._text-red {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #ff4136;
}
._hover-red:hover {
  background: #ff4136;
}
._fuchsia {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #f012be;
}
._text-fuchsia {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #f012be;
}
._hover-fuchsia:hover {
  background: #f012be;
}
._purple {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #b10dc9;
}
._text-purple {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #b10dc9;
}
._hover-purple:hover {
  background: #b10dc9;
}
._maroon {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #85144b;
}
._text-maroon {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #85144b;
}
._hover-maroon:hover {
  background: #85144b;
}
._white {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #fff;
}
._text-white {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #fff;
}
._hover-white:hover {
  background: #fff;
}
._silver {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #f2f2f2;
}
._text-silver {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #f2f2f2;
}
._hover-silver:hover {
  background: #f2f2f2;
}
._darksilver {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #d1d1d1;
}
._text-darksilver {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #d1d1d1;
}
._hover-darksilver:hover {
  background: #d1d1d1;
}
._gray {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #aaa;
}
._text-gray {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #aaa;
}
._hover-gray:hover {
  background: #aaa;
}
._lightgray {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #ccc;
}
._text-lightgray {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #ccc;
}
._hover-lightgray:hover {
  background: #ccc;
}
._concrete {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #1d1d1e;
}
._text-concrete {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #1d1d1e;
}
._hover-concrete:hover {
  background: #1d1d1e;
}
._asphalt {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #3b3b3b;
}
._text-asphalt {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #3b3b3b;
}
._hover-asphalt:hover {
  background: #3b3b3b;
}
._black {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #111;
}
._text-black {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #111;
}
._hover-black:hover {
  background: #111;
}
._primary {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #ec6ead;
}
._text-primary {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #ec6ead;
}
._hover-primary:hover {
  background: #ec6ead;
}
._secondary {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #3494e6;
}
._text-secondary {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #3494e6;
}
._hover-secondary:hover {
  background: #3494e6;
}
._success {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #3d9970;
}
._text-success {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #3d9970;
}
._hover-success:hover {
  background: #3d9970;
}
._danger {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #ff4136;
}
._text-danger {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #ff4136;
}
._hover-danger:hover {
  background: #ff4136;
}
._warning {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #ff851b;
}
._text-warning {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #ff851b;
}
._hover-warning:hover {
  background: #ff851b;
}
._info {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: #0074d9;
}
._text-info {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #0074d9;
}
._hover-info:hover {
  background: #0074d9;
}
._margin-1x {
  margin: 1rem;
}
._margin-vertical-1x {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
._margin-top-1x {
  margin-top: 1rem;
}
._margin-bottom-1x {
  margin-bottom: 1rem;
}
._margin-horizontal-1x {
  margin-left: 1rem;
  margin-right: 1rem;
}
._margin-left-1x {
  margin-left: 1rem;
}
._margin-right-1x {
  margin-right: 1rem;
}
._padding-1x {
  padding: 1rem;
}
._padding-vertical-1x {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
._padding-top-1x {
  padding-top: 1rem;
}
._padding-bottom-1x {
  padding-bottom: 1rem;
}
._padding-horizontal-1x {
  padding-left: 1rem;
  padding-right: 1rem;
}
._padding-left-1x {
  padding-left: 1rem;
}
._padding-right-1x {
  padding-right: 1rem;
}
._margin-2x {
  margin: 2rem;
}
._margin-vertical-2x {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
._margin-top-2x {
  margin-top: 2rem;
}
._margin-bottom-2x {
  margin-bottom: 2rem;
}
._margin-horizontal-2x {
  margin-left: 2rem;
  margin-right: 2rem;
}
._margin-left-2x {
  margin-left: 2rem;
}
._margin-right-2x {
  margin-right: 2rem;
}
._padding-2x {
  padding: 2rem;
}
._padding-vertical-2x {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
._padding-top-2x {
  padding-top: 2rem;
}
._padding-bottom-2x {
  padding-bottom: 2rem;
}
._padding-horizontal-2x {
  padding-left: 2rem;
  padding-right: 2rem;
}
._padding-left-2x {
  padding-left: 2rem;
}
._padding-right-2x {
  padding-right: 2rem;
}
._margin-3x {
  margin: 3rem;
}
._margin-vertical-3x {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
._margin-top-3x {
  margin-top: 3rem;
}
._margin-bottom-3x {
  margin-bottom: 3rem;
}
._margin-horizontal-3x {
  margin-left: 3rem;
  margin-right: 3rem;
}
._margin-left-3x {
  margin-left: 3rem;
}
._margin-right-3x {
  margin-right: 3rem;
}
._padding-3x {
  padding: 3rem;
}
._padding-vertical-3x {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
._padding-top-3x {
  padding-top: 3rem;
}
._padding-bottom-3x {
  padding-bottom: 3rem;
}
._padding-horizontal-3x {
  padding-left: 3rem;
  padding-right: 3rem;
}
._padding-left-3x {
  padding-left: 3rem;
}
._padding-right-3x {
  padding-right: 3rem;
}
._margin-4x {
  margin: 4rem;
}
._margin-vertical-4x {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
._margin-top-4x {
  margin-top: 4rem;
}
._margin-bottom-4x {
  margin-bottom: 4rem;
}
._margin-horizontal-4x {
  margin-left: 4rem;
  margin-right: 4rem;
}
._margin-left-4x {
  margin-left: 4rem;
}
._margin-right-4x {
  margin-right: 4rem;
}
._padding-4x {
  padding: 4rem;
}
._padding-vertical-4x {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
._padding-top-4x {
  padding-top: 4rem;
}
._padding-bottom-4x {
  padding-bottom: 4rem;
}
._padding-horizontal-4x {
  padding-left: 4rem;
  padding-right: 4rem;
}
._padding-left-4x {
  padding-left: 4rem;
}
._padding-right-4x {
  padding-right: 4rem;
}
._margin-5x {
  margin: 5rem;
}
._margin-vertical-5x {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
._margin-top-5x {
  margin-top: 5rem;
}
._margin-bottom-5x {
  margin-bottom: 5rem;
}
._margin-horizontal-5x {
  margin-left: 5rem;
  margin-right: 5rem;
}
._margin-left-5x {
  margin-left: 5rem;
}
._margin-right-5x {
  margin-right: 5rem;
}
._padding-5x {
  padding: 5rem;
}
._padding-vertical-5x {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
._padding-top-5x {
  padding-top: 5rem;
}
._padding-bottom-5x {
  padding-bottom: 5rem;
}
._padding-horizontal-5x {
  padding-left: 5rem;
  padding-right: 5rem;
}
._padding-left-5x {
  padding-left: 5rem;
}
._padding-right-5x {
  padding-right: 5rem;
}
._margin-6x {
  margin: 6rem;
}
._margin-vertical-6x {
  margin-top: 6rem;
  margin-bottom: 6rem;
}
._margin-top-6x {
  margin-top: 6rem;
}
._margin-bottom-6x {
  margin-bottom: 6rem;
}
._margin-horizontal-6x {
  margin-left: 6rem;
  margin-right: 6rem;
}
._margin-left-6x {
  margin-left: 6rem;
}
._margin-right-6x {
  margin-right: 6rem;
}
._padding-6x {
  padding: 6rem;
}
._padding-vertical-6x {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
._padding-top-6x {
  padding-top: 6rem;
}
._padding-bottom-6x {
  padding-bottom: 6rem;
}
._padding-horizontal-6x {
  padding-left: 6rem;
  padding-right: 6rem;
}
._padding-left-6x {
  padding-left: 6rem;
}
._padding-right-6x {
  padding-right: 6rem;
}
._margin-7x {
  margin: 7rem;
}
._margin-vertical-7x {
  margin-top: 7rem;
  margin-bottom: 7rem;
}
._margin-top-7x {
  margin-top: 7rem;
}
._margin-bottom-7x {
  margin-bottom: 7rem;
}
._margin-horizontal-7x {
  margin-left: 7rem;
  margin-right: 7rem;
}
._margin-left-7x {
  margin-left: 7rem;
}
._margin-right-7x {
  margin-right: 7rem;
}
._padding-7x {
  padding: 7rem;
}
._padding-vertical-7x {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
._padding-top-7x {
  padding-top: 7rem;
}
._padding-bottom-7x {
  padding-bottom: 7rem;
}
._padding-horizontal-7x {
  padding-left: 7rem;
  padding-right: 7rem;
}
._padding-left-7x {
  padding-left: 7rem;
}
._padding-right-7x {
  padding-right: 7rem;
}
._margin-8x {
  margin: 8rem;
}
._margin-vertical-8x {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
._margin-top-8x {
  margin-top: 8rem;
}
._margin-bottom-8x {
  margin-bottom: 8rem;
}
._margin-horizontal-8x {
  margin-left: 8rem;
  margin-right: 8rem;
}
._margin-left-8x {
  margin-left: 8rem;
}
._margin-right-8x {
  margin-right: 8rem;
}
._padding-8x {
  padding: 8rem;
}
._padding-vertical-8x {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
._padding-top-8x {
  padding-top: 8rem;
}
._padding-bottom-8x {
  padding-bottom: 8rem;
}
._padding-horizontal-8x {
  padding-left: 8rem;
  padding-right: 8rem;
}
._padding-left-8x {
  padding-left: 8rem;
}
._padding-right-8x {
  padding-right: 8rem;
}
._margin-9x {
  margin: 9rem;
}
._margin-vertical-9x {
  margin-top: 9rem;
  margin-bottom: 9rem;
}
._margin-top-9x {
  margin-top: 9rem;
}
._margin-bottom-9x {
  margin-bottom: 9rem;
}
._margin-horizontal-9x {
  margin-left: 9rem;
  margin-right: 9rem;
}
._margin-left-9x {
  margin-left: 9rem;
}
._margin-right-9x {
  margin-right: 9rem;
}
._padding-9x {
  padding: 9rem;
}
._padding-vertical-9x {
  padding-top: 9rem;
  padding-bottom: 9rem;
}
._padding-top-9x {
  padding-top: 9rem;
}
._padding-bottom-9x {
  padding-bottom: 9rem;
}
._padding-horizontal-9x {
  padding-left: 9rem;
  padding-right: 9rem;
}
._padding-left-9x {
  padding-left: 9rem;
}
._padding-right-9x {
  padding-right: 9rem;
}
._margin-10x {
  margin: 10rem;
}
._margin-vertical-10x {
  margin-top: 10rem;
  margin-bottom: 10rem;
}
._margin-top-10x {
  margin-top: 10rem;
}
._margin-bottom-10x {
  margin-bottom: 10rem;
}
._margin-horizontal-10x {
  margin-left: 10rem;
  margin-right: 10rem;
}
._margin-left-10x {
  margin-left: 10rem;
}
._margin-right-10x {
  margin-right: 10rem;
}
._padding-10x {
  padding: 10rem;
}
._padding-vertical-10x {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
._padding-top-10x {
  padding-top: 10rem;
}
._padding-bottom-10x {
  padding-bottom: 10rem;
}
._padding-horizontal-10x {
  padding-left: 10rem;
  padding-right: 10rem;
}
._padding-left-10x {
  padding-left: 10rem;
}
._padding-right-10x {
  padding-right: 10rem;
}
._margin-sm {
  margin: 0.5rem;
}
._margin-vertical-sm {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
._margin-top-sm {
  margin-top: 0.5rem;
}
._margin-bottom-sm {
  margin-bottom: 0.5rem;
}
._margin-horizontal-sm {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
._margin-left-sm {
  margin-left: 0.5rem;
}
._margin-right-sm {
  margin-right: 0.5rem;
}
._padding-sm {
  padding: 0.5rem;
}
._padding-vertical-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
._padding-top-sm {
  padding-top: 0.5rem;
}
._padding-bottom-sm {
  padding-bottom: 0.5rem;
}
._padding-horizontal-sm {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
._padding-left-sm {
  padding-left: 0.5rem;
}
._padding-right-sm {
  padding-right: 0.5rem;
}
._margin-none {
  margin: 0 !important;
}
._margin-left-none {
  margin-left: 0 !important;
}
._margin-right-none {
  margin-right: 0 !important;
}
._margin-top-none {
  margin-top: 0 !important;
}
._margin-bottom-none {
  margin-bottom: 0 !important;
}
._padding-none {
  padding: 0 !important;
}
._padding-left-none {
  padding-left: 0 !important;
}
._padding-right-none {
  padding-right: 0 !important;
}
._padding-top-none {
  padding-top: 0 !important;
}
._padding-bottom-none {
  padding-bottom: 0 !important;
}
._full-width {
  width: 100% !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
img._full-width {
  height: auto;
}
._max-full-width {
  max-width: 100% !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
._full-height {
  height: 100% !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
img._full-height {
  width: auto;
}
._max-full-height {
  max-height: 100% !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
._hidden {
  display: none;
}
body {
  background-color: #9081c9;
  background-image: -webkit-linear-gradient(top left, #ec6ead, #3494e6);
  background-image: -moz-linear-gradient(top left, #ec6ead, #3494e6);
  background-image: -o-linear-gradient(top left, #ec6ead, #3494e6);
  background-image: -ms-linear-gradient(top left, #ec6ead, #3494e6);
  background-image: linear-gradient(to bottom right, #ec6ead, #3494e6);
  background-repeat: no-repeat;
  background-attachment: fixed;
}
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: -webkit-linear-gradient(top, rgba(144,129,201,0), #3494e6);
  background: -moz-linear-gradient(top, rgba(144,129,201,0), #3494e6);
  background: -o-linear-gradient(top, rgba(144,129,201,0), #3494e6);
  background: -ms-linear-gradient(top, rgba(144,129,201,0), #3494e6);
  background: linear-gradient(to bottom, rgba(144,129,201,0), #3494e6);
}
::-webkit-scrollbar-thumb {
  -webkit-background-clip: content;
  -moz-background-clip: content;
  background-clip: content-box;
  background-color: rgba(0,0,0,0.75);
  border-radius: 3px;
}
.content {
  width: 100%;
  display: block;
  position: relative;
  margin-top: 70px;
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
}
@media screen and (max-width: 991px) {
  .content.-collapsible.-with-left-sidebar {
    padding-left: 60px;
    padding-right: 0;
  }
  .content.-collapsible.-with-right-sidebar {
    padding-right: 60px;
    padding-left: 0;
  }
}
.content.-with-left-sidebar {
  padding-left: 296px;
}
.content.-with-right-sidebar {
  padding-right: 296px;
}
.content.-with-top-navbar {
  margin-top: 70px;
  margin-bottom: 0;
}
.content.-with-bottom-navbar {
  margin-top: 0;
  margin-bottom: 70px;
}
.content.-dark {
  color: rgba(255,255,255,0.85);
}
.content.-light {
  color: rgba(0,0,0,0.75);
}
aside,
.aside {
  z-index: 2;
  position: absolute;
  display: block;
  top: 0;
  width: 280px;
  height: 100%;
}
aside.-with-left-sidebar.-left,
.aside.-with-left-sidebar.-left {
  left: 280px;
}
aside.-with-left-sidebar.-right,
.aside.-with-left-sidebar.-right {
  right: 0;
}
aside.-with-right-sidebar.-left,
.aside.-with-right-sidebar.-left {
  left: 0;
}
aside.-with-right-sidebar.-right,
.aside.-with-right-sidebar.-right {
  right: 280px;
}
aside.-dark,
.aside.-dark {
  background: rgba(0,0,0,0.25);
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -o-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -ms-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: linear-gradient(to bottom right, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
}
aside.-light,
.aside.-light {
  background: rgba(255,255,255,0.15);
}
aside .aside-content,
.aside .aside-content {
  padding: 1rem;
}
@media screen and (max-width: 991px) {
  aside.-collapse-with-sidebar.-with-left-sidebar.-left,
  .aside.-collapse-with-sidebar.-with-left-sidebar.-left {
    left: auto;
  }
  aside.-collapse-with-sidebar.-with-right-sidebar.-right,
  .aside.-collapse-with-sidebar.-with-right-sidebar.-right {
    right: auto;
  }
  aside.-collapsible,
  .aside.-collapsible {
    margin-top: -16px;
    margin-bottom: 16px;
    position: relative;
    width: 100%;
    height: auto;
  }
  aside.-collapsible.-left,
  .aside.-collapsible.-left {
    left: auto;
  }
  aside.-collapsible.-right,
  .aside.-collapsible.-right {
    right: auto;
  }
}
.beside {
  z-index: 1;
  width: 100%;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.beside.-left-aside {
  padding-left: 248px;
  margin-left: 16px;
}
.beside.-right-aside {
  padding-right: 248px;
  margin-right: 16px;
}
@media screen and (max-width: 991px) {
  .beside.-collapse-with-sidebar.-left-aside {
    padding-left: 280px;
    margin-left: 0;
  }
  .beside.-collapse-with-sidebar.-right-aside {
    padding-right: 280px;
    margin-right: 0;
  }
  .beside.-collapsible.-left-aside {
    padding-left: 0;
    margin-left: 0;
  }
  .beside.-collapsible.-right-aside {
    padding-right: 0;
    margin-right: 0;
  }
}
.pe {
  display: inline-block;
}
.pe.-rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.pe.-rotate-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.pe.-rotate-270 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.btn-list {
  margin-bottom: 1rem;
  zoom: 1;
}
.btn-list:before,
.btn-list:after {
  content: "";
  display: table;
}
.btn-list:after {
  clear: both;
}
.btn-list:last-child {
  margin-bottom: 0;
}
.btn-list > .btn,
.btn-list > .btn-group {
  width: 24%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-right: 1%;
  float: left;
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-list > .btn,
  .btn-list > .btn-group {
    width: 48%;
    margin-right: 2%;
    margin-bottom: 1rem;
  }
  .btn-list > .btn:nth-child(4n+3),
  .btn-list > .btn-group:nth-child(4n+3),
  .btn-list > .btn:nth-child(4n+4),
  .btn-list > .btn-group:nth-child(4n+4) {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 991px) {
  .btn-list > .btn,
  .btn-list > .btn-group {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
.btn-list-unstyled > .btn,
.btn-list-unstyled > .btn-group {
  margin-right: 1rem;
  margin-bottom: 1rem;
}
@media screen and (max-width: 991px) {
  .btn-list-unstyled > .btn,
  .btn-list-unstyled > .btn-group {
    width: 100%;
    margin-right: 0;
  }
}
.dropdown-preview {
  display: inline-block;
  float: left;
  margin: 0 1rem 1rem 0;
}
.dropdown-preview .dropdown-menu {
  z-index: 0;
  display: block;
  position: relative;
}
.icon-grid {
  display: block;
  margin: 0 auto;
  position: relative;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  zoom: 1;
}
.icon-grid:before,
.icon-grid:after {
  content: "";
  display: table;
}
.icon-grid:after {
  clear: both;
}
.icon-grid .icon-grid-item {
  display: inline-block;
  float: left;
  width: 170px;
  height: 170px;
  padding: 3rem 1rem;
  text-align: center;
  background: rgba(0,0,0,0.25);
  border: 1px solid rgba(0,0,0,0.25);
}
.icon-grid .icon-grid-item i {
  margin-bottom: 0.5rem;
  font-size: 2rem;
}
.icon-grid .icon-grid-item span {
  display: block;
}
#grid-presentation .row,
#grid-presentation .col {
  color: rgba(255,255,255,0.85);
  border: 1px dashed rgba(0,0,0,0.25);
  background: rgba(0,0,0,0.125);
  text-align: center;
}
#grid-presentation .col {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
#grid-presentation .row {
  margin-bottom: 3rem;
}
.sparklines-table th:nth-child(2n+2),
.sparklines-table td:nth-child(2n+2) {
  text-align: right;
}
.tasks-calendar-wrapper .tasks-calendar-list {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  padding: 1rem;
  width: 320px;
  height: 100%;
  background: rgba(0,0,0,0.125);
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background: -moz-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background: -o-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background: -ms-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background: linear-gradient(to bottom, rgba(0,0,0,0.25), rgba(0,0,0,0));
  overflow: auto;
}
@media screen and (min-width: 1840px) {
  .tasks-calendar-wrapper .tasks-calendar-list {
    width: 520px;
  }
}
@media screen and (max-width: 767px) {
  .tasks-calendar-wrapper .tasks-calendar-list {
    position: relative;
    width: 100%;
    margin-top: 2rem;
  }
}
.tasks-calendar-wrapper .tasks-calendar-list .tasks-calendar-day {
  font-size: 1.5rem;
}
.tasks-calendar-wrapper .tasks-calendar-list .tasks-calendar-date {
  font-size: 0.9rem;
  margin-top: -0.25rem;
  color: rgba(255,255,255,0.5);
}
.tasks-calendar-wrapper .tasks-calendar-list form {
  position: relative;
  margin-top: 1rem;
}
.tasks-calendar-wrapper .tasks-calendar-list form button {
  position: absolute;
  right: 0;
  top: 0.25rem;
  border: 0;
  background: transparent;
  color: rgba(255,255,255,0.5);
  font-size: 1.25rem;
  -webkit-transition: color 0.5s ease;
  -moz-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  -ms-transition: color 0.5s ease;
  transition: color 0.5s ease;
}
.tasks-calendar-wrapper .tasks-calendar-list .tasks-calendar-add-new {
  background: transparent;
  color: #fff;
  border: 0;
  width: 100%;
  padding: 0.5rem 0.25rem;
  border-bottom: 1px solid rgba(255,255,255,0.15);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.tasks-calendar-wrapper .tasks-calendar-list .tasks-calendar-add-new:active,
.tasks-calendar-wrapper .tasks-calendar-list .tasks-calendar-add-new:focus {
  outline: 0;
  border-bottom-color: #fff;
}
.tasks-calendar-wrapper .tasks-calendar-list .tasks-calendar-add-new:active + button,
.tasks-calendar-wrapper .tasks-calendar-list .tasks-calendar-add-new:focus + button {
  color: #fff;
}
.tasks-calendar-wrapper .tasks-calendar-list ul {
  list-style: none;
  padding: 0;
  margin-top: 1.5rem;
}
.tasks-calendar-wrapper .tasks-calendar-list ul li {
  margin-bottom: 0.5rem;
}
.tasks-calendar-wrapper .tasks-calendar-list ul li span {
  float: right;
  color: rgba(255,255,255,0.5);
}
.tasks-calendar-wrapper .tasks-calendar {
  width: 100%;
  height: auto;
  padding-left: 320px;
  position: relative;
  display: block;
}
@media screen and (min-width: 1840px) {
  .tasks-calendar-wrapper .tasks-calendar {
    padding-left: 520px;
  }
}
@media screen and (max-width: 767px) {
  .tasks-calendar-wrapper .tasks-calendar {
    padding-left: 0;
  }
}
.tasks-calendar-wrapper .tasks-calendar .datepicker-inline,
.tasks-calendar-wrapper .tasks-calendar .datepicker-inline table {
  width: 100%;
}
@media screen and (min-width: 1400px) {
  .dashboard-table-col {
    width: 50% !important;
  }
}
[data-change-theme] {
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-position: 0% 0%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  margin: 0.25rem;
  font-size: 0.8rem;
  max-height: 53px;
}
[data-change-theme]:hover,
[data-change-theme]:focus,
[data-change-theme]:active {
  -webkit-background-size: 150% 150%;
  -moz-background-size: 150% 150%;
  background-size: 150% 150%;
  background-position: 0% -100%;
}
#chart-project-progress {
  height: 300px;
}
@media screen and (max-width: 1199px) {
  #chart-project-progress {
    height: 360px;
  }
}
@media screen and (max-width: 991px) {
  #chart-project-progress {
    height: 300px;
  }
}
@media screen and (max-width: 767px) {
  #chart-project-progress {
    height: 240px;
  }
}
@media screen and (max-width: 575px) {
  #chart-project-progress {
    height: 225px;
  }
}
[data-change-theme="volta"] {
  background-image: -webkit-linear-gradient(left, #c04848, #480048);
  background-image: -moz-linear-gradient(left, #c04848, #480048);
  background-image: -o-linear-gradient(left, #c04848, #480048);
  background-image: -ms-linear-gradient(left, #c04848, #480048);
  background-image: linear-gradient(to right, #c04848, #480048);
}
[data-change-theme="purple-bliss"] {
  background-image: -webkit-linear-gradient(left, #360033, #0b8793);
  background-image: -moz-linear-gradient(left, #360033, #0b8793);
  background-image: -o-linear-gradient(left, #360033, #0b8793);
  background-image: -ms-linear-gradient(left, #360033, #0b8793);
  background-image: linear-gradient(to right, #360033, #0b8793);
}
[data-change-theme="love-couple"] {
  background-image: -webkit-linear-gradient(left, #89253e, #3a6186);
  background-image: -moz-linear-gradient(left, #89253e, #3a6186);
  background-image: -o-linear-gradient(left, #89253e, #3a6186);
  background-image: -ms-linear-gradient(left, #89253e, #3a6186);
  background-image: linear-gradient(to right, #89253e, #3a6186);
}
[data-change-theme="deep-space"] {
  background-image: -webkit-linear-gradient(left, #434343, #000);
  background-image: -moz-linear-gradient(left, #434343, #000);
  background-image: -o-linear-gradient(left, #434343, #000);
  background-image: -ms-linear-gradient(left, #434343, #000);
  background-image: linear-gradient(to right, #434343, #000);
}
[data-change-theme="from-beyond"] {
  background-image: -webkit-linear-gradient(left, #838181, #000c40);
  background-image: -moz-linear-gradient(left, #838181, #000c40);
  background-image: -o-linear-gradient(left, #838181, #000c40);
  background-image: -ms-linear-gradient(left, #838181, #000c40);
  background-image: linear-gradient(to right, #838181, #000c40);
}
[data-change-theme="curiosity-blue"] {
  background-image: -webkit-linear-gradient(left, #3d72b4, #525252);
  background-image: -moz-linear-gradient(left, #3d72b4, #525252);
  background-image: -o-linear-gradient(left, #3d72b4, #525252);
  background-image: -ms-linear-gradient(left, #3d72b4, #525252);
  background-image: linear-gradient(to right, #3d72b4, #525252);
}
[data-change-theme="orca"] {
  background-image: -webkit-linear-gradient(left, #093637, #44a08d);
  background-image: -moz-linear-gradient(left, #093637, #44a08d);
  background-image: -o-linear-gradient(left, #093637, #44a08d);
  background-image: -ms-linear-gradient(left, #093637, #44a08d);
  background-image: linear-gradient(to right, #093637, #44a08d);
}
[data-change-theme="love-tonight"] {
  background-image: -webkit-linear-gradient(left, #4568dc, #b06ab3);
  background-image: -moz-linear-gradient(left, #4568dc, #b06ab3);
  background-image: -o-linear-gradient(left, #4568dc, #b06ab3);
  background-image: -ms-linear-gradient(left, #4568dc, #b06ab3);
  background-image: linear-gradient(to right, #4568dc, #b06ab3);
}
[data-change-theme="love-and-liberty"] {
  background-image: -webkit-linear-gradient(left, #a83b3b, #200122);
  background-image: -moz-linear-gradient(left, #a83b3b, #200122);
  background-image: -o-linear-gradient(left, #a83b3b, #200122);
  background-image: -ms-linear-gradient(left, #a83b3b, #200122);
  background-image: linear-gradient(to right, #a83b3b, #200122);
}
[data-change-theme="blue-lagoon"] {
  background-image: -webkit-linear-gradient(left, #43c6ac, #191654);
  background-image: -moz-linear-gradient(left, #43c6ac, #191654);
  background-image: -o-linear-gradient(left, #43c6ac, #191654);
  background-image: -ms-linear-gradient(left, #43c6ac, #191654);
  background-image: linear-gradient(to right, #43c6ac, #191654);
}
[data-change-theme="grapefruit-sunset"] {
  background-image: -webkit-linear-gradient(left, #904e95, #e96443);
  background-image: -moz-linear-gradient(left, #904e95, #e96443);
  background-image: -o-linear-gradient(left, #904e95, #e96443);
  background-image: -ms-linear-gradient(left, #904e95, #e96443);
  background-image: linear-gradient(to right, #904e95, #e96443);
}
[data-change-theme="frost"] {
  background-image: -webkit-linear-gradient(left, #004e92, #000428);
  background-image: -moz-linear-gradient(left, #004e92, #000428);
  background-image: -o-linear-gradient(left, #004e92, #000428);
  background-image: -ms-linear-gradient(left, #004e92, #000428);
  background-image: linear-gradient(to right, #004e92, #000428);
}
[data-change-theme="mauve"] {
  background-image: -webkit-linear-gradient(left, #734b6d, #42275a);
  background-image: -moz-linear-gradient(left, #734b6d, #42275a);
  background-image: -o-linear-gradient(left, #734b6d, #42275a);
  background-image: -ms-linear-gradient(left, #734b6d, #42275a);
  background-image: linear-gradient(to right, #734b6d, #42275a);
}
[data-change-theme="under-the-lake"] {
  background-image: -webkit-linear-gradient(left, #237a57, #093028);
  background-image: -moz-linear-gradient(left, #237a57, #093028);
  background-image: -o-linear-gradient(left, #237a57, #093028);
  background-image: -ms-linear-gradient(left, #237a57, #093028);
  background-image: linear-gradient(to right, #237a57, #093028);
}
[data-change-theme="vice-city"] {
  background-image: -webkit-linear-gradient(left, #ec6ead, #3494e6);
  background-image: -moz-linear-gradient(left, #ec6ead, #3494e6);
  background-image: -o-linear-gradient(left, #ec6ead, #3494e6);
  background-image: -ms-linear-gradient(left, #ec6ead, #3494e6);
  background-image: linear-gradient(to right, #ec6ead, #3494e6);
}
[data-change-theme="mild"] {
  background-image: -webkit-linear-gradient(left, #4ca2cd, #67b26f);
  background-image: -moz-linear-gradient(left, #4ca2cd, #67b26f);
  background-image: -o-linear-gradient(left, #4ca2cd, #67b26f);
  background-image: -ms-linear-gradient(left, #4ca2cd, #67b26f);
  background-image: linear-gradient(to right, #4ca2cd, #67b26f);
}
[data-change-theme="nepal"] {
  background-image: -webkit-linear-gradient(left, #2657eb, #de6161);
  background-image: -moz-linear-gradient(left, #2657eb, #de6161);
  background-image: -o-linear-gradient(left, #2657eb, #de6161);
  background-image: -ms-linear-gradient(left, #2657eb, #de6161);
  background-image: linear-gradient(to right, #2657eb, #de6161);
}
[data-change-theme="ibiza-sunset"] {
  background-image: -webkit-linear-gradient(left, #ff6a00, #ee0979);
  background-image: -moz-linear-gradient(left, #ff6a00, #ee0979);
  background-image: -o-linear-gradient(left, #ff6a00, #ee0979);
  background-image: -ms-linear-gradient(left, #ff6a00, #ee0979);
  background-image: linear-gradient(to right, #ff6a00, #ee0979);
}
[data-change-theme="sunset"] {
  background-image: -webkit-linear-gradient(left, #f56217, #0b486b);
  background-image: -moz-linear-gradient(left, #f56217, #0b486b);
  background-image: -o-linear-gradient(left, #f56217, #0b486b);
  background-image: -ms-linear-gradient(left, #f56217, #0b486b);
  background-image: linear-gradient(to right, #f56217, #0b486b);
}
[data-change-theme="colored-lenses"] {
  background-image: -webkit-linear-gradient(left, #636fa4, #e8cbc0);
  background-image: -moz-linear-gradient(left, #636fa4, #e8cbc0);
  background-image: -o-linear-gradient(left, #636fa4, #e8cbc0);
  background-image: -ms-linear-gradient(left, #636fa4, #e8cbc0);
  background-image: linear-gradient(to right, #636fa4, #e8cbc0);
}
[data-change-theme="disco"] {
  background-image: -webkit-linear-gradient(left, #556270, #4ecdc4);
  background-image: -moz-linear-gradient(left, #556270, #4ecdc4);
  background-image: -o-linear-gradient(left, #556270, #4ecdc4);
  background-image: -ms-linear-gradient(left, #556270, #4ecdc4);
  background-image: linear-gradient(to right, #556270, #4ecdc4);
}
[data-change-theme="dania"] {
  background-image: -webkit-linear-gradient(left, #7bc6cc, #be93c5);
  background-image: -moz-linear-gradient(left, #7bc6cc, #be93c5);
  background-image: -o-linear-gradient(left, #7bc6cc, #be93c5);
  background-image: -ms-linear-gradient(left, #7bc6cc, #be93c5);
  background-image: linear-gradient(to right, #7bc6cc, #be93c5);
}
[data-change-theme="50shades"] {
  background-image: -webkit-linear-gradient(left, #2c3e50, #bdc3c7);
  background-image: -moz-linear-gradient(left, #2c3e50, #bdc3c7);
  background-image: -o-linear-gradient(left, #2c3e50, #bdc3c7);
  background-image: -ms-linear-gradient(left, #2c3e50, #bdc3c7);
  background-image: linear-gradient(to right, #2c3e50, #bdc3c7);
}
[data-change-theme="very-blue"] {
  background-image: -webkit-linear-gradient(left, #0575e6, #021b79);
  background-image: -moz-linear-gradient(left, #0575e6, #021b79);
  background-image: -o-linear-gradient(left, #0575e6, #021b79);
  background-image: -ms-linear-gradient(left, #0575e6, #021b79);
  background-image: linear-gradient(to right, #0575e6, #021b79);
}
[data-change-theme="dawn"] {
  background-image: -webkit-linear-gradient(left, #f3904f, #3b4371);
  background-image: -moz-linear-gradient(left, #f3904f, #3b4371);
  background-image: -o-linear-gradient(left, #f3904f, #3b4371);
  background-image: -ms-linear-gradient(left, #f3904f, #3b4371);
  background-image: linear-gradient(to right, #f3904f, #3b4371);
}
[data-change-theme="dusk"] {
  background-image: -webkit-linear-gradient(left, #19547b, #ffd89b);
  background-image: -moz-linear-gradient(left, #19547b, #ffd89b);
  background-image: -o-linear-gradient(left, #19547b, #ffd89b);
  background-image: -ms-linear-gradient(left, #19547b, #ffd89b);
  background-image: linear-gradient(to right, #19547b, #ffd89b);
}
[data-change-theme="dusk-2"] {
  background-image: -webkit-linear-gradient(left, #fd746c, #2c3e50);
  background-image: -moz-linear-gradient(left, #fd746c, #2c3e50);
  background-image: -o-linear-gradient(left, #fd746c, #2c3e50);
  background-image: -ms-linear-gradient(left, #fd746c, #2c3e50);
  background-image: linear-gradient(to right, #fd746c, #2c3e50);
}
[data-change-theme="delhi"] {
  background-image: -webkit-linear-gradient(left, #3fada8, #808080);
  background-image: -moz-linear-gradient(left, #3fada8, #808080);
  background-image: -o-linear-gradient(left, #3fada8, #808080);
  background-image: -ms-linear-gradient(left, #3fada8, #808080);
  background-image: linear-gradient(to right, #3fada8, #808080);
}
[data-change-theme="cosmic-fusion"] {
  background-image: -webkit-linear-gradient(left, #f0c, #339);
  background-image: -moz-linear-gradient(left, #f0c, #339);
  background-image: -o-linear-gradient(left, #f0c, #339);
  background-image: -ms-linear-gradient(left, #f0c, #339);
  background-image: linear-gradient(to right, #f0c, #339);
}
[data-change-theme="firewatch"] {
  background-image: -webkit-linear-gradient(left, #ef473a, #cb2d3e);
  background-image: -moz-linear-gradient(left, #ef473a, #cb2d3e);
  background-image: -o-linear-gradient(left, #ef473a, #cb2d3e);
  background-image: -ms-linear-gradient(left, #ef473a, #cb2d3e);
  background-image: linear-gradient(to right, #ef473a, #cb2d3e);
}
[data-change-theme="lush"] {
  background-image: -webkit-linear-gradient(left, #56ab2f, #a8e063);
  background-image: -moz-linear-gradient(left, #56ab2f, #a8e063);
  background-image: -o-linear-gradient(left, #56ab2f, #a8e063);
  background-image: -ms-linear-gradient(left, #56ab2f, #a8e063);
  background-image: linear-gradient(to right, #56ab2f, #a8e063);
}
[data-change-theme="80s-purple"] {
  background-image: -webkit-linear-gradient(left, #41295a, #2f0743);
  background-image: -moz-linear-gradient(left, #41295a, #2f0743);
  background-image: -o-linear-gradient(left, #41295a, #2f0743);
  background-image: -ms-linear-gradient(left, #41295a, #2f0743);
  background-image: linear-gradient(to right, #41295a, #2f0743);
}
[data-change-theme="royal"] {
  background-image: -webkit-linear-gradient(left, #243b55, #141e30);
  background-image: -moz-linear-gradient(left, #243b55, #141e30);
  background-image: -o-linear-gradient(left, #243b55, #141e30);
  background-image: -ms-linear-gradient(left, #243b55, #141e30);
  background-image: linear-gradient(to right, #243b55, #141e30);
}
[data-change-theme="deep-sea-space"] {
  background-image: -webkit-linear-gradient(left, #4ca1af, #2c3e50);
  background-image: -moz-linear-gradient(left, #4ca1af, #2c3e50);
  background-image: -o-linear-gradient(left, #4ca1af, #2c3e50);
  background-image: -ms-linear-gradient(left, #4ca1af, #2c3e50);
  background-image: linear-gradient(to right, #4ca1af, #2c3e50);
}
[data-change-theme="solid-vault"] {
  background-image: -webkit-linear-gradient(left, #3a6073, #3a7bd5);
  background-image: -moz-linear-gradient(left, #3a6073, #3a7bd5);
  background-image: -o-linear-gradient(left, #3a6073, #3a7bd5);
  background-image: -ms-linear-gradient(left, #3a6073, #3a7bd5);
  background-image: linear-gradient(to right, #3a6073, #3a7bd5);
}
[data-change-theme="politics"] {
  background-image: -webkit-linear-gradient(left, #f44336, #2196f3);
  background-image: -moz-linear-gradient(left, #f44336, #2196f3);
  background-image: -o-linear-gradient(left, #f44336, #2196f3);
  background-image: -ms-linear-gradient(left, #f44336, #2196f3);
  background-image: linear-gradient(to right, #f44336, #2196f3);
}
[data-change-theme="transfile"] {
  background-image: -webkit-linear-gradient(left, #cb3066, #16bffd);
  background-image: -moz-linear-gradient(left, #cb3066, #16bffd);
  background-image: -o-linear-gradient(left, #cb3066, #16bffd);
  background-image: -ms-linear-gradient(left, #cb3066, #16bffd);
  background-image: linear-gradient(to right, #cb3066, #16bffd);
}
[data-change-theme="red-ocean"] {
  background-image: -webkit-linear-gradient(left, #a43931, #1d4350);
  background-image: -moz-linear-gradient(left, #a43931, #1d4350);
  background-image: -o-linear-gradient(left, #a43931, #1d4350);
  background-image: -ms-linear-gradient(left, #a43931, #1d4350);
  background-image: linear-gradient(to right, #a43931, #1d4350);
}
[data-change-theme="pink-lime"] {
  background-image: -webkit-linear-gradient(left, #a80077, #6f0);
  background-image: -moz-linear-gradient(left, #a80077, #6f0);
  background-image: -o-linear-gradient(left, #a80077, #6f0);
  background-image: -ms-linear-gradient(left, #a80077, #6f0);
  background-image: linear-gradient(to right, #a80077, #6f0);
}
[data-change-theme="easy-med"] {
  background-image: -webkit-linear-gradient(left, #409c43, #c2c93a);
  background-image: -moz-linear-gradient(left, #409c43, #c2c93a);
  background-image: -o-linear-gradient(left, #409c43, #c2c93a);
  background-image: -ms-linear-gradient(left, #409c43, #c2c93a);
  background-image: linear-gradient(to right, #409c43, #c2c93a);
}
[data-change-theme="cocoa-ice"] {
  background-image: -webkit-linear-gradient(left, #1ce1ce, #c0c0aa);
  background-image: -moz-linear-gradient(left, #1ce1ce, #c0c0aa);
  background-image: -o-linear-gradient(left, #1ce1ce, #c0c0aa);
  background-image: -ms-linear-gradient(left, #1ce1ce, #c0c0aa);
  background-image: linear-gradient(to right, #1ce1ce, #c0c0aa);
}
[data-change-theme="candy-ice"] {
  background-image: -webkit-linear-gradient(left, #db36a4, #f7ff00);
  background-image: -moz-linear-gradient(left, #db36a4, #f7ff00);
  background-image: -o-linear-gradient(left, #db36a4, #f7ff00);
  background-image: -ms-linear-gradient(left, #db36a4, #f7ff00);
  background-image: linear-gradient(to right, #db36a4, #f7ff00);
}
[data-change-theme="dark-skies"] {
  background-image: -webkit-linear-gradient(left, #283e51, #4b79a1);
  background-image: -moz-linear-gradient(left, #283e51, #4b79a1);
  background-image: -o-linear-gradient(left, #283e51, #4b79a1);
  background-image: -ms-linear-gradient(left, #283e51, #4b79a1);
  background-image: linear-gradient(to right, #283e51, #4b79a1);
}
[data-change-theme="forest"] {
  background-image: -webkit-linear-gradient(left, #2c7744, #5a3f37);
  background-image: -moz-linear-gradient(left, #2c7744, #5a3f37);
  background-image: -o-linear-gradient(left, #2c7744, #5a3f37);
  background-image: -ms-linear-gradient(left, #2c7744, #5a3f37);
  background-image: linear-gradient(to right, #2c7744, #5a3f37);
}
[data-change-theme="miami-dolphins"] {
  background-image: -webkit-linear-gradient(left, #d39d38, #4da0b0);
  background-image: -moz-linear-gradient(left, #d39d38, #4da0b0);
  background-image: -o-linear-gradient(left, #d39d38, #4da0b0);
  background-image: -ms-linear-gradient(left, #d39d38, #4da0b0);
  background-image: linear-gradient(to right, #d39d38, #4da0b0);
}
body {
  font-family: "Proxima Nova", "Helvetica Neue", "Helvetica", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Arial", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
._text-primary {
  color: #ec6ead;
}
._text-secondary {
  color: #3494e6;
}
._text-aqua {
  color: #7fdfff;
}
._text-blue {
  color: #0074d9;
}
._text-navy {
  color: #001f3f;
}
._text-teal {
  color: #39cccc;
}
._text-green {
  color: #2ecc40;
}
._text-olive {
  color: #3d9970;
}
._text-lime {
  color: #01ff70;
}
._text-yellow {
  color: #ffdc00;
}
._text-orange {
  color: #ff851b;
}
._text-red {
  color: #ff4136;
}
._text-fuchsia {
  color: #f012be;
}
._text-purple {
  color: #b10dc9;
}
._text-maroon {
  color: #85144b;
}
._text-white {
  color: #fff;
}
._text-silver {
  color: #f2f2f2;
}
._text-darksilver {
  color: #d1d1d1;
}
._text-gray {
  color: #aaa;
}
._text-lightgray {
  color: #ccc;
}
._text-concrete {
  color: #1d1d1e;
}
._text-asphalt {
  color: #3b3b3b;
}
._text-black {
  color: #111;
}
._font-black {
  font-weight: 900;
}
._font-bold {
  font-weight: bold;
}
._font-normal {
  font-weight: normal;
}
._font-light {
  font-weight: 300;
}
._font-thin {
  font-weight: 100;
}
._font-italic {
  font-style: italic;
}
._text-center {
  text-align: center;
}
._text-left {
  text-align: left;
}
._text-right {
  text-align: right;
}
._text-muted {
  color: rgba(255,255,255,0.5);
}
.text-muted {
  color: rgba(255,255,255,0.5) !important;
}
._pull-right {
  float: right;
  zoom: 1;
}
._pull-right:before,
._pull-right:after {
  content: "";
  display: table;
}
._pull-right:after {
  clear: both;
}
._pull-left {
  float: left;
  zoom: 1;
}
._pull-left:before,
._pull-left:after {
  content: "";
  display: table;
}
._pull-left:after {
  clear: both;
}
selection {
  background: #1d1d1e;
  color: #fff;
}
-moz-selection {
  color: #fff;
  background: #1d1d1e;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 300;
}
h1 > small,
h2 > small,
h3 > small,
h4 > small,
h5 > small,
h6 > small,
.h1 > small,
.h2 > small,
.h3 > small,
.h4 > small,
.h5 > small,
.h6 > small {
  font-size: 60%;
  color: rgba(255,255,255,0.5);
}
mark,
.mark {
  background: rgba(255,255,255,0.5);
}
blockquote,
.blockquote {
  border-left: 2px solid transparent;
  font-size: 1.25rem;
  padding: 0.25rem 1rem;
}
blockquote.-right,
.blockquote.-right {
  border-left: 0px solid transparent;
  border-right: 2px solid transparent;
  text-align: right;
}
blockquote.-right.-light,
.blockquote.-right.-light {
  border-right-color: rgba(255,255,255,0.5);
}
blockquote.-right.-dark,
.blockquote.-right.-dark {
  border-right-color: rgba(0,0,0,0.5);
}
blockquote.-light,
.blockquote.-light {
  border-left-color: rgba(255,255,255,0.5);
}
blockquote.-dark,
.blockquote.-dark {
  border-left-color: rgba(0,0,0,0.5);
}
blockquote footer,
.blockquote footer,
blockquote .blockquote-footer,
.blockquote .blockquote-footer {
  font-size: 1rem;
  color: rgba(255,255,255,0.5);
}
._cursor-default {
  cursor: default;
}
._cursor-pointer {
  cursor: pointer;
}
a {
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
code {
  color: rgba(255,255,255,0.85);
  background: rgba(0,0,0,0.5);
}
.alert {
  border-radius: 2px;
  padding-top: 1rem;
  padding-left: 3.5rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  position: relative;
  display: block;
}
.alert .alert-icon {
  position: absolute;
  font-size: 1.5rem;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0.75rem 1rem;
}
.alert.-contrasted {
  padding-left: 4.5rem;
}
.alert.-dark {
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -o-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -ms-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: linear-gradient(to bottom right, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
}
.alert.-light {
  background: -webkit-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -moz-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -o-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -ms-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: linear-gradient(to bottom right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
}
.alert.-primary {
  background: -webkit-linear-gradient(top left, #ec6ead, rgba(236,110,173,0.75));
  background: -moz-linear-gradient(top left, #ec6ead, rgba(236,110,173,0.75));
  background: -o-linear-gradient(top left, #ec6ead, rgba(236,110,173,0.75));
  background: -ms-linear-gradient(top left, #ec6ead, rgba(236,110,173,0.75));
  background: linear-gradient(to bottom right, #ec6ead, rgba(236,110,173,0.75));
}
.alert.-primary.-contrasted .alert-icon {
  background: #e64093;
}
.alert.-contrasted .alert-icon.-primary {
  background: #e64093;
}
.alert.-border-primary {
  border-color: #ee7cb5;
}
.alert.-secondary {
  background: -webkit-linear-gradient(top left, #3494e6, rgba(52,148,230,0.75));
  background: -moz-linear-gradient(top left, #3494e6, rgba(52,148,230,0.75));
  background: -o-linear-gradient(top left, #3494e6, rgba(52,148,230,0.75));
  background: -ms-linear-gradient(top left, #3494e6, rgba(52,148,230,0.75));
  background: linear-gradient(to bottom right, #3494e6, rgba(52,148,230,0.75));
}
.alert.-secondary.-contrasted .alert-icon {
  background: #1a7fd5;
}
.alert.-contrasted .alert-icon.-secondary {
  background: #1a7fd5;
}
.alert.-border-secondary {
  border-color: #489fe9;
}
.alert.-success {
  background: -webkit-linear-gradient(top left, #3d9970, rgba(61,153,112,0.75));
  background: -moz-linear-gradient(top left, #3d9970, rgba(61,153,112,0.75));
  background: -o-linear-gradient(top left, #3d9970, rgba(61,153,112,0.75));
  background: -ms-linear-gradient(top left, #3d9970, rgba(61,153,112,0.75));
  background: linear-gradient(to bottom right, #3d9970, rgba(61,153,112,0.75));
}
.alert.-success.-contrasted .alert-icon {
  background: #34825f;
}
.alert.-contrasted .alert-icon.-success {
  background: #34825f;
}
.alert.-border-success {
  border-color: #45ae7f;
}
.alert.-danger {
  background: -webkit-linear-gradient(top left, #ff4136, rgba(255,65,54,0.75));
  background: -moz-linear-gradient(top left, #ff4136, rgba(255,65,54,0.75));
  background: -o-linear-gradient(top left, #ff4136, rgba(255,65,54,0.75));
  background: -ms-linear-gradient(top left, #ff4136, rgba(255,65,54,0.75));
  background: linear-gradient(to bottom right, #ff4136, rgba(255,65,54,0.75));
}
.alert.-danger.-contrasted .alert-icon {
  background: #ff1508;
}
.alert.-contrasted .alert-icon.-danger {
  background: #ff1508;
}
.alert.-border-danger {
  border-color: #ff544a;
}
.alert.-warning {
  background: -webkit-linear-gradient(top left, #ff851b, rgba(255,133,27,0.75));
  background: -moz-linear-gradient(top left, #ff851b, rgba(255,133,27,0.75));
  background: -o-linear-gradient(top left, #ff851b, rgba(255,133,27,0.75));
  background: -ms-linear-gradient(top left, #ff851b, rgba(255,133,27,0.75));
  background: linear-gradient(to bottom right, #ff851b, rgba(255,133,27,0.75));
}
.alert.-warning.-contrasted .alert-icon {
  background: #f06f00;
}
.alert.-contrasted .alert-icon.-warning {
  background: #f06f00;
}
.alert.-border-warning {
  border-color: #ff9132;
}
.alert.-info {
  background: -webkit-linear-gradient(top left, #0074d9, rgba(0,116,217,0.75));
  background: -moz-linear-gradient(top left, #0074d9, rgba(0,116,217,0.75));
  background: -o-linear-gradient(top left, #0074d9, rgba(0,116,217,0.75));
  background: -ms-linear-gradient(top left, #0074d9, rgba(0,116,217,0.75));
  background: linear-gradient(to bottom right, #0074d9, rgba(0,116,217,0.75));
}
.alert.-info.-contrasted .alert-icon {
  background: #0063b8;
}
.alert.-contrasted .alert-icon.-info {
  background: #0063b8;
}
.alert.-border-info {
  border-color: #0084f6;
}
.breadcrumb .breadcrumb-item {
  color: #fff;
}
.breadcrumb .breadcrumb-item.-active,
.breadcrumb .breadcrumb-item::before {
  color: rgba(255,255,255,0.5);
}
.breadcrumb.-dark {
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -o-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -ms-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: linear-gradient(to bottom right, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
}
.breadcrumb.-light {
  background: -webkit-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -moz-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -o-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -ms-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: linear-gradient(to bottom right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
}
.btn {
  border-radius: 0;
  background-color: transparent;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 0.75rem 2rem;
  color: rgba(255,255,255,0.95);
}
.btn.-xs {
  padding: 0.375rem 1rem;
  font-size: 0.9rem;
}
.btn.-sm {
  padding: 0.5625rem 1.5rem;
  font-size: 0.95rem;
}
.btn.-lg {
  padding: 0.9375rem 2.5rem;
  font-size: 1.05rem;
}
.btn.-xl {
  padding: 1.125rem 3rem;
  font-size: 1.1rem;
}
.btn.-wide {
  padding-left: 3rem;
  padding-right: 3rem;
}
.btn.-narrow {
  padding-left: 1rem;
  padding-right: 1rem;
}
.btn.-block {
  display: block;
  width: 100%;
  margin: 0;
}
.btn.-hspace {
  margin-left: 1rem;
  margin-right: 1rem;
}
.btn.-hspace:first-child {
  margin-left: 0rem;
}
.btn.-vspace {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.btn:hover,
.btn:focus,
.btn:active {
  color: #fff;
}
.btn.-dark {
  background-image: -webkit-linear-gradient(top, rgba(0,0,0,0.7), rgba(0,0,0,0.5));
  background-image: -moz-linear-gradient(top, rgba(0,0,0,0.7), rgba(0,0,0,0.5));
  background-image: -o-linear-gradient(top, rgba(0,0,0,0.7), rgba(0,0,0,0.5));
  background-image: -ms-linear-gradient(top, rgba(0,0,0,0.7), rgba(0,0,0,0.5));
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.5));
}
.btn.-dark:hover,
.btn.-dark:focus {
  background-color: rgba(0,0,0,0.5);
}
.btn.-dark:active {
  background-color: #000;
}
.btn.-light {
  background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.85), rgba(255,255,255,0.5));
  background-image: -moz-linear-gradient(top, rgba(255,255,255,0.85), rgba(255,255,255,0.5));
  background-image: -o-linear-gradient(top, rgba(255,255,255,0.85), rgba(255,255,255,0.5));
  background-image: -ms-linear-gradient(top, rgba(255,255,255,0.85), rgba(255,255,255,0.5));
  background-image: linear-gradient(to bottom, rgba(255,255,255,0.85), rgba(255,255,255,0.5));
}
.btn.-light:hover,
.btn.-light:focus {
  background-color: rgba(255,255,255,0.5);
}
.btn.-light:active {
  background-color: #fff;
}
.btn.-light {
  color: #000;
}
.btn.-light:hover,
.btn.-light:focus,
.btn.-light:active {
  color: #000;
}
.btn.-primary {
  background-image: -webkit-linear-gradient(top, rgba(236,110,173,0.8), rgba(236,110,173,0.4));
  background-image: -moz-linear-gradient(top, rgba(236,110,173,0.8), rgba(236,110,173,0.4));
  background-image: -o-linear-gradient(top, rgba(236,110,173,0.8), rgba(236,110,173,0.4));
  background-image: -ms-linear-gradient(top, rgba(236,110,173,0.8), rgba(236,110,173,0.4));
  background-image: linear-gradient(to bottom, rgba(236,110,173,0.8), rgba(236,110,173,0.4));
}
.btn.-primary:hover,
.btn.-primary:focus {
  background-color: rgba(236,110,173,0.5);
}
.btn.-primary:active {
  background-color: #b81868;
}
.btn.-secondary {
  background-image: -webkit-linear-gradient(top, rgba(52,148,230,0.8), rgba(52,148,230,0.4));
  background-image: -moz-linear-gradient(top, rgba(52,148,230,0.8), rgba(52,148,230,0.4));
  background-image: -o-linear-gradient(top, rgba(52,148,230,0.8), rgba(52,148,230,0.4));
  background-image: -ms-linear-gradient(top, rgba(52,148,230,0.8), rgba(52,148,230,0.4));
  background-image: linear-gradient(to bottom, rgba(52,148,230,0.8), rgba(52,148,230,0.4));
}
.btn.-secondary:hover,
.btn.-secondary:focus {
  background-color: rgba(52,148,230,0.5);
}
.btn.-secondary:active {
  background-color: #135a97;
}
.btn.-success {
  background-image: -webkit-linear-gradient(top, rgba(61,153,112,0.8), rgba(61,153,112,0.4));
  background-image: -moz-linear-gradient(top, rgba(61,153,112,0.8), rgba(61,153,112,0.4));
  background-image: -o-linear-gradient(top, rgba(61,153,112,0.8), rgba(61,153,112,0.4));
  background-image: -ms-linear-gradient(top, rgba(61,153,112,0.8), rgba(61,153,112,0.4));
  background-image: linear-gradient(to bottom, rgba(61,153,112,0.8), rgba(61,153,112,0.4));
}
.btn.-success:hover,
.btn.-success:focus {
  background-color: rgba(61,153,112,0.5);
}
.btn.-success:active {
  background-color: #255c43;
}
.btn.-danger {
  background-image: -webkit-linear-gradient(top, rgba(255,65,54,0.8), rgba(255,65,54,0.4));
  background-image: -moz-linear-gradient(top, rgba(255,65,54,0.8), rgba(255,65,54,0.4));
  background-image: -o-linear-gradient(top, rgba(255,65,54,0.8), rgba(255,65,54,0.4));
  background-image: -ms-linear-gradient(top, rgba(255,65,54,0.8), rgba(255,65,54,0.4));
  background-image: linear-gradient(to bottom, rgba(255,65,54,0.8), rgba(255,65,54,0.4));
}
.btn.-danger:hover,
.btn.-danger:focus {
  background-color: rgba(255,65,54,0.5);
}
.btn.-danger:active {
  background-color: #b90a00;
}
.btn.-warning {
  background-image: -webkit-linear-gradient(top, rgba(255,133,27,0.8), rgba(255,133,27,0.4));
  background-image: -moz-linear-gradient(top, rgba(255,133,27,0.8), rgba(255,133,27,0.4));
  background-image: -o-linear-gradient(top, rgba(255,133,27,0.8), rgba(255,133,27,0.4));
  background-image: -ms-linear-gradient(top, rgba(255,133,27,0.8), rgba(255,133,27,0.4));
  background-image: linear-gradient(to bottom, rgba(255,133,27,0.8), rgba(255,133,27,0.4));
}
.btn.-warning:hover,
.btn.-warning:focus {
  background-color: rgba(255,133,27,0.5);
}
.btn.-warning:active {
  background-color: #a94f00;
}
.btn.-info {
  background-image: -webkit-linear-gradient(top, rgba(0,116,217,0.8), rgba(0,116,217,0.4));
  background-image: -moz-linear-gradient(top, rgba(0,116,217,0.8), rgba(0,116,217,0.4));
  background-image: -o-linear-gradient(top, rgba(0,116,217,0.8), rgba(0,116,217,0.4));
  background-image: -ms-linear-gradient(top, rgba(0,116,217,0.8), rgba(0,116,217,0.4));
  background-image: linear-gradient(to bottom, rgba(0,116,217,0.8), rgba(0,116,217,0.4));
}
.btn.-info:hover,
.btn.-info:focus {
  background-color: rgba(0,116,217,0.5);
}
.btn.-info:active {
  background-color: #004682;
}
.btn.-facebook {
  background-image: -webkit-linear-gradient(top, rgba(59,89,152,0.8), rgba(59,89,152,0.5));
  background-image: -moz-linear-gradient(top, rgba(59,89,152,0.8), rgba(59,89,152,0.5));
  background-image: -o-linear-gradient(top, rgba(59,89,152,0.8), rgba(59,89,152,0.5));
  background-image: -ms-linear-gradient(top, rgba(59,89,152,0.8), rgba(59,89,152,0.5));
  background-image: linear-gradient(to bottom, rgba(59,89,152,0.8), rgba(59,89,152,0.5));
}
.btn.-facebook:hover,
.btn.-facebook:focus {
  background-color: rgba(59,89,152,0.5);
}
.btn.-facebook:active {
  background-color: #23355b;
}
.btn.-twitter {
  background-image: -webkit-linear-gradient(top, rgba(29,161,242,0.8), rgba(29,161,242,0.5));
  background-image: -moz-linear-gradient(top, rgba(29,161,242,0.8), rgba(29,161,242,0.5));
  background-image: -o-linear-gradient(top, rgba(29,161,242,0.8), rgba(29,161,242,0.5));
  background-image: -ms-linear-gradient(top, rgba(29,161,242,0.8), rgba(29,161,242,0.5));
  background-image: linear-gradient(to bottom, rgba(29,161,242,0.8), rgba(29,161,242,0.5));
}
.btn.-twitter:hover,
.btn.-twitter:focus {
  background-color: rgba(29,161,242,0.5);
}
.btn.-twitter:active {
  background-color: #09639a;
}
.btn.-google-plus {
  background-image: -webkit-linear-gradient(top, rgba(221,75,57,0.8), rgba(221,75,57,0.5));
  background-image: -moz-linear-gradient(top, rgba(221,75,57,0.8), rgba(221,75,57,0.5));
  background-image: -o-linear-gradient(top, rgba(221,75,57,0.8), rgba(221,75,57,0.5));
  background-image: -ms-linear-gradient(top, rgba(221,75,57,0.8), rgba(221,75,57,0.5));
  background-image: linear-gradient(to bottom, rgba(221,75,57,0.8), rgba(221,75,57,0.5));
}
.btn.-google-plus:hover,
.btn.-google-plus:focus {
  background-color: rgba(221,75,57,0.5);
}
.btn.-google-plus:active {
  background-color: #8e2518;
}
.btn.-dribbble {
  background-image: -webkit-linear-gradient(top, rgba(234,76,137,0.8), rgba(234,76,137,0.5));
  background-image: -moz-linear-gradient(top, rgba(234,76,137,0.8), rgba(234,76,137,0.5));
  background-image: -o-linear-gradient(top, rgba(234,76,137,0.8), rgba(234,76,137,0.5));
  background-image: -ms-linear-gradient(top, rgba(234,76,137,0.8), rgba(234,76,137,0.5));
  background-image: linear-gradient(to bottom, rgba(234,76,137,0.8), rgba(234,76,137,0.5));
}
.btn.-dribbble:hover,
.btn.-dribbble:focus {
  background-color: rgba(234,76,137,0.5);
}
.btn.-dribbble:active {
  background-color: #a6144c;
}
.btn.-behance {
  background-image: -webkit-linear-gradient(top, rgba(23,105,255,0.8), rgba(23,105,255,0.5));
  background-image: -moz-linear-gradient(top, rgba(23,105,255,0.8), rgba(23,105,255,0.5));
  background-image: -o-linear-gradient(top, rgba(23,105,255,0.8), rgba(23,105,255,0.5));
  background-image: -ms-linear-gradient(top, rgba(23,105,255,0.8), rgba(23,105,255,0.5));
  background-image: linear-gradient(to bottom, rgba(23,105,255,0.8), rgba(23,105,255,0.5));
}
.btn.-behance:hover,
.btn.-behance:focus {
  background-color: rgba(23,105,255,0.5);
}
.btn.-behance:active {
  background-color: #003ba7;
}
.btn.-flickr {
  background-image: -webkit-linear-gradient(top, rgba(255,0,132,0.8), rgba(255,0,132,0.5));
  background-image: -moz-linear-gradient(top, rgba(255,0,132,0.8), rgba(255,0,132,0.5));
  background-image: -o-linear-gradient(top, rgba(255,0,132,0.8), rgba(255,0,132,0.5));
  background-image: -ms-linear-gradient(top, rgba(255,0,132,0.8), rgba(255,0,132,0.5));
  background-image: linear-gradient(to bottom, rgba(255,0,132,0.8), rgba(255,0,132,0.5));
}
.btn.-flickr:hover,
.btn.-flickr:focus {
  background-color: rgba(255,0,132,0.5);
}
.btn.-flickr:active {
  background-color: #99004f;
}
.btn.-linkedin {
  background-image: -webkit-linear-gradient(top, rgba(0,119,181,0.8), rgba(0,119,181,0.5));
  background-image: -moz-linear-gradient(top, rgba(0,119,181,0.8), rgba(0,119,181,0.5));
  background-image: -o-linear-gradient(top, rgba(0,119,181,0.8), rgba(0,119,181,0.5));
  background-image: -ms-linear-gradient(top, rgba(0,119,181,0.8), rgba(0,119,181,0.5));
  background-image: linear-gradient(to bottom, rgba(0,119,181,0.8), rgba(0,119,181,0.5));
}
.btn.-linkedin:hover,
.btn.-linkedin:focus {
  background-color: rgba(0,119,181,0.5);
}
.btn.-linkedin:active {
  background-color: #00476d;
}
.btn.-youtube {
  background-image: -webkit-linear-gradient(top, rgba(205,32,31,0.8), rgba(205,32,31,0.5));
  background-image: -moz-linear-gradient(top, rgba(205,32,31,0.8), rgba(205,32,31,0.5));
  background-image: -o-linear-gradient(top, rgba(205,32,31,0.8), rgba(205,32,31,0.5));
  background-image: -ms-linear-gradient(top, rgba(205,32,31,0.8), rgba(205,32,31,0.5));
  background-image: linear-gradient(to bottom, rgba(205,32,31,0.8), rgba(205,32,31,0.5));
}
.btn.-youtube:hover,
.btn.-youtube:focus {
  background-color: rgba(205,32,31,0.5);
}
.btn.-youtube:active {
  background-color: #7b1313;
}
.btn.-pinterest {
  background-image: -webkit-linear-gradient(top, rgba(189,8,28,0.8), rgba(189,8,28,0.5));
  background-image: -moz-linear-gradient(top, rgba(189,8,28,0.8), rgba(189,8,28,0.5));
  background-image: -o-linear-gradient(top, rgba(189,8,28,0.8), rgba(189,8,28,0.5));
  background-image: -ms-linear-gradient(top, rgba(189,8,28,0.8), rgba(189,8,28,0.5));
  background-image: linear-gradient(to bottom, rgba(189,8,28,0.8), rgba(189,8,28,0.5));
}
.btn.-pinterest:hover,
.btn.-pinterest:focus {
  background-color: rgba(189,8,28,0.5);
}
.btn.-pinterest:active {
  background-color: #710511;
}
.btn.-github {
  background-image: -webkit-linear-gradient(top, rgba(51,51,51,0.8), rgba(51,51,51,0.5));
  background-image: -moz-linear-gradient(top, rgba(51,51,51,0.8), rgba(51,51,51,0.5));
  background-image: -o-linear-gradient(top, rgba(51,51,51,0.8), rgba(51,51,51,0.5));
  background-image: -ms-linear-gradient(top, rgba(51,51,51,0.8), rgba(51,51,51,0.5));
  background-image: linear-gradient(to bottom, rgba(51,51,51,0.8), rgba(51,51,51,0.5));
}
.btn.-github:hover,
.btn.-github:focus {
  background-color: rgba(51,51,51,0.5);
}
.btn.-github:active {
  background-color: #1f1f1f;
}
.btn.-tumblr {
  background-image: -webkit-linear-gradient(top, rgba(53,70,92,0.8), rgba(53,70,92,0.5));
  background-image: -moz-linear-gradient(top, rgba(53,70,92,0.8), rgba(53,70,92,0.5));
  background-image: -o-linear-gradient(top, rgba(53,70,92,0.8), rgba(53,70,92,0.5));
  background-image: -ms-linear-gradient(top, rgba(53,70,92,0.8), rgba(53,70,92,0.5));
  background-image: linear-gradient(to bottom, rgba(53,70,92,0.8), rgba(53,70,92,0.5));
}
.btn.-tumblr:hover,
.btn.-tumblr:focus {
  background-color: rgba(53,70,92,0.5);
}
.btn.-tumblr:active {
  background-color: #202a37;
}
.btn.-twitch {
  background-image: -webkit-linear-gradient(top, rgba(100,65,165,0.8), rgba(100,65,165,0.5));
  background-image: -moz-linear-gradient(top, rgba(100,65,165,0.8), rgba(100,65,165,0.5));
  background-image: -o-linear-gradient(top, rgba(100,65,165,0.8), rgba(100,65,165,0.5));
  background-image: -ms-linear-gradient(top, rgba(100,65,165,0.8), rgba(100,65,165,0.5));
  background-image: linear-gradient(to bottom, rgba(100,65,165,0.8), rgba(100,65,165,0.5));
}
.btn.-twitch:hover,
.btn.-twitch:focus {
  background-color: rgba(100,65,165,0.5);
}
.btn.-twitch:active {
  background-color: #3c2763;
}
.btn.-envato {
  background-image: -webkit-linear-gradient(top, rgba(130,181,65,0.8), rgba(130,181,65,0.5));
  background-image: -moz-linear-gradient(top, rgba(130,181,65,0.8), rgba(130,181,65,0.5));
  background-image: -o-linear-gradient(top, rgba(130,181,65,0.8), rgba(130,181,65,0.5));
  background-image: -ms-linear-gradient(top, rgba(130,181,65,0.8), rgba(130,181,65,0.5));
  background-image: linear-gradient(to bottom, rgba(130,181,65,0.8), rgba(130,181,65,0.5));
}
.btn.-envato:hover,
.btn.-envato:focus {
  background-color: rgba(130,181,65,0.5);
}
.btn.-envato:active {
  background-color: #4e6d27;
}
.btn-group {
  margin: 0 1rem 1rem 0;
}
.btn-group-vertical {
  margin-right: 1rem;
}
.btn-group.-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.btn-group.-justified .btn,
.btn-group.-justified .btn-group {
  padding-left: 0;
  padding-right: 0;
  float: none;
  display: table-cell;
  width: 1%;
}
.btn-group.-justified .btn .btn,
.btn-group.-justified .btn-group .btn {
  width: 100%;
}
.btn-group.-justified .btn .dropdown-menu,
.btn-group.-justified .btn-group .dropdown-menu {
  left: auto;
}
.fc {
  direction: ltr;
  text-align: left;
}
.fc button {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  height: 2.1em;
  padding: 0 0.6em;
  font-size: 1em;
  text-transform: capitalize;
  white-space: nowrap;
  cursor: pointer;
}
.fc button::-moz-focus-inner {
  margin: 0;
  padding: 0;
}
.fc button .fc-icon {
  position: relative;
  top: -0.05em;
  margin: 0 0.2em;
  vertical-align: middle;
}
.fc .fc-button-group > * {
  float: left;
  margin: 0 0 0 -1px;
}
.fc .fc-button-group > :first-child {
  margin-left: 0;
}
.fc table {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
}
.fc th {
  text-align: center;
}
.fc td.fc-today {
  border-style: double;
}
.fc .fc-row {
  border-style: solid;
  border-width: 0;
}
.fc .fc-toolbar > * > * {
  float: left;
  margin-left: 0.75em;
}
.fc .fc-toolbar > * > :first-child {
  margin-left: 0;
}
.fc .fc-axis {
  vertical-align: middle;
  padding: 0 4px;
  white-space: nowrap;
}
.fc .fc-list-table {
  table-layout: auto;
}
.fc-rtl {
  text-align: right;
}
.fc-rtl .fc-basic-view .fc-day-top .fc-day-number {
  float: left;
}
.fc-rtl .fc-basic-view .fc-day-top .fc-week-number {
  float: right;
  border-radius: 0 0 0 3px;
}
.fc-rtl .fc-axis {
  text-align: left;
}
.fc-rtl .fc-time-grid .fc-event-container {
  margin: 0 2px 0 2.5%;
}
.fc-rtl .fc-time-grid .fc-now-indicator-arrow {
  right: 0;
  border-width: 5px 6px 5px 0;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.fc-rtl .fc-list-view {
  direction: rtl;
}
.fc-rtl .fc-list-heading-main {
  float: right;
}
.fc-rtl .fc-list-heading-alt {
  float: left;
}
.fc-rtl .fc-list-item-marker {
  padding-left: 0;
}
body .fc {
  font-size: 1em;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: rgba(0,0,0,0.125);
}
.fc-unthemed .fc-popover {
  background-color: rgba(0,0,0,0.7);
  border-width: 1px;
  border-style: solid;
}
.fc-unthemed .fc-popover .fc-header {
  background-color: rgba(0,0,0,0.5) !important;
}
.fc-unthemed .fc-popover .fc-header .fc-close {
  color: #666;
  font-size: 0.9em;
  margin-top: 2px;
}
.fc-unthemed td.fc-today {
  background: rgba(0,0,0,0.25);
}
.fc-unthemed .fc-list-empty {
  background-color: rgba(255,255,255,0.5);
}
.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header,
.fc-unthemed .fc-list-heading td {
  background: rgba(0,0,0,0.5);
}
.fc-highlight {
  background: #0074d9;
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
.fc-bgevent {
  background: #3d9970;
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
.fc-nonbusiness {
  background: #000;
}
.fc-icon {
  display: inline-block;
  height: 1em;
  line-height: 1em;
  font-size: 1em;
  text-align: center;
  overflow: hidden;
  font-family: "Courier New", Courier, monospace;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.fc-icon:after {
  position: relative;
}
.fc-icon-left-single-arrow:after {
  content: "\02039";
  font-weight: bold;
  font-size: 200%;
  top: -7%;
}
.fc-icon-right-single-arrow:after {
  content: "\0203A";
  font-weight: bold;
  font-size: 200%;
  top: -7%;
}
.fc-icon-left-double-arrow:after {
  content: "\000AB";
  font-size: 160%;
  top: -7%;
}
.fc-icon-right-double-arrow:after {
  content: "\000BB";
  font-size: 160%;
  top: -7%;
}
.fc-icon-left-triangle:after {
  content: "\25C4";
  font-size: 125%;
  top: 3%;
}
.fc-icon-right-triangle:after {
  content: "\25BA";
  font-size: 125%;
  top: 3%;
}
.fc-icon-down-triangle:after {
  content: "\25BC";
  font-size: 125%;
  top: 2%;
}
.fc-icon-x:after {
  content: "\000D7";
  font-size: 200%;
  top: 6%;
}
.fc-state-default {
  border: 1px solid;
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -moz-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -o-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -ms-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: linear-gradient(to bottom, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  border-color: rgba(0,0,0,0.125);
  color: rgba(255,255,255,0.85);
}
.fc-state-default.fc-corner-left {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.fc-state-default.fc-corner-right {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: #fff;
  background-color: rgba(0,0,0,0.125);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.fc-state-hover {
  text-decoration: none;
}
.fc-state-down,
.fc-state-active {
  background-color: rgba(0,0,0,0.5);
  background-image: none;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,0.15), 0 1px 2px rgba(0,0,0,0.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.15), 0 1px 2px rgba(0,0,0,0.05);
}
.fc-state-disabled {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.fc-button-group {
  display: inline-block;
}
.fc-popover {
  position: absolute;
  -webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.15);
  box-shadow: 0 2px 6px rgba(0,0,0,0.15);
}
.fc-popover .fc-header {
  padding: 2px 4px;
}
.fc-popover .fc-header .fc-title {
  margin: 0 2px;
}
.fc-popover .fc-header .fc-close {
  cursor: pointer;
}
.fc-popover > .ui-widget-header + .ui-widget-content {
  border-top: 0;
}
.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close {
  float: left;
}
.fc-rtl .fc-popover .fc-header .fc-title,
.fc-ltr .fc-popover .fc-header .fc-close {
  float: right;
}
.fc-divider {
  border-style: solid;
  border-width: 1px;
}
hr.fc-divider {
  height: 0;
  margin: 0;
  padding: 0 0 2px;
  border-width: 1px 0;
}
.fc-clear {
  clear: both;
}
.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc-bg {
  bottom: 0;
}
.fc-bg table {
  height: 100%;
}
.fc th,
.fc td {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top;
}
.fc a {
  cursor: pointer;
}
.fc-row {
  position: relative;
}
.fc-row table {
  border-left: 0 hidden transparent;
  border-right: 0 hidden transparent;
  border-bottom: 0 hidden transparent;
}
.fc-row:first-child table {
  border-top: 0 hidden transparent;
}
.fc-row .fc-bg {
  z-index: 1;
}
.fc-row .fc-bgevent-skeleton {
  z-index: 2;
}
.fc-row .fc-highlight-skeleton {
  z-index: 3;
}
.fc-row .fc-content-skeleton {
  position: relative;
  z-index: 4;
  padding-bottom: 2px;
}
.fc-row .fc-helper-skeleton {
  z-index: 5;
}
.fc-row.fc-rigid {
  overflow: hidden;
}
.fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
  bottom: 0;
}
.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
  height: 100%;
}
.fc-row .fc-highlight-skeleton td,
.fc-row .fc-bgevent-skeleton td {
  border-color: transparent;
}
.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  background: none;
  border-color: transparent;
  border-bottom: 0;
}
.fc-row .fc-content-skeleton tbody: td,
.fc-row .fc-helper-skeleton tbody: td {
  border-top: 0;
}
.fc-scroller {
  -webkit-overflow-scrolling: touch;
}
.fc-scroller > .fc-day-grid,
.fc-scroller > .fc-time-grid {
  position: relative;
  width: 100%;
}
.fc-event {
  position: relative;
  display: block;
  font-size: 0.85em;
  line-height: 1.3;
  border-radius: 3px;
  border-radius: 0;
  border: 1px solid rgba(0,0,0,0.125);
  font-weight: normal;
}
.fc-event .fc-bg {
  z-index: 1;
  background: rgba(255,255,255,0.15);
  opacity: 0.25;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
  filter: alpha(opacity=25);
}
.fc-event .fc-content {
  position: relative;
  z-index: 2;
}
.fc-event .fc-resizer {
  position: absolute;
  z-index: 4;
  display: none;
}
.fc-event.fc-selected {
  z-index: 9999 !important;
  -webkit-box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}
.fc-event.fc-selected .fc-resizer:before {
  content: "";
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
}
.fc-event.fc-selected.fc-dragging {
  -webkit-box-shadow: 0 2px 7px rgba(0,0,0,0.3);
  box-shadow: 0 2px 7px rgba(0,0,0,0.3);
}
.fc-event,
.fc-event-dot {
  background: rgba(0,0,0,0.5);
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -moz-linear-gradient(left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -o-linear-gradient(left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -ms-linear-gradient(left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
}
.fc-event,
.fc-event:hover,
.ui-widget .fc-event {
  color: #fff;
  text-decoration: none;
}
.fc-event,
.fc-event.fc-draggable {
  cursor: pointer;
}
.fc-not-allowed,
.fc-not-allowed .fc-event {
  cursor: not-allowed;
}
.fc-event.fc-allow-mouse-resize .fc-resizer,
.fc-event.fc-selected .fc-resizer {
  display: block;
}
.fc-h-event.fc-selected:before {
  content: "";
  position: absolute;
  z-index: 3;
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0;
}
.fc-h-event.fc-selected .fc-resizer {
  border-radius: 4px;
  border-width: 1px;
  width: 6px;
  height: 6px;
  border-style: solid;
  border-color: inherit;
  background: rgba(255,255,255,0.15);
  top: 50%;
  margin-top: -4px;
}
.fc-h-event.fc-allow-mouse-resize .fc-resizer {
  width: 7px;
  top: -1px;
  bottom: -1px;
}
.fc-ltr .fc-h-event.fc-not-start,
.fc-rtl .fc-h-event.fc-not-end {
  margin-left: 0;
  border-left-width: 0;
  padding-left: 1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
  margin-right: 0;
  border-right-width: 0;
  padding-right: 1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fc-ltr .fc-h-event .fc-start-resizer,
.fc-rtl .fc-h-event .fc-end-resizer {
  cursor: w-resize;
  left: -1px;
}
.fc-ltr .fc-h-event .fc-end-resizer,
.fc-rtl .fc-h-event .fc-start-resizer {
  cursor: e-resize;
  right: -1px;
}
.fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
  margin-left: -4px;
}
.fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
  margin-right: -4px;
}
.fc-day-grid-event {
  margin: 1px 2px 0;
  padding: 0 1px;
}
.fc-day-grid-event.fc-selected:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background: #000;
  opacity: 0.25;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
  filter: alpha(opacity=25);
}
.fc-day-grid-event .fc-content {
  white-space: nowrap;
  overflow: hidden;
}
.fc-day-grid-event .fc-time {
  font-weight: bold;
}
tr:first-child > td > .fc-day-grid-event {
  margin-top: 2px;
}
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
  margin-left: -2px;
}
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
  margin-right: -2px;
}
a.fc-more {
  margin: 1px 3px;
  font-size: 0.85em;
  cursor: pointer;
  text-decoration: none;
}
a.fc-more:hover {
  text-decoration: underline;
}
.fc-limited {
  display: none;
}
.fc-day-grid .fc-row {
  z-index: 1;
}
.fc-more-popover {
  z-index: 2;
  width: 220px;
}
.fc-more-popover .fc-event-container {
  padding: 10px;
}
.fc-now-indicator {
  position: absolute;
  border: 0 solid #f00;
}
.fc-unselectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.fc-toolbar {
  padding: 1rem 1rem 0.75rem;
  border: 1px solid rgba(0,0,0,0.125);
  background: rgba(0,0,0,0.25);
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -o-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -ms-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: linear-gradient(to bottom right, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  text-align: center;
}
.fc-toolbar.fc-header-toolbar {
  margin-bottom: 1em;
}
.fc-toolbar.fc-footer-toolbar {
  margin-top: 1em;
}
.fc-toolbar .fc-left {
  float: left;
}
.fc-toolbar .fc-right {
  float: right;
}
.fc-toolbar .fc-center {
  display: inline-block;
  text-align: center;
}
.fc-toolbar h2 {
  margin: 0;
  font-size: 1.5rem;
  margin-top: 0.4rem;
}
.fc-toolbar button {
  position: relative;
}
.fc-toolbar button:focus {
  z-index: 5;
}
.fc-toolbar .fc-state-down {
  z-index: 3;
}
.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
  z-index: 2;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  z-index: 4;
}
.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.fc-view,
.fc-view > table {
  position: relative;
  z-index: 1;
}
.fc-view {
  border-radius: 2px;
  background: rgba(0,0,0,0.125);
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -o-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -ms-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: linear-gradient(to bottom right, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
}
.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
  padding-bottom: 1em;
}
.fc-basic-view .fc-body .fc-row {
  min-height: 4em;
}
.fc-basic-view .fc-day-top .fc-week-number {
  min-width: 1.5em;
  text-align: center;
  background-color: #f2f2f2;
  color: #808080;
}
.fc-basic-view td.fc-week-number {
  text-align: center;
}
.fc-basic-view td.fc-week-number > * {
  display: inline-block;
  min-width: 1.25em;
}
.fc-day-top.fc-other-month {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
.fc-basic-view .fc-week-number,
.fc-basic-view .fc-day-number {
  padding: 2px;
}
.fc-basic-view th.fc-week-number,
.fc-basic-view th.fc-day-number {
  padding: 0 2px;
}
.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: right;
}
.fc-ltr .fc-basic-view .fc-day-top .fc-week-number {
  float: left;
  border-radius: 0 0 3px 0;
}
.fc-ltr .fc-axis {
  text-align: right;
}
.fc-ltr .fc-time-grid .fc-event-container {
  margin: 0 2.5% 0 2px;
}
.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
  left: 0;
  border-width: 5px 0 5px 6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.fc-ltr .fc-list-heading-main {
  float: left;
}
.fc-ltr .fc-list-heading-alt {
  float: right;
}
.fc-ltr .fc-list-item-marker {
  padding-right: 0;
}
.fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2;
}
.fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3em;
}
.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-bottom: 1em;
}
.ui-widget td.fc-axis {
  font-weight: normal;
}
.fc-time-grid-container,
.fc-time-grid {
  position: relative;
  z-index: 1;
}
.fc-time-grid {
  min-height: 100%;
}
.fc-time-grid table {
  border: 0 hidden transparent;
}
.fc-time-grid > .fc-bg {
  z-index: 1;
}
.fc-time-grid .fc-content-col {
  position: relative;
}
.fc-time-grid .fc-content-skeleton {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
}
.fc-time-grid .fc-business-container {
  position: relative;
  z-index: 1;
}
.fc-time-grid .fc-bgevent-container {
  position: relative;
  z-index: 2;
}
.fc-time-grid .fc-highlight-container {
  position: relative;
  z-index: 3;
  position: relative;
}
.fc-time-grid .fc-event-container {
  position: relative;
  z-index: 4;
}
.fc-time-grid .fc-now-indicator-line {
  z-index: 5;
  border-top-width: 1px;
  left: 0;
  right: 0;
}
.fc-time-grid .fc-helper-container {
  position: relative;
  z-index: 6;
}
.fc-time-grid .fc-slats td {
  height: 1.5em;
  border-bottom: 0;
}
.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: dotted;
}
.fc-time-grid .fc-slats .ui-widget-content {
  background: none;
}
.fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0;
}
.fc-time-grid .fc-bgevent {
  left: 0;
  right: 0;
}
.fc-time-grid .fc-now-indicator-arrow {
  margin-top: -5px;
}
.fc-time-grid .fc-slats,
.fc-time-grid > hr {
  position: relative;
  z-index: 2;
}
.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
  position: absolute;
  z-index: 1;
}
.fc-v-event.fc-not-start {
  border-top-width: 0;
  padding-top: 1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.fc-v-event.fc-not-end {
  border-bottom-width: 0;
  padding-bottom: 1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.fc-time-grid-event {
  overflow: hidden;
}
.fc-time-grid-event.fc-selected {
  overflow: visible;
}
.fc-time-grid-event.fc-selected .fc-bg {
  display: none;
}
.fc-time-grid-event.fc-selected .fc-resizer {
  border-radius: 5px;
  border-width: 1px;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-color: inherit;
  background: rgba(255,255,255,0.5);
  left: 50%;
  margin-left: -5px;
  bottom: -5px;
}
.fc-time-grid-event .fc-content {
  overflow: hidden;
}
.fc-time-grid-event .fc-time {
  font-size: 0.85em;
  white-space: nowrap;
}
.fc-time-grid-event.fc-short .fc-content {
  white-space: nowrap;
}
.fc-time-grid-event.fc-short .fc-time span {
  display: none;
}
.fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start);
}
.fc-time-grid-event.fc-short .fc-time:after {
  content: "\000A0-\000A0";
}
.fc-time-grid-event.fc-short .fc-title {
  font-size: 0.85em;
  padding: 0;
}
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  overflow: hidden;
  line-height: 8px;
  font-size: 11px;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
}
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
  content: "=";
}
.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-title {
  padding: 0 1px;
}
.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
  display: inline-block;
  vertical-align: top;
}
.fc-event-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.fc-list-view {
  border-width: 1px;
  border-style: solid;
}
.fc-list-table td {
  border-width: 1px 0 0;
  padding: 8px 14px;
}
.fc-list-table tr:first-child td {
  border-top-width: 0;
}
.fc-list-heading {
  border-bottom-width: 1px;
}
.fc-list-heading td {
  font-weight: bold;
}
.fc-list-item.fc-has-url {
  cursor: pointer;
}
.fc-list-item:hover td {
  background-color: rgba(255,255,255,0.5);
}
.fc-list-item-marker,
.fc-list-item-time {
  white-space: nowrap;
  width: 1px;
}
.fc-list-item-title a {
  text-decoration: none;
  color: inherit;
}
.fc-list-item-title a:hover {
  text-decoration: underline;
}
.fc-list-empty-wrap2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fc-list-empty-wrap1 {
  width: 100%;
  height: 100%;
  display: table;
}
.fc-list-empty {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
#external-events .fc-event {
  margin-bottom: 0.5rem;
  padding: 0.25rem;
}
@media screen and (max-width: 575px) {
  .fc .fc-toolbar {
    zoom: 1;
  }
  .fc .fc-toolbar:before,
  .fc .fc-toolbar:after {
    content: "";
    display: table;
  }
  .fc .fc-toolbar:after {
    clear: both;
  }
  .fc .fc-toolbar .fc-left .fc-button-group {
    float: left;
    width: 66.66666666666666%;
  }
  .fc .fc-toolbar .fc-left .fc-button-group > button {
    width: 50%;
  }
  .fc .fc-toolbar .fc-left .fc-today-button {
    margin: 0;
    float: left;
    width: 33.33333333333333%;
  }
  .fc .fc-toolbar .fc-right .fc-button-group {
    width: 100%;
    float: none;
  }
  .fc .fc-toolbar .fc-right .fc-button-group > button {
    width: 33.33333333333333%;
  }
  .fc .fc-toolbar .fc-left,
  .fc .fc-toolbar .fc-right {
    zoom: 1;
    margin-bottom: 1rem;
    display: block;
    position: relative;
    width: 100%;
    float: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .fc .fc-toolbar .fc-left:before,
  .fc .fc-toolbar .fc-right:before,
  .fc .fc-toolbar .fc-left:after,
  .fc .fc-toolbar .fc-right:after {
    content: "";
    display: table;
  }
  .fc .fc-toolbar .fc-left:after,
  .fc .fc-toolbar .fc-right:after {
    clear: both;
  }
  .fc .fc-toolbar .fc-left > *,
  .fc .fc-toolbar .fc-right > * {
    float: none;
  }
  #calendar-add-event {
    display: block;
    position: relative;
    top: auto;
    right: auto;
    margin-top: 1rem;
  }
}
.chart {
  height: 300px;
}
@media screen and (max-width: 1199px) {
  .chart {
    height: 262.5px;
  }
}
@media screen and (max-width: 991px) {
  .chart {
    height: 225px;
  }
}
@media screen and (max-width: 767px) {
  .chart {
    height: 187.5px;
  }
}
@media screen and (max-width: 575px) {
  .chart {
    height: 150px;
  }
}
.chart.-xl {
  height: 396px;
}
@media screen and (max-width: 1199px) {
  .chart.-xl {
    height: 346.5px;
  }
}
@media screen and (max-width: 991px) {
  .chart.-xl {
    height: 297px;
  }
}
@media screen and (max-width: 767px) {
  .chart.-xl {
    height: 247.5px;
  }
}
@media screen and (max-width: 575px) {
  .chart.-xl {
    height: 198px;
  }
}
.chart.-lg {
  height: 345px;
}
@media screen and (max-width: 1199px) {
  .chart.-lg {
    height: 301.875px;
  }
}
@media screen and (max-width: 991px) {
  .chart.-lg {
    height: 258.75px;
  }
}
@media screen and (max-width: 767px) {
  .chart.-lg {
    height: 215.62499999999997px;
  }
}
@media screen and (max-width: 575px) {
  .chart.-lg {
    height: 172.5px;
  }
}
.chart.-md {
  height: 300px;
}
@media screen and (max-width: 1199px) {
  .chart.-md {
    height: 262.5px;
  }
}
@media screen and (max-width: 991px) {
  .chart.-md {
    height: 225px;
  }
}
@media screen and (max-width: 767px) {
  .chart.-md {
    height: 187.5px;
  }
}
@media screen and (max-width: 575px) {
  .chart.-md {
    height: 150px;
  }
}
.chart.-sm {
  height: 225px;
}
@media screen and (max-width: 1199px) {
  .chart.-sm {
    height: 196.875px;
  }
}
@media screen and (max-width: 991px) {
  .chart.-sm {
    height: 168.75px;
  }
}
@media screen and (max-width: 767px) {
  .chart.-sm {
    height: 140.625px;
  }
}
@media screen and (max-width: 575px) {
  .chart.-sm {
    height: 112.5px;
  }
}
.chart.-xs {
  height: 150px;
}
@media screen and (max-width: 1199px) {
  .chart.-xs {
    height: 131.25px;
  }
}
@media screen and (max-width: 991px) {
  .chart.-xs {
    height: 112.5px;
  }
}
@media screen and (max-width: 767px) {
  .chart.-xs {
    height: 93.75px;
  }
}
@media screen and (max-width: 575px) {
  .chart.-xs {
    height: 75px;
  }
}
.chart .flot-text .flot-x-axis,
.chart .flot-text .flot-y-axis {
  color: rgba(255,255,255,0.5);
}
.chart .legend .legendLabel {
  color: rgba(255,255,255,0.85);
}
.jqstooltip {
  border: 0 !important;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
#flot-tooltip {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  font-size: 12px;
  padding: 0.25rem 0.5rem;
  background: rgba(0,0,0,0.5);
  color: rgba(255,255,255,0.85);
}
.morris-hover {
  position: absolute;
  z-index: 1000;
}
.morris-hover.morris-default-style {
  border-radius: 2px;
  padding: 6px;
  color: rgba(255,255,255,0.85);
  background: rgba(0,0,0,0.7);
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.7), rgba(0,0,0,0.8));
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.7), rgba(0,0,0,0.8));
  background: -o-linear-gradient(top left, rgba(0,0,0,0.7), rgba(0,0,0,0.8));
  background: -ms-linear-gradient(top left, rgba(0,0,0,0.7), rgba(0,0,0,0.8));
  background: linear-gradient(to bottom right, rgba(0,0,0,0.7), rgba(0,0,0,0.8));
  border: solid 1px rgba(0,0,0,0.5);
  font-family: sans-serif;
  font-size: 12px;
  text-align: center;
}
.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0.25em 0;
}
.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}
.datepicker {
  border-radius: 3px;
  direction: ltr;
}
.datepicker-inline {
  width: 300px;
}
.datepicker-inline table {
  width: 100%;
}
.datepicker-inline table td,
.datepicker-inline table th {
  padding-top: 5px;
  padding-bottom: 5px;
}
.datepicker-inline thead {
  color: rgba(255,255,255,0.95);
}
.datepicker-inline .prev,
.datepicker-inline .next,
.datepicker-inline .datepicker-switch {
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  color: rgba(255,255,255,0.85);
}
.datepicker.datepicker-rtl {
  direction: rtl;
}
.datepicker.datepicker-rtl.dropdown-menu {
  left: auto;
}
.datepicker.datepicker-rtl table tr td span {
  float: right;
}
.datepicker-dropdown {
  top: 0;
  left: 0;
  padding: 4px;
}
.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(0,0,0,0.15);
  border-top: 0;
  border-bottom-color: rgba(0,0,0,0.2);
  position: absolute;
}
.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute;
}
.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}
.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}
.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}
.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}
.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}
.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid rgba(0,0,0,0.15);
}
.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}
.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.datepicker table tr td,
.datepicker table tr th {
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  border: none;
}
.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}
.datepicker table tr td {
  -webkit-transition: color 0.3s ease, background 0.3s ease;
  -moz-transition: color 0.3s ease, background 0.3s ease;
  -o-transition: color 0.3s ease, background 0.3s ease;
  -ms-transition: color 0.3s ease, background 0.3s ease;
  transition: color 0.3s ease, background 0.3s ease;
}
.datepicker table tr td.old,
.datepicker table tr td.new {
  color: rgba(255,255,255,0.5);
}
.datepicker table tr td.day:hover,
.datepicker table tr td.focused {
  background: rgba(0,0,0,0.25);
  cursor: pointer;
}
.datepicker table tr td.disabled {
  background: none;
  color: rgba(255,255,255,0.5);
  cursor: default;
}
.datepicker table tr td.disabled:hover {
  background: none;
  color: rgba(255,255,255,0.5);
  cursor: default;
}
.datepicker table tr td.highlighted {
  color: #000;
  background-color: rgba(236,110,173,0.33);
  border-color: rgba(236,110,173,0.5);
  border-radius: 0;
}
.datepicker table tr td.highlighted:focus,
.datepicker table tr td.highlighted.focus {
  color: #000;
  background-color: rgba(236,110,173,0.33);
  border-color: rgba(236,110,173,0.4);
}
.datepicker table tr td.highlighted:hover,
.datepicker table tr td.highlighted:active,
.datepicker table tr td.highlighted.active {
  color: #000;
  background-color: rgba(236,110,173,0.33);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td.highlighted:active:hover,
.datepicker table tr td.highlighted.active:hover,
.datepicker table tr td.highlighted:active:focus,
.datepicker table tr td.highlighted.active:focus,
.datepicker table tr td.highlighted:active.focus,
.datepicker table tr td.highlighted.active.focus {
  color: #000;
  background-color: rgba(236,110,173,0.33);
  border-color: rgba(236,110,173,0.4);
}
.datepicker table tr td.highlighted.disabled:hover,
.datepicker table tr td.highlighted[disabled]:hover {
  background-color: rgba(236,110,173,0.33);
  border-color: rgba(236,110,173,0.5);
}
fieldset[disabled] .datepicker table tr td.highlighted:hover {
  background-color: rgba(236,110,173,0.33);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td.highlighted.disabled:focus,
.datepicker table tr td.highlighted[disabled]:focus {
  background-color: rgba(236,110,173,0.33);
  border-color: rgba(236,110,173,0.5);
}
fieldset[disabled] .datepicker table tr td.highlighted:focus {
  background-color: rgba(236,110,173,0.33);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td.highlighted.disabled.focus,
.datepicker table tr td.highlighted[disabled].focus {
  background-color: rgba(236,110,173,0.33);
  border-color: rgba(236,110,173,0.5);
}
fieldset[disabled] .datepicker table tr td.highlighted.focus {
  background-color: rgba(236,110,173,0.33);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td.highlighted.focused {
  background: rgba(236,110,173,0.33);
}
.datepicker table tr td.highlighted.disabled {
  background: rgba(236,110,173,0.33);
  color: #777;
}
.datepicker table tr td.highlighted.disabled:active {
  background: rgba(236,110,173,0.33);
  color: #777;
}
.datepicker table tr td.today {
  color: #000;
  background-color: #ffdb99;
  border-color: #ffb733;
}
.datepicker table tr td.today:focus,
.datepicker table tr td.today.focus {
  color: #000;
  background-color: #ffc966;
  border-color: #b37400;
}
.datepicker table tr td.today:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today.active {
  color: #000;
  background-color: #ffc966;
  border-color: #f59e00;
}
.datepicker table tr td.today:active:hover,
.datepicker table tr td.today.active:hover,
.datepicker table tr td.today:active:focus,
.datepicker table tr td.today.active:focus,
.datepicker table tr td.today:active.focus,
.datepicker table tr td.today.active.focus {
  color: #000;
  background-color: #ffbc42;
  border-color: #b37400;
}
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today[disabled]:hover {
  background-color: #ffdb99;
  border-color: #ffb733;
}
fieldset[disabled] .datepicker table tr td.today:hover {
  background-color: #ffdb99;
  border-color: #ffb733;
}
.datepicker table tr td.today.disabled:focus,
.datepicker table tr td.today[disabled]:focus {
  background-color: #ffdb99;
  border-color: #ffb733;
}
fieldset[disabled] .datepicker table tr td.today:focus {
  background-color: #ffdb99;
  border-color: #ffb733;
}
.datepicker table tr td.today.disabled.focus,
.datepicker table tr td.today[disabled].focus {
  background-color: #ffdb99;
  border-color: #ffb733;
}
fieldset[disabled] .datepicker table tr td.today.focus {
  background-color: #ffdb99;
  border-color: #ffb733;
}
.datepicker table tr td.today.focused {
  background: #ffc966;
}
.datepicker table tr td.today.disabled {
  background: #ffdb99;
  color: #777;
}
.datepicker table tr td.today.disabled:active {
  background: #ffdb99;
  color: #777;
}
.datepicker table tr td.range {
  color: #000;
  background-color: #eee;
  border-color: #bbb;
  border-radius: 0;
}
.datepicker table tr td.range:focus,
.datepicker table tr td.range.focus {
  color: #000;
  background-color: #d5d5d5;
  border-color: #7c7c7c;
}
.datepicker table tr td.range:hover,
.datepicker table tr td.range:active,
.datepicker table tr td.range.active {
  color: #000;
  background-color: #d5d5d5;
  border-color: #9d9d9d;
}
.datepicker table tr td.range:active:hover,
.datepicker table tr td.range.active:hover,
.datepicker table tr td.range:active:focus,
.datepicker table tr td.range.active:focus,
.datepicker table tr td.range:active.focus,
.datepicker table tr td.range.active.focus {
  color: #000;
  background-color: #c3c3c3;
  border-color: #7c7c7c;
}
.datepicker table tr td.range.disabled:hover,
.datepicker table tr td.range[disabled]:hover {
  background-color: #eee;
  border-color: #bbb;
}
fieldset[disabled] .datepicker table tr td.range:hover {
  background-color: #eee;
  border-color: #bbb;
}
.datepicker table tr td.range.disabled:focus,
.datepicker table tr td.range[disabled]:focus {
  background-color: #eee;
  border-color: #bbb;
}
fieldset[disabled] .datepicker table tr td.range:focus {
  background-color: #eee;
  border-color: #bbb;
}
.datepicker table tr td.range.disabled.focus,
.datepicker table tr td.range[disabled].focus {
  background-color: #eee;
  border-color: #bbb;
}
fieldset[disabled] .datepicker table tr td.range.focus {
  background-color: #eee;
  border-color: #bbb;
}
.datepicker table tr td.range.focused {
  background: #d5d5d5;
}
.datepicker table tr td.range.disabled {
  background: #eee;
  color: #777;
}
.datepicker table tr td.range.disabled:active {
  background: #eee;
  color: #777;
}
.datepicker table tr td.range.highlighted {
  color: #000;
  background-color: #e4eef3;
  border-color: #9dc1d3;
}
.datepicker table tr td.range.highlighted:focus,
.datepicker table tr td.range.highlighted.focus {
  color: #000;
  background-color: #c1d7e3;
  border-color: #4b88a6;
}
.datepicker table tr td.range.highlighted:hover,
.datepicker table tr td.range.highlighted:active,
.datepicker table tr td.range.highlighted.active {
  color: #000;
  background-color: #c1d7e3;
  border-color: #73a6c0;
}
.datepicker table tr td.range.highlighted:active:hover,
.datepicker table tr td.range.highlighted.active:hover,
.datepicker table tr td.range.highlighted:active:focus,
.datepicker table tr td.range.highlighted.active:focus,
.datepicker table tr td.range.highlighted:active.focus,
.datepicker table tr td.range.highlighted.active.focus {
  color: #000;
  background-color: #a8c8d8;
  border-color: #4b88a6;
}
.datepicker table tr td.range.highlighted.disabled:hover,
.datepicker table tr td.range.highlighted[disabled]:hover {
  background-color: #e4eef3;
  border-color: #9dc1d3;
}
fieldset[disabled] .datepicker table tr td.range.highlighted:hover {
  background-color: #e4eef3;
  border-color: #9dc1d3;
}
.datepicker table tr td.range.highlighted.disabled:focus,
.datepicker table tr td.range.highlighted[disabled]:focus {
  background-color: #e4eef3;
  border-color: #9dc1d3;
}
fieldset[disabled] .datepicker table tr td.range.highlighted:focus {
  background-color: #e4eef3;
  border-color: #9dc1d3;
}
.datepicker table tr td.range.highlighted.disabled.focus,
.datepicker table tr td.range.highlighted[disabled].focus {
  background-color: #e4eef3;
  border-color: #9dc1d3;
}
fieldset[disabled] .datepicker table tr td.range.highlighted.focus {
  background-color: #e4eef3;
  border-color: #9dc1d3;
}
.datepicker table tr td.range.highlighted.focused {
  background: #c1d7e3;
}
.datepicker table tr td.range.highlighted.disabled {
  background: #e4eef3;
  color: #777;
}
.datepicker table tr td.range.highlighted.disabled:active {
  background: #e4eef3;
  color: #777;
}
.datepicker table tr td.range.today {
  color: #000;
  background-color: #f7ca77;
  border-color: #f1a417;
}
.datepicker table tr td.range.today:focus,
.datepicker table tr td.range.today.focus {
  color: #000;
  background-color: #f4b747;
  border-color: #815608;
}
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today.active {
  color: #000;
  background-color: #f4b747;
  border-color: #bf800c;
}
.datepicker table tr td.range.today:active:hover,
.datepicker table tr td.range.today.active:hover,
.datepicker table tr td.range.today:active:focus,
.datepicker table tr td.range.today.active:focus,
.datepicker table tr td.range.today:active.focus,
.datepicker table tr td.range.today.active.focus {
  color: #000;
  background-color: #f2aa25;
  border-color: #815608;
}
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today[disabled]:hover {
  background-color: #f7ca77;
  border-color: #f1a417;
}
fieldset[disabled] .datepicker table tr td.range.today:hover {
  background-color: #f7ca77;
  border-color: #f1a417;
}
.datepicker table tr td.range.today.disabled:focus,
.datepicker table tr td.range.today[disabled]:focus {
  background-color: #f7ca77;
  border-color: #f1a417;
}
fieldset[disabled] .datepicker table tr td.range.today:focus {
  background-color: #f7ca77;
  border-color: #f1a417;
}
.datepicker table tr td.range.today.disabled.focus,
.datepicker table tr td.range.today[disabled].focus {
  background-color: #f7ca77;
  border-color: #f1a417;
}
fieldset[disabled] .datepicker table tr td.range.today.focus {
  background-color: #f7ca77;
  border-color: #f1a417;
}
.datepicker table tr td.range.today.disabled {
  background: #f7ca77;
  color: #777;
}
.datepicker table tr td.range.today.disabled:active {
  background: #f7ca77;
  color: #777;
}
.datepicker table tr td.selected {
  color: #fff;
  background-color: #777;
  border-color: #555;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
}
.datepicker table tr td.selected.highlighted {
  color: #fff;
  background-color: #777;
  border-color: #555;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
}
.datepicker table tr td.selected:focus,
.datepicker table tr td.selected.highlighted:focus,
.datepicker table tr td.selected.focus,
.datepicker table tr td.selected.highlighted.focus {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #161616;
}
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.highlighted:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected.highlighted:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected.highlighted.active {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #373737;
}
.datepicker table tr td.selected:active:hover,
.datepicker table tr td.selected.highlighted:active:hover,
.datepicker table tr td.selected.active:hover,
.datepicker table tr td.selected.highlighted.active:hover,
.datepicker table tr td.selected:active:focus,
.datepicker table tr td.selected.highlighted:active:focus,
.datepicker table tr td.selected.active:focus,
.datepicker table tr td.selected.highlighted.active:focus,
.datepicker table tr td.selected:active.focus,
.datepicker table tr td.selected.highlighted:active.focus,
.datepicker table tr td.selected.active.focus,
.datepicker table tr td.selected.highlighted.active.focus {
  color: #fff;
  background-color: #4c4c4c;
  border-color: #161616;
}
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.highlighted.disabled:hover,
.datepicker table tr td.selected[disabled]:hover,
.datepicker table tr td.selected.highlighted[disabled]:hover {
  background-color: #777;
  border-color: #555;
}
fieldset[disabled] .datepicker table tr td.selected:hover,
fieldset[disabled] .datepicker table tr td.selected.highlighted:hover {
  background-color: #777;
  border-color: #555;
}
.datepicker table tr td.selected.disabled:focus,
.datepicker table tr td.selected.highlighted.disabled:focus,
.datepicker table tr td.selected[disabled]:focus,
.datepicker table tr td.selected.highlighted[disabled]:focus {
  background-color: #777;
  border-color: #555;
}
fieldset[disabled] .datepicker table tr td.selected:focus,
fieldset[disabled] .datepicker table tr td.selected.highlighted:focus {
  background-color: #777;
  border-color: #555;
}
.datepicker table tr td.selected.disabled.focus,
.datepicker table tr td.selected.highlighted.disabled.focus,
.datepicker table tr td.selected[disabled].focus,
.datepicker table tr td.selected.highlighted[disabled].focus {
  background-color: #777;
  border-color: #555;
}
fieldset[disabled] .datepicker table tr td.selected.focus,
fieldset[disabled] .datepicker table tr td.selected.highlighted.focus {
  background-color: #777;
  border-color: #555;
}
.datepicker table tr td.active {
  color: #fff;
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
}
.datepicker table tr td.active.highlighted {
  color: #fff;
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
}
.datepicker table tr td.active:focus,
.datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active.focus,
.datepicker table tr td.active.highlighted.focus {
  color: #fff;
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(232,79,156,0.5);
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active {
  color: #fff;
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(232,79,156,0.75);
}
.datepicker table tr td.active:active:hover,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active:hover,
.datepicker table tr td.active:active:focus,
.datepicker table tr td.active.highlighted:active:focus,
.datepicker table tr td.active.active:focus,
.datepicker table tr td.active.highlighted.active:focus,
.datepicker table tr td.active:active.focus,
.datepicker table tr td.active.highlighted:active.focus,
.datepicker table tr td.active.active.focus,
.datepicker table tr td.active.highlighted.active.focus {
  color: #fff;
  background-color: rgba(232,79,156,0.75);
  border-color: rgba(232,79,156,0.5);
}
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.highlighted.disabled:hover,
.datepicker table tr td.active[disabled]:hover,
.datepicker table tr td.active.highlighted[disabled]:hover {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
fieldset[disabled] .datepicker table tr td.active:hover,
fieldset[disabled] .datepicker table tr td.active.highlighted:hover {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.highlighted.disabled:focus,
.datepicker table tr td.active[disabled]:focus,
.datepicker table tr td.active.highlighted[disabled]:focus {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
fieldset[disabled] .datepicker table tr td.active:focus,
fieldset[disabled] .datepicker table tr td.active.highlighted:focus {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td.active.disabled.focus,
.datepicker table tr td.active.highlighted.disabled.focus,
.datepicker table tr td.active[disabled].focus,
.datepicker table tr td.active.highlighted[disabled].focus {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
fieldset[disabled] .datepicker table tr td.active.focus,
fieldset[disabled] .datepicker table tr td.active.highlighted.focus {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 2px;
}
.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background: #eee;
}
.datepicker table tr td span.disabled {
  background: none;
  color: #777;
  cursor: default;
}
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #777;
  cursor: default;
}
.datepicker table tr td span.active {
  color: #fff;
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
}
.datepicker table tr td span.active:hover {
  color: #fff;
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
}
.datepicker table tr td span.active.disabled {
  color: #fff;
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
}
.datepicker table tr td span.active.disabled:hover {
  color: #fff;
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
}
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus {
  color: #fff;
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(232,79,156,0.5);
}
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus {
  color: #fff;
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(232,79,156,0.5);
}
.datepicker table tr td span.active.focus,
.datepicker table tr td span.active:hover.focus {
  color: #fff;
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(232,79,156,0.5);
}
.datepicker table tr td span.active.disabled.focus,
.datepicker table tr td span.active.disabled:hover.focus {
  color: #fff;
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(232,79,156,0.5);
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active {
  color: #fff;
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(232,79,156,0.75);
}
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active {
  color: #fff;
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(232,79,156,0.75);
}
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active {
  color: #fff;
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(232,79,156,0.75);
}
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  color: #fff;
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(232,79,156,0.75);
}
.datepicker table tr td span.active:active:hover,
.datepicker table tr td span.active.disabled:active:hover,
.datepicker table tr td span.active.active:hover,
.datepicker table tr td span.active.disabled.active:hover,
.datepicker table tr td span.active:active:focus,
.datepicker table tr td span.active:hover:active:focus {
  color: #fff;
  background-color: rgba(232,79,156,0.75);
  border-color: rgba(232,79,156,0.5);
}
.datepicker table tr td span.active.disabled:active:focus,
.datepicker table tr td span.active.disabled:hover:active:focus {
  color: #fff;
  background-color: rgba(232,79,156,0.75);
  border-color: rgba(232,79,156,0.5);
}
.datepicker table tr td span.active.active:focus,
.datepicker table tr td span.active:hover.active:focus {
  color: #fff;
  background-color: rgba(232,79,156,0.75);
  border-color: rgba(232,79,156,0.5);
}
.datepicker table tr td span.active.disabled.active:focus,
.datepicker table tr td span.active.disabled:hover.active:focus {
  color: #fff;
  background-color: rgba(232,79,156,0.75);
  border-color: rgba(232,79,156,0.5);
}
.datepicker table tr td span.active:active.focus,
.datepicker table tr td span.active:hover:active.focus {
  color: #fff;
  background-color: rgba(232,79,156,0.75);
  border-color: rgba(232,79,156,0.5);
}
.datepicker table tr td span.active.disabled:active.focus,
.datepicker table tr td span.active.disabled:hover:active.focus {
  color: #fff;
  background-color: rgba(232,79,156,0.75);
  border-color: rgba(232,79,156,0.5);
}
.datepicker table tr td span.active.active.focus,
.datepicker table tr td span.active:hover.active.focus {
  color: #fff;
  background-color: rgba(232,79,156,0.75);
  border-color: rgba(232,79,156,0.5);
}
.datepicker table tr td span.active.disabled.active.focus {
  color: #fff;
  background-color: rgba(232,79,156,0.75);
  border-color: rgba(232,79,156,0.5);
}
.datepicker table tr td span.active.disabled:hover {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td span.active.disabled:hover.active.focus {
  color: #fff;
  background-color: rgba(232,79,156,0.75);
  border-color: rgba(232,79,156,0.5);
}
.datepicker table tr td span.active[disabled]:hover,
.datepicker table tr td span.active.disabled[disabled]:hover {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
fieldset[disabled] .datepicker table tr td span.active:hover,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active:hover.disabled:focus {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td span.active.disabled.disabled:focus,
.datepicker table tr td span.active.disabled:hover.disabled:focus {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td span.active[disabled]:focus,
.datepicker table tr td span.active:hover[disabled]:focus {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td span.active.disabled[disabled]:focus,
.datepicker table tr td span.active.disabled:hover[disabled]:focus {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
fieldset[disabled] .datepicker table tr td span.active:focus,
fieldset[disabled] .datepicker table tr td span.active:hover:focus {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
fieldset[disabled] .datepicker table tr td span.active.disabled:focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover:focus {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td span.active.disabled.focus,
.datepicker table tr td span.active:hover.disabled.focus {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td span.active.disabled.disabled.focus,
.datepicker table tr td span.active.disabled:hover.disabled.focus {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td span.active[disabled].focus,
.datepicker table tr td span.active:hover[disabled].focus {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td span.active.disabled[disabled].focus,
.datepicker table tr td span.active.disabled:hover[disabled].focus {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
fieldset[disabled] .datepicker table tr td span.active.focus,
fieldset[disabled] .datepicker table tr td span.active:hover.focus {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
fieldset[disabled] .datepicker table tr td span.active.disabled.focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover.focus {
  background-color: rgba(236,110,173,0.75);
  border-color: rgba(236,110,173,0.5);
}
.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #777;
}
.datepicker .datepicker-switch {
  width: 145px;
  cursor: pointer;
}
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
  cursor: pointer;
}
.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background: rgba(0,0,0,0.25);
}
.datepicker .prev.disabled,
.datepicker .next.disabled {
  visibility: hidden;
}
.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}
.input-group.date .input-group-addon {
  cursor: pointer;
}
.input-daterange {
  width: 100%;
}
.input-daterange input {
  text-align: center;
}
.input-daterange input:first-child {
  border-radius: 2px 0 0 2px;
}
.input-daterange input:last-child {
  border-radius: 0 2px 2px 0;
}
.input-daterange .input-group-addon {
  width: auto;
  min-width: 16px;
  padding: 4px 5px;
  line-height: 1.42857143;
  text-shadow: 0 1px 0 #fff;
  border-width: 1px 0;
  margin-left: -5px;
  margin-right: -5px;
}
.dropdown-menu {
  border-radius: 3px;
  padding: 0;
  width: 240px;
}
.dropdown-menu.-left {
  left: 0;
  right: auto;
}
.dropdown-menu.-right {
  right: 0;
  left: auto;
}
.dropdown-menu .dropdown-divider {
  margin: 0;
  background: rgba(255,255,255,0.15);
}
.dropdown-menu .dropdown-item,
.dropdown-menu a.dropdown-item {
  padding: 0.75rem 1.25rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: rgba(255,255,255,0.85);
}
.dropdown-menu .dropdown-item:hover,
.dropdown-menu a.dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu a.dropdown-item:focus,
.dropdown-menu .dropdown-item:active,
.dropdown-menu a.dropdown-item:active {
  background: rgba(255,255,255,0.15);
  color: #fff;
}
.dropdown-menu.-light {
  background: rgba(255,255,255,0.5);
  background: -webkit-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  background: -moz-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  background: -o-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  background: -ms-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  background: linear-gradient(to right, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
}
.dropdown-menu.-light .dropdown-divider {
  background: rgba(0,0,0,0.05);
}
.dropdown-menu.-light .dropdown-item {
  color: rgba(0,0,0,0.75);
}
.dropdown-menu.-light .dropdown-item:hover,
.dropdown-menu.-light .dropdown-item:focus,
.dropdown-menu.-light .dropdown-item:active {
  background: rgba(0,0,0,0.07);
  color: #000;
}
.dropdown-menu.-dark {
  background: rgba(0,0,0,0.5);
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.7));
  background: -moz-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.7));
  background: -o-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.7));
  background: -ms-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.7));
  background: linear-gradient(to right, rgba(0,0,0,0.8), rgba(0,0,0,0.7));
}
.dropdown-menu.-dark .dropdown-divider {
  background: rgba(255,255,255,0.125);
}
.dropdown-menu.-dark .dropdown-item {
  color: rgba(255,255,255,0.85);
}
.dropdown-menu.-dark .dropdown-item:hover,
.dropdown-menu.-dark .dropdown-item:focus,
.dropdown-menu.-dark .dropdown-item:active {
  background: rgba(255,255,255,0.07);
  color: #fff;
}
.dropdown-menu.-primary {
  background: #ec6ead;
  background: -webkit-linear-gradient(left, #ec6ead, rgba(236,110,173,0.8));
  background: -moz-linear-gradient(left, #ec6ead, rgba(236,110,173,0.8));
  background: -o-linear-gradient(left, #ec6ead, rgba(236,110,173,0.8));
  background: -ms-linear-gradient(left, #ec6ead, rgba(236,110,173,0.8));
  background: linear-gradient(to right, #ec6ead, rgba(236,110,173,0.8));
}
.dropdown-menu.-secondary {
  background: #3494e6;
  background: -webkit-linear-gradient(left, #3494e6, rgba(52,148,230,0.8));
  background: -moz-linear-gradient(left, #3494e6, rgba(52,148,230,0.8));
  background: -o-linear-gradient(left, #3494e6, rgba(52,148,230,0.8));
  background: -ms-linear-gradient(left, #3494e6, rgba(52,148,230,0.8));
  background: linear-gradient(to right, #3494e6, rgba(52,148,230,0.8));
}
.dropdown-menu.-success {
  background: #3d9970;
  background: -webkit-linear-gradient(left, #3d9970, rgba(61,153,112,0.8));
  background: -moz-linear-gradient(left, #3d9970, rgba(61,153,112,0.8));
  background: -o-linear-gradient(left, #3d9970, rgba(61,153,112,0.8));
  background: -ms-linear-gradient(left, #3d9970, rgba(61,153,112,0.8));
  background: linear-gradient(to right, #3d9970, rgba(61,153,112,0.8));
}
.dropdown-menu.-danger {
  background: #ff4136;
  background: -webkit-linear-gradient(left, #ff4136, rgba(255,65,54,0.8));
  background: -moz-linear-gradient(left, #ff4136, rgba(255,65,54,0.8));
  background: -o-linear-gradient(left, #ff4136, rgba(255,65,54,0.8));
  background: -ms-linear-gradient(left, #ff4136, rgba(255,65,54,0.8));
  background: linear-gradient(to right, #ff4136, rgba(255,65,54,0.8));
}
.dropdown-menu.-warning {
  background: #ff851b;
  background: -webkit-linear-gradient(left, #ff851b, rgba(255,133,27,0.8));
  background: -moz-linear-gradient(left, #ff851b, rgba(255,133,27,0.8));
  background: -o-linear-gradient(left, #ff851b, rgba(255,133,27,0.8));
  background: -ms-linear-gradient(left, #ff851b, rgba(255,133,27,0.8));
  background: linear-gradient(to right, #ff851b, rgba(255,133,27,0.8));
}
.dropdown-menu.-info {
  background: #0074d9;
  background: -webkit-linear-gradient(left, #0074d9, rgba(0,116,217,0.8));
  background: -moz-linear-gradient(left, #0074d9, rgba(0,116,217,0.8));
  background: -o-linear-gradient(left, #0074d9, rgba(0,116,217,0.8));
  background: -ms-linear-gradient(left, #0074d9, rgba(0,116,217,0.8));
  background: linear-gradient(to right, #0074d9, rgba(0,116,217,0.8));
}
.error-section {
  margin: 4rem auto;
  text-align: center;
}
.error-section .error-content {
  display: block;
  z-index: 1;
  position: relative;
}
.error-section .error-title {
  font-weight: 100;
  font-size: 5rem;
  text-transform: uppercase;
  display: block;
  position: relative;
  margin: 0 auto;
}
.error-section .error-subtitle {
  font-weight: 300;
  font-size: 1.5rem;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.error-section .error-icon {
  display: block;
  position: absolute;
  left: 50%;
  bottom: -5rem;
  font-size: 20rem;
  opacity: 0.25;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
  filter: alpha(opacity=25);
  z-index: 0;
}
.error-section .btn {
  z-index: 1;
  margin-top: 3rem;
}
form legend,
.form legend {
  font-size: 1.25rem;
  font-weight: 300;
}
form select.form-control:not([multiple]):not([size]),
.form select.form-control:not([multiple]):not([size]) {
  padding: 0 1rem;
  height: 2.9rem;
}
form .form-control,
.form .form-control {
  border-radius: 2px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 0.75rem 1rem;
}
form .form-control.-xs,
.form .form-control.-xs {
  font-size: 80%;
  padding: 0.375rem 1rem;
}
form .form-control.-sm,
.form .form-control.-sm {
  font-size: 90%;
  padding: 0.5625rem 1rem;
}
form .form-control.-md,
.form .form-control.-md {
  padding: 0.75rem 1rem;
}
form .form-control.-lg,
.form .form-control.-lg {
  font-size: 110%;
  padding: 0.9375rem 1rem;
}
form .form-control.-xl,
.form .form-control.-xl {
  font-size: 120%;
  padding: 1.125rem 1rem;
}
form .form-check,
.form .form-check {
  margin-left: 0;
}
form .form-check input,
.form .form-check input {
  display: none;
}
form .form-check input:checked + label::after,
.form .form-check input:checked + label::after {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
form .form-check label,
.form .form-check label {
  cursor: pointer;
  margin-left: 0;
}
form .form-check label::before,
.form .form-check label::before {
  font-family: 'FontAwesome';
  content: "";
  display: block;
  text-align: center;
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 0.75rem;
  position: relative;
}
form .form-check label::after,
.form .form-check label::after {
  font-family: 'FontAwesome';
  content: "";
  display: block;
  text-align: center;
  width: 24px;
  height: 24px;
  margin-right: 0.75rem;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: rotate(30deg) scale(0.5) translateZ(0);
  -moz-transform: rotate(30deg) scale(0.5) translateZ(0);
  -o-transform: rotate(30deg) scale(0.5) translateZ(0);
  -ms-transform: rotate(30deg) scale(0.5) translateZ(0);
  transform: rotate(30deg) scale(0.5) translateZ(0);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
form .form-check.-radio label::before,
.form .form-check.-radio label::before {
  border-radius: 100%;
}
form .form-check.-radio label::after,
.form .form-check.-radio label::after {
  content: "\f111";
}
form .form-check.-checkbox label::before,
.form .form-check.-checkbox label::before {
  border-radius: 2px;
}
form .form-check.-checkbox label::after,
.form .form-check.-checkbox label::after {
  content: "\f00c";
}
form .help-block,
.form .help-block {
  font-size: 0.9rem;
  margin-top: 0.5rem;
  color: rgba(255,255,255,0.5);
}
form.-dark .form-control,
.form.-dark .form-control,
form.-dark .form-check label::before,
.form.-dark .form-check label::before {
  color: #fff;
  border-color: rgba(0,0,0,0.125);
  background-color: rgba(0,0,0,0);
  background-image: -webkit-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -moz-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -o-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -ms-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: linear-gradient(to bottom right, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
}
form.-dark .form-control:disabled,
.form.-dark .form-control:disabled,
form.-dark .form-check label::before:disabled,
.form.-dark .form-check label::before:disabled {
  background-color: rgba(255,255,255,0.15);
}
form.-dark .form-check label::before,
.form.-dark .form-check label::before {
  background-image: -webkit-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background-image: -moz-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background-image: -o-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background-image: -ms-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background-image: linear-gradient(to bottom right, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
}
form.-dark .form-control:active,
.form.-dark .form-control:active,
form.-dark .form-control:focus,
.form.-dark .form-control:focus {
  background-color: rgba(0,0,0,0.125);
}
form.-dark .form-control::-webkit-input-placeholder,
.form.-dark .form-control::-webkit-input-placeholder {
  color: rgba(255,255,255,0.5);
}
form.-dark .form-control::-moz-placeholder,
.form.-dark .form-control::-moz-placeholder {
  color: rgba(255,255,255,0.5);
}
form.-dark .form-control:-moz-placeholder,
.form.-dark .form-control:-moz-placeholder {
  color: rgba(255,255,255,0.5);
}
form.-dark .form-control:-ms-input-placeholder,
.form.-dark .form-control:-ms-input-placeholder {
  color: rgba(255,255,255,0.5);
}
form.-dark .form-control.disabled label,
.form.-dark .form-control.disabled label,
form.-dark .form-check.disabled label,
.form.-dark .form-check.disabled label {
  color: rgba(255,255,255,0.5);
}
form.-dark .form-control.disabled label::before,
.form.-dark .form-control.disabled label::before,
form.-dark .form-check.disabled label::before,
.form.-dark .form-check.disabled label::before {
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -o-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -ms-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: linear-gradient(to bottom right, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
}
form.-dark .form-control.disabled label::after,
.form.-dark .form-control.disabled label::after,
form.-dark .form-check.disabled label::after,
.form.-dark .form-check.disabled label::after {
  color: rgba(255,255,255,0.5);
}
form.-dark select.form-control:not([multiple]):not([size]) option,
.form.-dark select.form-control:not([multiple]):not([size]) option {
  color: #fff;
  background: #000;
}
form.-light .form-control,
.form.-light .form-control,
form.-light .form-check label::before,
.form.-light .form-check label::before {
  color: #fff;
  border-color: rgba(255,255,255,0.05);
  background-color: rgba(255,255,255,0);
  background-image: -webkit-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background-image: -moz-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background-image: -o-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background-image: -ms-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background-image: linear-gradient(to bottom right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
}
form.-light .form-control:disabled,
.form.-light .form-control:disabled,
form.-light .form-check label::before:disabled,
.form.-light .form-check label::before:disabled {
  background-color: rgba(255,255,255,0.15);
}
form.-light .form-check label::before,
.form.-light .form-check label::before {
  background-image: -webkit-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.5));
  background-image: -moz-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.5));
  background-image: -o-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.5));
  background-image: -ms-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.5));
  background-image: linear-gradient(to bottom right, rgba(255,255,255,0.15), rgba(255,255,255,0.5));
}
form.-light .form-control:active,
.form.-light .form-control:active,
form.-light .form-control:focus,
.form.-light .form-control:focus {
  background-color: rgba(255,255,255,0.05);
}
form.-light .form-control::-webkit-input-placeholder,
.form.-light .form-control::-webkit-input-placeholder {
  color: rgba(255,255,255,0.5);
}
form.-light .form-control::-moz-placeholder,
.form.-light .form-control::-moz-placeholder {
  color: rgba(255,255,255,0.5);
}
form.-light .form-control:-moz-placeholder,
.form.-light .form-control:-moz-placeholder {
  color: rgba(255,255,255,0.5);
}
form.-light .form-control:-ms-input-placeholder,
.form.-light .form-control:-ms-input-placeholder {
  color: rgba(255,255,255,0.5);
}
form.-light .form-control.disabled label,
.form.-light .form-control.disabled label,
form.-light .form-check.disabled label,
.form.-light .form-check.disabled label {
  color: rgba(255,255,255,0.5);
}
form.-light .form-control.disabled label::before,
.form.-light .form-control.disabled label::before,
form.-light .form-check.disabled label::before,
.form.-light .form-check.disabled label::before {
  background: -webkit-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -moz-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -o-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -ms-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: linear-gradient(to bottom right, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
}
form.-light .form-control.disabled label::after,
.form.-light .form-control.disabled label::after,
form.-light .form-check.disabled label::after,
.form.-light .form-check.disabled label::after {
  color: rgba(255,255,255,0.5);
}
form.-light select.form-control:not([multiple]):not([size]) option,
.form.-light select.form-control:not([multiple]):not([size]) option {
  color: #000;
  background: #fff;
}
form .form-group.-primary label,
.form .form-group.-primary label,
form .form-group.has-primary label,
.form .form-group.has-primary label,
form .form-group.-primary .help-block,
.form .form-group.-primary .help-block,
form .form-group.has-primary .help-block,
.form .form-group.has-primary .help-block {
  color: #ec6ead;
}
form .form-group.-primary .form-control,
.form .form-group.-primary .form-control,
form .form-group.has-primary .form-control,
.form .form-group.has-primary .form-control,
form .form-group.-primary .form-check label::before,
.form .form-group.-primary .form-check label::before,
form .form-group.has-primary .form-check label::before,
.form .form-group.has-primary .form-check label::before {
  border-color: rgba(236,110,173,0.66);
}
form .form-group.-primary .form-control:active,
.form .form-group.-primary .form-control:active,
form .form-group.has-primary .form-control:active,
.form .form-group.has-primary .form-control:active,
form .form-group.-primary .form-check label::before:active,
.form .form-group.-primary .form-check label::before:active,
form .form-group.has-primary .form-check label::before:active,
.form .form-group.has-primary .form-check label::before:active,
form .form-group.-primary .form-control:focus,
.form .form-group.-primary .form-control:focus,
form .form-group.has-primary .form-control:focus,
.form .form-group.has-primary .form-control:focus,
form .form-group.-primary .form-check label::before:focus,
.form .form-group.-primary .form-check label::before:focus,
form .form-group.has-primary .form-check label::before:focus,
.form .form-group.has-primary .form-check label::before:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
form .form-group.-secondary label,
.form .form-group.-secondary label,
form .form-group.has-secondary label,
.form .form-group.has-secondary label,
form .form-group.-secondary .help-block,
.form .form-group.-secondary .help-block,
form .form-group.has-secondary .help-block,
.form .form-group.has-secondary .help-block {
  color: #3494e6;
}
form .form-group.-secondary .form-control,
.form .form-group.-secondary .form-control,
form .form-group.has-secondary .form-control,
.form .form-group.has-secondary .form-control,
form .form-group.-secondary .form-check label::before,
.form .form-group.-secondary .form-check label::before,
form .form-group.has-secondary .form-check label::before,
.form .form-group.has-secondary .form-check label::before {
  border-color: rgba(52,148,230,0.66);
}
form .form-group.-secondary .form-control:active,
.form .form-group.-secondary .form-control:active,
form .form-group.has-secondary .form-control:active,
.form .form-group.has-secondary .form-control:active,
form .form-group.-secondary .form-check label::before:active,
.form .form-group.-secondary .form-check label::before:active,
form .form-group.has-secondary .form-check label::before:active,
.form .form-group.has-secondary .form-check label::before:active,
form .form-group.-secondary .form-control:focus,
.form .form-group.-secondary .form-control:focus,
form .form-group.has-secondary .form-control:focus,
.form .form-group.has-secondary .form-control:focus,
form .form-group.-secondary .form-check label::before:focus,
.form .form-group.-secondary .form-check label::before:focus,
form .form-group.has-secondary .form-check label::before:focus,
.form .form-group.has-secondary .form-check label::before:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
form .form-group.-success label,
.form .form-group.-success label,
form .form-group.has-success label,
.form .form-group.has-success label,
form .form-group.-success .help-block,
.form .form-group.-success .help-block,
form .form-group.has-success .help-block,
.form .form-group.has-success .help-block {
  color: #3d9970;
}
form .form-group.-success .form-control,
.form .form-group.-success .form-control,
form .form-group.has-success .form-control,
.form .form-group.has-success .form-control,
form .form-group.-success .form-check label::before,
.form .form-group.-success .form-check label::before,
form .form-group.has-success .form-check label::before,
.form .form-group.has-success .form-check label::before {
  border-color: rgba(61,153,112,0.66);
}
form .form-group.-success .form-control:active,
.form .form-group.-success .form-control:active,
form .form-group.has-success .form-control:active,
.form .form-group.has-success .form-control:active,
form .form-group.-success .form-check label::before:active,
.form .form-group.-success .form-check label::before:active,
form .form-group.has-success .form-check label::before:active,
.form .form-group.has-success .form-check label::before:active,
form .form-group.-success .form-control:focus,
.form .form-group.-success .form-control:focus,
form .form-group.has-success .form-control:focus,
.form .form-group.has-success .form-control:focus,
form .form-group.-success .form-check label::before:focus,
.form .form-group.-success .form-check label::before:focus,
form .form-group.has-success .form-check label::before:focus,
.form .form-group.has-success .form-check label::before:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
form .form-group.-danger label,
.form .form-group.-danger label,
form .form-group.has-danger label,
.form .form-group.has-danger label,
form .form-group.-danger .help-block,
.form .form-group.-danger .help-block,
form .form-group.has-danger .help-block,
.form .form-group.has-danger .help-block {
  color: #ff4136;
}
form .form-group.-danger .form-control,
.form .form-group.-danger .form-control,
form .form-group.has-danger .form-control,
.form .form-group.has-danger .form-control,
form .form-group.-danger .form-check label::before,
.form .form-group.-danger .form-check label::before,
form .form-group.has-danger .form-check label::before,
.form .form-group.has-danger .form-check label::before {
  border-color: rgba(255,65,54,0.66);
}
form .form-group.-danger .form-control:active,
.form .form-group.-danger .form-control:active,
form .form-group.has-danger .form-control:active,
.form .form-group.has-danger .form-control:active,
form .form-group.-danger .form-check label::before:active,
.form .form-group.-danger .form-check label::before:active,
form .form-group.has-danger .form-check label::before:active,
.form .form-group.has-danger .form-check label::before:active,
form .form-group.-danger .form-control:focus,
.form .form-group.-danger .form-control:focus,
form .form-group.has-danger .form-control:focus,
.form .form-group.has-danger .form-control:focus,
form .form-group.-danger .form-check label::before:focus,
.form .form-group.-danger .form-check label::before:focus,
form .form-group.has-danger .form-check label::before:focus,
.form .form-group.has-danger .form-check label::before:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
form .form-group.-warning label,
.form .form-group.-warning label,
form .form-group.has-warning label,
.form .form-group.has-warning label,
form .form-group.-warning .help-block,
.form .form-group.-warning .help-block,
form .form-group.has-warning .help-block,
.form .form-group.has-warning .help-block {
  color: #ff851b;
}
form .form-group.-warning .form-control,
.form .form-group.-warning .form-control,
form .form-group.has-warning .form-control,
.form .form-group.has-warning .form-control,
form .form-group.-warning .form-check label::before,
.form .form-group.-warning .form-check label::before,
form .form-group.has-warning .form-check label::before,
.form .form-group.has-warning .form-check label::before {
  border-color: rgba(255,133,27,0.66);
}
form .form-group.-warning .form-control:active,
.form .form-group.-warning .form-control:active,
form .form-group.has-warning .form-control:active,
.form .form-group.has-warning .form-control:active,
form .form-group.-warning .form-check label::before:active,
.form .form-group.-warning .form-check label::before:active,
form .form-group.has-warning .form-check label::before:active,
.form .form-group.has-warning .form-check label::before:active,
form .form-group.-warning .form-control:focus,
.form .form-group.-warning .form-control:focus,
form .form-group.has-warning .form-control:focus,
.form .form-group.has-warning .form-control:focus,
form .form-group.-warning .form-check label::before:focus,
.form .form-group.-warning .form-check label::before:focus,
form .form-group.has-warning .form-check label::before:focus,
.form .form-group.has-warning .form-check label::before:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
form .form-group.-info label,
.form .form-group.-info label,
form .form-group.has-info label,
.form .form-group.has-info label,
form .form-group.-info .help-block,
.form .form-group.-info .help-block,
form .form-group.has-info .help-block,
.form .form-group.has-info .help-block {
  color: #0074d9;
}
form .form-group.-info .form-control,
.form .form-group.-info .form-control,
form .form-group.has-info .form-control,
.form .form-group.has-info .form-control,
form .form-group.-info .form-check label::before,
.form .form-group.-info .form-check label::before,
form .form-group.has-info .form-check label::before,
.form .form-group.has-info .form-check label::before {
  border-color: rgba(0,116,217,0.66);
}
form .form-group.-info .form-control:active,
.form .form-group.-info .form-control:active,
form .form-group.has-info .form-control:active,
.form .form-group.has-info .form-control:active,
form .form-group.-info .form-check label::before:active,
.form .form-group.-info .form-check label::before:active,
form .form-group.has-info .form-check label::before:active,
.form .form-group.has-info .form-check label::before:active,
form .form-group.-info .form-control:focus,
.form .form-group.-info .form-control:focus,
form .form-group.has-info .form-control:focus,
.form .form-group.has-info .form-control:focus,
form .form-group.-info .form-check label::before:focus,
.form .form-group.-info .form-check label::before:focus,
form .form-group.has-info .form-check label::before:focus,
.form .form-group.has-info .form-check label::before:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
img.-responsive {
  max-width: 100%;
  height: auto;
}
img.-bordered.-dark {
  border: 10px solid rgba(0,0,0,0.25);
}
img.-bordered.-light {
  border: 10px solid rgba(255,255,255,0.15);
}
img.-rounded {
  border-radius: 5px;
}
img.-round {
  border-radius: 100%;
}
img.-fluid {
  width: 100%;
  height: auto;
}
.list-group.-dark {
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -o-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -ms-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: linear-gradient(to bottom right, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
}
.list-group.-dark .list-group-item {
  border: 1px solid rgba(0,0,0,0.125);
}
.list-group.-dark .list-group-item:hover,
.list-group.-dark .list-group-item:focus,
.list-group.-dark .list-group-item:active {
  background: rgba(0,0,0,0.125);
}
.list-group.-light {
  background: -webkit-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -moz-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -o-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -ms-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: linear-gradient(to bottom right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
}
.list-group.-light .list-group-item {
  border: 1px solid rgba(255,255,255,0.05);
}
.list-group.-light .list-group-item:hover,
.list-group.-light .list-group-item:focus,
.list-group.-light .list-group-item:active {
  background: rgba(255,255,255,0.05);
}
.list-group .list-group-item {
  background: transparent;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.list-group .list-group-item .list-group-icon {
  margin-right: 1rem;
}
.list-group .list-group-item:hover,
.list-group .list-group-item:focus,
.list-group .list-group-item:active {
  text-decoration: none;
  color: #fff;
}
.mail-sidebar .mail-sidebar-header {
  padding: 1rem;
}
.mail-sidebar .mail-sidebar-header.-fixed-height {
  height: 232px;
}
.mail-sidebar .mail-sidebar-header .btn {
  margin-top: 1.5rem;
}
.mail-sidebar .mail-sidebar-nav li {
  height: 60px;
  line-height: 60px;
  border-top: 1px solid rgba(0,0,0,0.125);
}
.mail-sidebar .mail-sidebar-nav li:last-child {
  border-bottom: 1px solid rgba(0,0,0,0.125);
}
.mail-sidebar .mail-sidebar-nav li.-active a {
  background-image: -webkit-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -moz-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -o-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -ms-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: linear-gradient(to right, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  font-weight: bold;
  color: #fff;
}
.mail-sidebar .mail-sidebar-nav li a {
  display: block;
  position: relative;
  padding: 0 1rem;
  background-image: -webkit-linear-gradient(left, rgba(0,0,0,0.125), rgba(0,0,0,0));
  background-image: -moz-linear-gradient(left, rgba(0,0,0,0.125), rgba(0,0,0,0));
  background-image: -o-linear-gradient(left, rgba(0,0,0,0.125), rgba(0,0,0,0));
  background-image: -ms-linear-gradient(left, rgba(0,0,0,0.125), rgba(0,0,0,0));
  background-image: linear-gradient(to right, rgba(0,0,0,0.125), rgba(0,0,0,0));
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: rgba(255,255,255,0.85);
}
.mail-sidebar .mail-sidebar-nav li a:hover,
.mail-sidebar .mail-sidebar-nav li a:focus,
.mail-sidebar .mail-sidebar-nav li a:active {
  background-color: rgba(0,0,0,0.125);
  text-decoration: none;
  color: #fff;
}
.mail-sidebar .mail-sidebar-nav li a i {
  margin-right: 0.5rem;
}
.mail-sidebar .mail-sidebar-nav li .tag {
  position: absolute;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  right: 1rem;
}
.mail .mail-header {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: -16px;
  background-image: -webkit-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -moz-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -o-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -ms-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: linear-gradient(to right, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
}
.mail .mail-header h1 i,
.mail .mail-header h2 i,
.mail .mail-header h3 i,
.mail .mail-header h4 i,
.mail .mail-header h5 i,
.mail .mail-header h6 i {
  margin-right: 0.75rem;
}
.mail .mail-header .mail-actions {
  margin-top: 2rem;
}
.mail .mail-header .mail-actions .form-group {
  display: inline-block;
}
.mail .mail-header .mail-actions .form-group,
.mail .mail-header .mail-actions .form-check,
.mail .mail-header .mail-actions label {
  margin: 0;
}
.mail .mail-header .mail-actions #check-all-wrapper {
  float: left;
  margin-top: 0.5rem;
}
.mail .mail-header .mail-actions .btn-group {
  margin-bottom: 0rem;
}
.mail .mail-header .mail-actions .btn-group._pull-right {
  margin-right: 0;
}
.mail .mail-header .mail-actions .btn-dropdown {
  float: left;
}
.mail-items .mail-item {
  cursor: pointer;
}
.mail-items .mail-item .mail-item-actions {
  min-width: 80px;
}
.mail-items .mail-item .mail-item-title {
  min-width: 220px;
}
.mail-items .mail-item .mail-item-sender {
  min-width: 160px;
}
.mail-items .mail-item .mail-item-date {
  min-width: 80px;
}
.mail-items .mail-item .mail-item-excerpt,
.mail-items .mail-item .mail-item-title span {
  color: rgba(255,255,255,0.5);
}
.mail-items .mail-item.-unread {
  background-color: rgba(0,0,0,0.25);
}
.mail-items .mail-item.-unread .mail-item-sender,
.mail-items .mail-item.-unread .mail-item-title,
.mail-items .mail-item.-unread .mail-item-date {
  font-weight: bold;
}
.mail-items .mail-item.-unread .mail-item-sender span,
.mail-items .mail-item.-unread .mail-item-title span,
.mail-items .mail-item.-unread .mail-item-date span {
  font-weight: normal;
}
.mail-items .mail-item.-starred .mail-item-star {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.mail-items .mail-item.-starred .mail-item-star:hover,
.mail-items .mail-item.-starred .mail-item-star:focus,
.mail-items .mail-item.-starred .mail-item-star:active {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.mail-items .mail-item .mail-item-star {
  display: inline-block;
  cursor: pointer;
  opacity: 0.33;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=33)";
  filter: alpha(opacity=33);
  font-size: 1.1rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.mail-items .mail-item .mail-item-star:hover,
.mail-items .mail-item .mail-item-star:focus,
.mail-items .mail-item .mail-item-star:active {
  opacity: 0.66;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=66)";
  filter: alpha(opacity=66);
}
.mail-items .mail-item .form {
  float: left;
}
.mail-items .mail-item .form-group,
.mail-items .mail-item .form-check,
.mail-items .mail-item label {
  margin: 0;
}
.mail-items .mail-item .mail-item-date {
  text-align: right;
}
.mail-info {
  padding: 0.5rem 1rem;
}
.mail-info p {
  margin: 0;
  font-size: 0.8rem;
}
.mail-sender {
  display: block;
  position: relative;
  padding: 1rem;
  border-bottom: 1px solid rgba(0,0,0,0.125);
  background: rgba(0,0,0,0.5);
  font-weight: bold;
  color: #fff;
}
.mail-sender a {
  display: inline-block;
}
.mail-sender a:hover,
.mail-sender a:focus,
.mail-sender a:active {
  color: #fff;
}
.mail-sender span {
  font-weight: normal;
}
.mail-sender img {
  border-radius: 2px;
  float: left;
  margin-right: 1rem;
  width: 25px;
  height: 25px;
}
.mail-body {
  padding: 2rem 1rem;
  background: -webkit-linear-gradient(bottom left, rgba(255,255,255,0.05), rgba(255,255,255,0));
  background: -moz-linear-gradient(bottom left, rgba(255,255,255,0.05), rgba(255,255,255,0));
  background: -o-linear-gradient(bottom left, rgba(255,255,255,0.05), rgba(255,255,255,0));
  background: -ms-linear-gradient(bottom left, rgba(255,255,255,0.05), rgba(255,255,255,0));
  background: linear-gradient(to top right, rgba(255,255,255,0.05), rgba(255,255,255,0));
  border-bottom: 1px solid rgba(0,0,0,0.125);
  margin-bottom: 1rem;
}
.mail-body p:last-child {
  margin-bottom: 0;
}
.map {
  width: 100%;
  height: 500px;
}
.map.-lg {
  height: 625px;
}
@media screen and (max-width: 1199px) {
  .map.-lg {
    height: 546.875px;
  }
}
@media screen and (max-width: 991px) {
  .map.-lg {
    height: 468.75px;
  }
}
@media screen and (max-width: 767px) {
  .map.-lg {
    height: 390.625px;
  }
}
@media screen and (max-width: 575px) {
  .map.-lg {
    height: 312.5px;
  }
}
.map.-md {
  height: 500px;
}
@media screen and (max-width: 1199px) {
  .map.-md {
    height: 437.5px;
  }
}
@media screen and (max-width: 991px) {
  .map.-md {
    height: 375px;
  }
}
@media screen and (max-width: 767px) {
  .map.-md {
    height: 312.5px;
  }
}
@media screen and (max-width: 575px) {
  .map.-md {
    height: 250px;
  }
}
.map.-sm {
  height: 375px;
}
@media screen and (max-width: 1199px) {
  .map.-sm {
    height: 328.125px;
  }
}
@media screen and (max-width: 991px) {
  .map.-sm {
    height: 281.25px;
  }
}
@media screen and (max-width: 767px) {
  .map.-sm {
    height: 234.375px;
  }
}
@media screen and (max-width: 575px) {
  .map.-sm {
    height: 187.5px;
  }
}
.map.-xs {
  height: 250px;
}
@media screen and (max-width: 1199px) {
  .map.-xs {
    height: 218.75px;
  }
}
@media screen and (max-width: 991px) {
  .map.-xs {
    height: 187.5px;
  }
}
@media screen and (max-width: 767px) {
  .map.-xs {
    height: 156.25px;
  }
}
@media screen and (max-width: 575px) {
  .map.-xs {
    height: 125px;
  }
}
.md-editor {
  display: block;
  border: 1px solid rgba(0,0,0,0);
}
.md-editor > .md-header,
.md-editor .md-footer {
  display: block;
  padding: 1rem 0.5rem;
  background: rgba(0,0,0,0);
}
.md-editor > .md-header {
  margin: 0;
}
.md-editor > .md-preview {
  background: rgba(0,0,0,0.5);
  border-top: 1px dashed rgba(0,0,0,0);
  border-bottom: 1px dashed rgba(0,0,0,0);
  min-height: 10px;
  overflow: auto;
  padding: 1rem;
}
.md-editor > textarea {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  outline: 0;
  margin: 0;
  display: block;
  padding: 1rem;
  color: #fff;
  width: 100%;
  border: 0;
  border-top: 1px dashed rgba(0,0,0,0);
  border-bottom: 1px dashed rgba(0,0,0,0);
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(0,0,0,0.5);
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.125));
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.125));
  background: -o-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.125));
  background: -ms-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.125));
  background: linear-gradient(to bottom right, rgba(0,0,0,0.5), rgba(0,0,0,0.125));
}
.md-editor > textarea:focus,
.md-editor > textarea:active {
  border: 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -o-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -ms-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: linear-gradient(to bottom right, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
}
.md-editor.active {
  outline: 0;
}
.md-editor .md-controls {
  float: right;
  padding: 3px;
  margin-bottom: -0.33rem;
}
.md-editor .md-controls .md-control {
  right: 5px;
  color: #bebebe;
  padding: 3px 3px 3px 10px;
}
.md-editor .md-controls .md-control:hover {
  color: #333;
}
.md-editor .btn-group {
  margin-bottom: 0.33rem;
}
.md-editor .btn-group .btn {
  padding-left: 1rem;
  padding-right: 1rem;
}
.md-editor.md-fullscreen-mode {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  padding: 60px 30px 15px;
  background: #fff !important;
  border: 0 !important;
}
.md-editor.md-fullscreen-mode .md-input {
  margin-top: 2.5rem;
}
.md-editor.md-fullscreen-mode .md-footer {
  display: none;
}
.md-editor.md-fullscreen-mode .md-input,
.md-editor.md-fullscreen-mode .md-preview {
  height: 100% !important;
  font-size: 20px !important;
  padding: 20px !important;
  color: #999;
  line-height: 1.6em !important;
  resize: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: #fff !important;
  border: 0 !important;
}
.md-editor.md-fullscreen-mode .md-preview {
  margin: 0 auto;
  color: #333;
  overflow: auto;
}
.md-editor.md-fullscreen-mode .md-input:hover,
.md-editor.md-fullscreen-mode .md-input:focus {
  color: #333;
  background: #fff !important;
}
.md-editor.md-fullscreen-mode .md-header {
  background: none;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 20px;
}
.md-editor.md-fullscreen-mode .btn-group {
  float: none;
}
.md-editor.md-fullscreen-mode .btn {
  border: 0;
  background: none;
  color: #b3b3b3;
}
.md-editor.md-fullscreen-mode .btn:hover,
.md-editor.md-fullscreen-mode .btn:focus,
.md-editor.md-fullscreen-mode .btn.active,
.md-editor.md-fullscreen-mode .btn:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #333;
}
.md-editor.md-fullscreen-mode .md-fullscreen-controls {
  position: absolute;
  top: 20px;
  right: 20px;
  text-align: right;
  z-index: 1002;
  display: block;
}
.md-editor.md-fullscreen-mode .md-fullscreen-controls a {
  color: #b3b3b3;
  clear: right;
  margin: 10px;
  width: 30px;
  height: 30px;
  text-align: center;
}
.md-editor.md-fullscreen-mode .md-fullscreen-controls a:hover {
  color: #333;
  text-decoration: none;
}
.md-editor.md-fullscreen-mode .md-editor {
  height: 100% !important;
  position: relative;
}
.md-editor .md-fullscreen-controls {
  display: none;
}
.md-nooverflow {
  overflow: hidden;
  position: fixed;
  width: 100%;
}
.morph-dropdown-wrapper {
  position: absolute;
  padding-top: 10px;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -o-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  display: block;
  height: auto;
  bottom: 0px;
}
.morph-dropdown-wrapper.-right {
  right: 0;
}
.morph-dropdown-wrapper.-left {
  left: 0;
}
.morph-dropdown-wrapper .morph-dropdown-list {
  border-radius: 3px;
  display: block;
  position: relative;
  overflow: hidden;
  height: 0px;
  width: 0px;
  -webkit-backdrop-filter: blur(7px);
  -moz-backdrop-filter: blur(7px);
}
.morph-dropdown-wrapper .morph-dropdown {
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.morph-dropdown-wrapper .morph-dropdown.-active {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  z-index: 999;
}
.morph-dropdown-wrapper .morph-content {
  display: block;
  position: relative;
  height: auto;
  padding: 1rem;
  width: 280px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.morph-dropdown-wrapper .morph-content.-gallery {
  width: 420px;
}
@media screen and (max-width: 575px) {
  .morph-dropdown-wrapper .morph-content.-gallery {
    max-width: 280px;
  }
}
.morph-dropdown-wrapper.-light .morph-dropdown-list {
  color: #000;
  background: rgba(255,255,255,0.85);
}
.morph-dropdown-wrapper.-dark .morph-dropdown-list {
  color: #fff;
  background: rgba(0,0,0,0.7);
}
.morph-gallery {
  display: block;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  max-height: 360px;
  overflow: auto;
  zoom: 1;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.morph-gallery:before,
.morph-gallery:after {
  content: "";
  display: table;
}
.morph-gallery:after {
  clear: both;
}
.morph-gallery.-three li {
  width: 33.33333333333333%;
}
.morph-gallery li {
  position: relative;
  display: block;
  float: left;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 25%;
}
@media screen and (max-width: 575px) {
  .morph-gallery li {
    width: 50%;
  }
}
.morph-gallery li:hover,
.morph-gallery li:active,
.morph-gallery li:focus {
  background: rgba(255,255,255,0.05);
}
.morph-gallery li a {
  font-size: 0.9rem;
  position: relative;
  display: block;
  border-radius: 2px;
  padding: 1rem 0;
}
.morph-gallery li img {
  margin: 0 auto;
  width: 60%;
  display: block;
}
.morph-gallery li i {
  position: relative;
  display: block;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
.morph-links {
  display: block;
  list-style: none;
  padding: 0;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 0;
  background: rgba(255,255,255,0.05);
  zoom: 1;
}
.morph-links:before,
.morph-links:after {
  content: "";
  display: table;
}
.morph-links:after {
  clear: both;
}
.morph-links.-small li {
  font-size: 0.9rem;
}
.morph-links li {
  position: relative;
  display: block;
  border-bottom: 1px solid rgba(255,255,255,0.05);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.morph-links li small {
  display: block;
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
}
.morph-links li:last-child {
  border-bottom: 0;
}
.morph-links li a {
  padding: 0.75rem;
  display: block;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.morph-links li:hover,
.morph-links li:focus,
.morph-links li:active {
  background: rgba(255,255,255,0.05);
}
.morph-links li img {
  float: left;
  width: 2.6rem;
  height: 2.6rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}
.morph-profile h1,
.morph-profile h2,
.morph-profile h3,
.morph-profile h4,
.morph-profile h5,
.morph-profile h6 {
  margin-bottom: 0;
}
.morph-profile img {
  border-radius: 2px;
  float: left;
  max-width: 45px;
  margin-right: 1rem;
}
.morph-profile p {
  font-size: 0.9rem;
  color: rgba(255,255,255,0.5);
}
.modal {
  color: #fff;
}
.modal .modal-dialog {
  margin-top: 6rem;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
.modal .modal-header {
  position: relative;
  min-height: 4rem;
}
.modal .modal-header h1,
.modal .modal-header h2,
.modal .modal-header h3,
.modal .modal-header h4,
.modal .modal-header h5,
.modal .modal-header h6,
.modal .modal-header .h1,
.modal .modal-header .h2,
.modal .modal-header .h3,
.modal .modal-header .h4,
.modal .modal-header .h5,
.modal .modal-header .h6 {
  margin: 0;
}
.modal .modal-header,
.modal .modal-footer {
  border: 0;
}
.modal .modal-content {
  border-radius: 3px;
}
.modal .modal-content.-padded {
  padding-bottom: 3rem;
}
.modal .modal-icon {
  font-size: 3rem;
}
.modal .modal-close {
  font-size: 2rem;
  padding: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 1rem;
  background: transparent;
  border: 0;
  color: inherit;
}
.modal .btn {
  color: #fff;
}
.modal .btn.-light,
.modal .btn.-light:hover {
  color: #000;
}
.modal.-light .modal-dialog .modal-content {
  color: #000;
  background: rgba(255,255,255,0.95);
  background: -webkit-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  background: -moz-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  background: -o-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  background: -ms-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  background: linear-gradient(to right, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
}
.modal.-light .modal-dialog .modal-content .btn {
  color: #fff;
}
.modal.-light .modal-dialog .modal-content .btn.-light,
.modal.-light .modal-dialog .modal-content .btn.-light:hover {
  color: #000;
}
.modal .modal-header.-light {
  color: #000;
  background: rgba(255,255,255,0.95);
  background: -webkit-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  background: -moz-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  background: -o-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  background: -ms-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  background: linear-gradient(to right, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
}
.modal.-dark .modal-dialog .modal-content {
  background: rgba(0,0,0,0.8);
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.5));
  background: -moz-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.5));
  background: -o-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.5));
  background: -ms-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.5));
  background: linear-gradient(to right, rgba(0,0,0,0.8), rgba(0,0,0,0.5));
}
.modal .modal-header.-dark {
  background: rgba(0,0,0,0.8);
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.5));
  background: -moz-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.5));
  background: -o-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.5));
  background: -ms-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.5));
  background: linear-gradient(to right, rgba(0,0,0,0.8), rgba(0,0,0,0.5));
}
.modal.-primary .modal-dialog .modal-content {
  background: #ec6ead;
  background: -webkit-linear-gradient(left, #ec6ead, rgba(236,110,173,0.8));
  background: -moz-linear-gradient(left, #ec6ead, rgba(236,110,173,0.8));
  background: -o-linear-gradient(left, #ec6ead, rgba(236,110,173,0.8));
  background: -ms-linear-gradient(left, #ec6ead, rgba(236,110,173,0.8));
  background: linear-gradient(to right, #ec6ead, rgba(236,110,173,0.8));
}
.modal .modal-header.-primary {
  background: #ec6ead;
  background: -webkit-linear-gradient(left, #ec6ead, rgba(236,110,173,0.8));
  background: -moz-linear-gradient(left, #ec6ead, rgba(236,110,173,0.8));
  background: -o-linear-gradient(left, #ec6ead, rgba(236,110,173,0.8));
  background: -ms-linear-gradient(left, #ec6ead, rgba(236,110,173,0.8));
  background: linear-gradient(to right, #ec6ead, rgba(236,110,173,0.8));
}
.modal.-secondary .modal-dialog .modal-content {
  background: #3494e6;
  background: -webkit-linear-gradient(left, #3494e6, rgba(52,148,230,0.8));
  background: -moz-linear-gradient(left, #3494e6, rgba(52,148,230,0.8));
  background: -o-linear-gradient(left, #3494e6, rgba(52,148,230,0.8));
  background: -ms-linear-gradient(left, #3494e6, rgba(52,148,230,0.8));
  background: linear-gradient(to right, #3494e6, rgba(52,148,230,0.8));
}
.modal .modal-header.-secondary {
  background: #3494e6;
  background: -webkit-linear-gradient(left, #3494e6, rgba(52,148,230,0.8));
  background: -moz-linear-gradient(left, #3494e6, rgba(52,148,230,0.8));
  background: -o-linear-gradient(left, #3494e6, rgba(52,148,230,0.8));
  background: -ms-linear-gradient(left, #3494e6, rgba(52,148,230,0.8));
  background: linear-gradient(to right, #3494e6, rgba(52,148,230,0.8));
}
.modal.-success .modal-dialog .modal-content {
  background: #3d9970;
  background: -webkit-linear-gradient(left, #3d9970, rgba(61,153,112,0.8));
  background: -moz-linear-gradient(left, #3d9970, rgba(61,153,112,0.8));
  background: -o-linear-gradient(left, #3d9970, rgba(61,153,112,0.8));
  background: -ms-linear-gradient(left, #3d9970, rgba(61,153,112,0.8));
  background: linear-gradient(to right, #3d9970, rgba(61,153,112,0.8));
}
.modal .modal-header.-success {
  background: #3d9970;
  background: -webkit-linear-gradient(left, #3d9970, rgba(61,153,112,0.8));
  background: -moz-linear-gradient(left, #3d9970, rgba(61,153,112,0.8));
  background: -o-linear-gradient(left, #3d9970, rgba(61,153,112,0.8));
  background: -ms-linear-gradient(left, #3d9970, rgba(61,153,112,0.8));
  background: linear-gradient(to right, #3d9970, rgba(61,153,112,0.8));
}
.modal.-danger .modal-dialog .modal-content {
  background: #ff4136;
  background: -webkit-linear-gradient(left, #ff4136, rgba(255,65,54,0.8));
  background: -moz-linear-gradient(left, #ff4136, rgba(255,65,54,0.8));
  background: -o-linear-gradient(left, #ff4136, rgba(255,65,54,0.8));
  background: -ms-linear-gradient(left, #ff4136, rgba(255,65,54,0.8));
  background: linear-gradient(to right, #ff4136, rgba(255,65,54,0.8));
}
.modal .modal-header.-danger {
  background: #ff4136;
  background: -webkit-linear-gradient(left, #ff4136, rgba(255,65,54,0.8));
  background: -moz-linear-gradient(left, #ff4136, rgba(255,65,54,0.8));
  background: -o-linear-gradient(left, #ff4136, rgba(255,65,54,0.8));
  background: -ms-linear-gradient(left, #ff4136, rgba(255,65,54,0.8));
  background: linear-gradient(to right, #ff4136, rgba(255,65,54,0.8));
}
.modal.-warning .modal-dialog .modal-content {
  background: #ff851b;
  background: -webkit-linear-gradient(left, #ff851b, rgba(255,133,27,0.8));
  background: -moz-linear-gradient(left, #ff851b, rgba(255,133,27,0.8));
  background: -o-linear-gradient(left, #ff851b, rgba(255,133,27,0.8));
  background: -ms-linear-gradient(left, #ff851b, rgba(255,133,27,0.8));
  background: linear-gradient(to right, #ff851b, rgba(255,133,27,0.8));
}
.modal .modal-header.-warning {
  background: #ff851b;
  background: -webkit-linear-gradient(left, #ff851b, rgba(255,133,27,0.8));
  background: -moz-linear-gradient(left, #ff851b, rgba(255,133,27,0.8));
  background: -o-linear-gradient(left, #ff851b, rgba(255,133,27,0.8));
  background: -ms-linear-gradient(left, #ff851b, rgba(255,133,27,0.8));
  background: linear-gradient(to right, #ff851b, rgba(255,133,27,0.8));
}
.modal.-info .modal-dialog .modal-content {
  background: #0074d9;
  background: -webkit-linear-gradient(left, #0074d9, rgba(0,116,217,0.8));
  background: -moz-linear-gradient(left, #0074d9, rgba(0,116,217,0.8));
  background: -o-linear-gradient(left, #0074d9, rgba(0,116,217,0.8));
  background: -ms-linear-gradient(left, #0074d9, rgba(0,116,217,0.8));
  background: linear-gradient(to right, #0074d9, rgba(0,116,217,0.8));
}
.modal .modal-header.-info {
  background: #0074d9;
  background: -webkit-linear-gradient(left, #0074d9, rgba(0,116,217,0.8));
  background: -moz-linear-gradient(left, #0074d9, rgba(0,116,217,0.8));
  background: -o-linear-gradient(left, #0074d9, rgba(0,116,217,0.8));
  background: -ms-linear-gradient(left, #0074d9, rgba(0,116,217,0.8));
  background: linear-gradient(to right, #0074d9, rgba(0,116,217,0.8));
}
.page-heading {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}
.page-heading .breadcrumb {
  background: transparent;
  border-radius: 0;
  padding: 0;
  margin: 0;
  font-size: 0.9rem;
}
.page-heading.-light {
  color: #000;
}
.page-heading.-light .breadcrumb-item {
  color: #000;
}
.page-heading.-light .breadcrumb-item::before,
.page-heading.-light .breadcrumb-item.-active {
  color: rgba(0,0,0,0.5);
}
.page-heading.-dark {
  color: #fff;
}
.page-heading.-dark .breadcrumb-item {
  color: #fff;
}
.page-heading.-dark .breadcrumb-item::before,
.page-heading.-dark .breadcrumb-item.-active {
  color: rgba(255,255,255,0.5);
}
.page-heading-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.pagination {
  display: block;
  position: relative;
  list-style: none;
  padding: 0;
}
.pagination .pagination-item {
  display: inline-block;
  margin-right: 0.5rem;
}
.pagination .pagination-item.-active a {
  font-weight: bold;
}
.pagination .pagination-item:last-child {
  margin-right: 0rem;
}
.pagination .pagination-item a {
  display: block;
  position: relative;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 1rem 1.5rem;
  background-position: 0% -100%;
  -webkit-background-size: 100% 200%;
  -moz-background-size: 100% 200%;
  background-size: 100% 200%;
}
.pagination .pagination-item a:hover,
.pagination .pagination-item a:active,
.pagination .pagination-item a:focus {
  text-decoration: none;
  background-position: 0% 100%;
}
.pagination.-dark .pagination-item.-active a {
  background-color: rgba(0,0,0,0.5);
}
.pagination.-dark .pagination-item.-disabled a {
  background-color: rgba(0,0,0,0.125);
}
.pagination.-dark .pagination-item a {
  background-image: -webkit-linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background-image: -moz-linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background-image: -o-linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background-image: -ms-linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
}
.pagination.-light .pagination-item.-active a {
  background-color: rgba(255,255,255,0.15);
}
.pagination.-light .pagination-item.-disabled a {
  background-color: rgba(255,255,255,0.05);
}
.pagination.-light .pagination-item a {
  background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background-image: -moz-linear-gradient(top, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background-image: -o-linear-gradient(top, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background-image: -ms-linear-gradient(top, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background-image: linear-gradient(to bottom, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
}
.panel,
.card {
  display: block;
  position: relative;
  margin-bottom: 1rem;
  border-radius: 2px;
}
.panel.-bordered,
.card.-bordered {
  border-width: 1px;
  border-style: solid;
}
.panel.-bordered-top,
.card.-bordered-top {
  border-top-width: 5px;
  border-top-style: solid;
}
.panel.-bordered-left,
.card.-bordered-left {
  border-left-width: 5px;
  border-left-style: solid;
}
.panel.-bordered-right,
.card.-bordered-right {
  border-right-width: 5px;
  border-right-style: solid;
}
.panel.-bordered-bottom,
.card.-bordered-bottom {
  border-bottom-width: 5px;
  border-bottom-style: solid;
}
.panel.-bordered-3d,
.card.-bordered-3d {
  border-width: 0;
  border-bottom-width: 5px;
  border-style: solid;
  border-radius: 0px 0px 5px 5px;
}
.panel .panel-status,
.card .panel-status {
  float: right;
  text-align: right;
}
.panel .panel-status h4,
.card .panel-status h4 {
  font-weight: bold;
}
.panel .panel-sparkline,
.card .panel-sparkline {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  display: block;
}
.panel .panel-heading,
.card .panel-heading,
.panel .card-header,
.card .card-header,
.panel .panel-body,
.card .panel-body,
.panel .card-block,
.card .card-block,
.panel .panel-footer,
.card .panel-footer,
.panel .card-footer,
.card .card-footer {
  position: relative;
  background: transparent;
  display: block;
  zoom: 1;
}
.panel .panel-heading:before,
.card .panel-heading:before,
.panel .card-header:before,
.card .card-header:before,
.panel .panel-body:before,
.card .panel-body:before,
.panel .card-block:before,
.card .card-block:before,
.panel .panel-footer:before,
.card .panel-footer:before,
.panel .card-footer:before,
.card .card-footer:before,
.panel .panel-heading:after,
.card .panel-heading:after,
.panel .card-header:after,
.card .card-header:after,
.panel .panel-body:after,
.card .panel-body:after,
.panel .card-block:after,
.card .card-block:after,
.panel .panel-footer:after,
.card .panel-footer:after,
.panel .card-footer:after,
.card .card-footer:after {
  content: "";
  display: table;
}
.panel .panel-heading:after,
.card .panel-heading:after,
.panel .card-header:after,
.card .card-header:after,
.panel .panel-body:after,
.card .panel-body:after,
.panel .card-block:after,
.card .card-block:after,
.panel .panel-footer:after,
.card .panel-footer:after,
.panel .card-footer:after,
.card .card-footer:after {
  clear: both;
}
.panel .panel-heading,
.card .panel-heading,
.panel .card-header,
.card .card-header {
  padding: 1rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-radius: 0;
}
.panel .panel-heading.-with-icon > i,
.card .panel-heading.-with-icon > i,
.panel .card-header.-with-icon > i,
.card .card-header.-with-icon > i,
.panel .panel-heading.-with-icon-sm > i,
.card .panel-heading.-with-icon-sm > i,
.panel .card-header.-with-icon-sm > i,
.card .card-header.-with-icon-sm > i,
.panel .panel-heading.-with-icon-lg > i,
.card .panel-heading.-with-icon-lg > i,
.panel .card-header.-with-icon-lg > i,
.card .card-header.-with-icon-lg > i {
  float: left;
  margin-top: -0.25rem;
  margin-right: 1rem;
}
.panel .panel-heading.-with-icon-sm > i,
.card .panel-heading.-with-icon-sm > i,
.panel .card-header.-with-icon-sm > i,
.card .card-header.-with-icon-sm > i {
  margin-top: 0;
  font-size: 1.5rem;
}
.panel .panel-heading.-with-icon > i,
.card .panel-heading.-with-icon > i,
.panel .card-header.-with-icon > i,
.card .card-header.-with-icon > i {
  font-size: 2.25rem;
}
.panel .panel-heading.-with-icon-lg > i,
.card .panel-heading.-with-icon-lg > i,
.panel .card-header.-with-icon-lg > i,
.card .card-header.-with-icon-lg > i {
  font-size: 3.5rem;
}
.panel .panel-heading h1,
.card .panel-heading h1,
.panel .card-header h1,
.card .card-header h1,
.panel .panel-heading h2,
.card .panel-heading h2,
.panel .card-header h2,
.card .card-header h2,
.panel .panel-heading h3,
.card .panel-heading h3,
.panel .card-header h3,
.card .card-header h3,
.panel .panel-heading h4,
.card .panel-heading h4,
.panel .card-header h4,
.card .card-header h4,
.panel .panel-heading h5,
.card .panel-heading h5,
.panel .card-header h5,
.card .card-header h5,
.panel .panel-heading h6,
.card .panel-heading h6,
.panel .card-header h6,
.card .card-header h6,
.panel .panel-heading .h1,
.card .panel-heading .h1,
.panel .card-header .h1,
.card .card-header .h1,
.panel .panel-heading .h2,
.card .panel-heading .h2,
.panel .card-header .h2,
.card .card-header .h2,
.panel .panel-heading .h3,
.card .panel-heading .h3,
.panel .card-header .h3,
.card .card-header .h3,
.panel .panel-heading .h4,
.card .panel-heading .h4,
.panel .card-header .h4,
.card .card-header .h4,
.panel .panel-heading .h5,
.card .panel-heading .h5,
.panel .card-header .h5,
.card .card-header .h5,
.panel .panel-heading .h6,
.card .panel-heading .h6,
.panel .card-header .h6,
.card .card-header .h6 {
  margin-bottom: 0;
}
.panel .panel-heading h1 > a,
.card .panel-heading h1 > a,
.panel .card-header h1 > a,
.card .card-header h1 > a,
.panel .panel-heading h2 > a,
.card .panel-heading h2 > a,
.panel .card-header h2 > a,
.card .card-header h2 > a,
.panel .panel-heading h3 > a,
.card .panel-heading h3 > a,
.panel .card-header h3 > a,
.card .card-header h3 > a,
.panel .panel-heading h4 > a,
.card .panel-heading h4 > a,
.panel .card-header h4 > a,
.card .card-header h4 > a,
.panel .panel-heading h5 > a,
.card .panel-heading h5 > a,
.panel .card-header h5 > a,
.card .card-header h5 > a,
.panel .panel-heading h6 > a,
.card .panel-heading h6 > a,
.panel .card-header h6 > a,
.card .card-header h6 > a,
.panel .panel-heading .h1 > a,
.card .panel-heading .h1 > a,
.panel .card-header .h1 > a,
.card .card-header .h1 > a,
.panel .panel-heading .h2 > a,
.card .panel-heading .h2 > a,
.panel .card-header .h2 > a,
.card .card-header .h2 > a,
.panel .panel-heading .h3 > a,
.card .panel-heading .h3 > a,
.panel .card-header .h3 > a,
.card .card-header .h3 > a,
.panel .panel-heading .h4 > a,
.card .panel-heading .h4 > a,
.panel .card-header .h4 > a,
.card .card-header .h4 > a,
.panel .panel-heading .h5 > a,
.card .panel-heading .h5 > a,
.panel .card-header .h5 > a,
.card .card-header .h5 > a,
.panel .panel-heading .h6 > a,
.card .panel-heading .h6 > a,
.panel .card-header .h6 > a,
.card .card-header .h6 > a {
  display: block;
  margin: -1rem;
  padding: 1rem;
}
.panel .panel-heading a:hover,
.card .panel-heading a:hover,
.panel .card-header a:hover,
.card .card-header a:hover,
.panel .panel-heading a:focus,
.card .panel-heading a:focus,
.panel .card-header a:focus,
.card .card-header a:focus {
  text-decoration: none;
}
.panel .panel-heading .panel-actions,
.card .panel-heading .panel-actions,
.panel .card-header .panel-actions,
.card .card-header .panel-actions,
.panel .panel-heading .card-actions,
.card .panel-heading .card-actions,
.panel .card-header .card-actions,
.card .card-header .card-actions {
  top: 1rem;
}
.panel .panel-footer,
.card .panel-footer,
.panel .card-footer,
.card .card-footer {
  padding: 1rem;
  border-top-width: 1px;
  border-top-style: solid;
}
.panel .panel-footer .panel-actions,
.card .panel-footer .panel-actions,
.panel .card-footer .panel-actions,
.card .card-footer .panel-actions {
  bottom: 1rem;
}
.panel .panel-body,
.card .panel-body,
.panel .card-block,
.card .card-block {
  padding: 1rem;
}
.panel .panel-body.-bordered,
.card .panel-body.-bordered,
.panel .card-block.-bordered,
.card .card-block.-bordered {
  border-top-width: 1px;
  border-top-style: solid;
}
.panel .panel-body p:last-child,
.card .panel-body p:last-child,
.panel .card-block p:last-child,
.card .card-block p:last-child {
  margin-bottom: 0;
}
.panel .panel-body p > a,
.card .panel-body p > a,
.panel .card-block p > a,
.card .card-block p > a {
  font-weight: bold;
}
.panel .panel-body p > a.btn,
.card .panel-body p > a.btn,
.panel .card-block p > a.btn,
.card .card-block p > a.btn {
  font-weight: normal;
}
.panel .panel-actions,
.card .panel-actions,
.panel .card-actions,
.card .card-actions {
  position: absolute;
  right: 1rem;
  list-style: none;
  margin: 0;
}
.panel .panel-actions.-centered,
.card .panel-actions.-centered,
.panel .card-actions.-centered,
.card .card-actions.-centered {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.panel .panel-actions li,
.card .panel-actions li,
.panel .card-actions li,
.card .card-actions li {
  float: left;
  display: inline-block;
  margin-left: 1rem;
}
.panel .panel-actions li a,
.card .panel-actions li a,
.panel .card-actions li a,
.card .card-actions li a {
  cursor: pointer;
}
.panel .panel-actions li a:hover,
.card .panel-actions li a:hover,
.panel .card-actions li a:hover,
.card .card-actions li a:hover,
.panel .panel-actions li a:active,
.card .panel-actions li a:active,
.panel .card-actions li a:active,
.card .card-actions li a:active,
.panel .panel-actions li a:focus,
.card .panel-actions li a:focus,
.panel .card-actions li a:focus,
.card .card-actions li a:focus {
  text-decoration: none;
}
.panel .panel-actions li a.dropdown-toggle::after,
.card .panel-actions li a.dropdown-toggle::after,
.panel .card-actions li a.dropdown-toggle::after,
.card .card-actions li a.dropdown-toggle::after {
  display: none;
}
.panel.-collapsible.-collapsed .panel-body,
.card.-collapsible.-collapsed .panel-body {
  display: none;
}
.panel.-focused,
.card.-focused {
  max-width: 600px;
  margin: 3rem auto;
}
.panel.-light,
.card.-light {
  background: rgba(255,255,255,0.15);
  background: -webkit-linear-gradient(bottom right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -moz-linear-gradient(bottom right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -o-linear-gradient(bottom right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -ms-linear-gradient(bottom right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: linear-gradient(to top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  border-color: rgba(255,255,255,0.05);
  color: #fff;
}
.panel.-light .panel-actions a,
.card.-light .panel-actions a,
.panel.-light .card-actions a,
.card.-light .card-actions a {
  color: rgba(255,255,255,0.85);
}
.panel.-light .panel-body,
.card.-light .panel-body,
.panel.-light .card-block,
.card.-light .card-block {
  color: rgba(255,255,255,0.85);
}
.panel.-light .panel-body.-bordered,
.card.-light .panel-body.-bordered,
.panel.-light .card-block.-bordered,
.card.-light .card-block.-bordered {
  border-color: rgba(255,255,255,0.05);
}
.panel.-light .panel-heading,
.card.-light .panel-heading,
.panel.-light .card-header,
.card.-light .card-header,
.panel.-light .panel-footer,
.card.-light .panel-footer,
.panel.-light .card-footer,
.card.-light .card-footer {
  border-color: rgba(255,255,255,0.05);
}
.panel.-light .panel-heading span,
.card.-light .panel-heading span,
.panel.-light .card-header span,
.card.-light .card-header span {
  color: rgba(255,255,255,0.5);
}
.panel.-dark,
.card.-dark {
  background: rgba(0,0,0,0.25);
  background: -webkit-linear-gradient(bottom right, rgba(0,0,0,0.125), rgba(0,0,0,0.25));
  background: -moz-linear-gradient(bottom right, rgba(0,0,0,0.125), rgba(0,0,0,0.25));
  background: -o-linear-gradient(bottom right, rgba(0,0,0,0.125), rgba(0,0,0,0.25));
  background: -ms-linear-gradient(bottom right, rgba(0,0,0,0.125), rgba(0,0,0,0.25));
  background: linear-gradient(to top left, rgba(0,0,0,0.125), rgba(0,0,0,0.25));
  border-color: rgba(0,0,0,0.125);
  color: #fff;
}
.panel.-dark .panel-actions a,
.card.-dark .panel-actions a,
.panel.-dark .card-actions a,
.card.-dark .card-actions a {
  color: rgba(255,255,255,0.85);
}
.panel.-dark .panel-body,
.card.-dark .panel-body,
.panel.-dark .card-block,
.card.-dark .card-block {
  color: rgba(255,255,255,0.85);
}
.panel.-dark .panel-body.-bordered,
.card.-dark .panel-body.-bordered,
.panel.-dark .card-block.-bordered,
.card.-dark .card-block.-bordered {
  border-color: rgba(0,0,0,0.125);
}
.panel.-dark .panel-heading,
.card.-dark .panel-heading,
.panel.-dark .card-header,
.card.-dark .card-header,
.panel.-dark .panel-footer,
.card.-dark .panel-footer,
.panel.-dark .card-footer,
.card.-dark .card-footer {
  border-color: rgba(0,0,0,0.125);
}
.panel.-dark .panel-heading span,
.card.-dark .panel-heading span,
.panel.-dark .card-header span,
.card.-dark .card-header span {
  color: rgba(255,255,255,0.5);
}
.panel .panel-heading.-light,
.card .panel-heading.-light,
.panel .card-header.-light,
.card .card-header.-light,
.panel .panel-footer.-light,
.card .panel-footer.-light,
.panel .card-footer.-light,
.card .card-footer.-light,
.panel .panel-body.-light,
.card .panel-body.-light,
.panel .card-body.-light,
.card .card-body.-light {
  background: rgba(255,255,255,0.15);
}
.panel .panel-heading.-dark,
.card .panel-heading.-dark,
.panel .card-header.-dark,
.card .card-header.-dark,
.panel .panel-footer.-dark,
.card .panel-footer.-dark,
.panel .card-footer.-dark,
.card .card-footer.-dark,
.panel .panel-body.-dark,
.card .panel-body.-dark,
.panel .card-body.-dark,
.card .card-body.-dark {
  background: rgba(0,0,0,0.25);
}
.panel.-primary,
.card.-primary {
  color: #fff;
  background: -webkit-linear-gradient(bottom right, rgba(236,110,173,0.4), rgba(236,110,173,0.8));
  background: -moz-linear-gradient(bottom right, rgba(236,110,173,0.4), rgba(236,110,173,0.8));
  background: -o-linear-gradient(bottom right, rgba(236,110,173,0.4), rgba(236,110,173,0.8));
  background: -ms-linear-gradient(bottom right, rgba(236,110,173,0.4), rgba(236,110,173,0.8));
  background: linear-gradient(to top left, rgba(236,110,173,0.4), rgba(236,110,173,0.8));
}
.panel.-primary .panel-heading,
.card.-primary .panel-heading,
.panel.-primary .card-header,
.card.-primary .card-header,
.panel.-primary .panel-footer,
.card.-primary .panel-footer,
.panel.-primary .card-footer,
.card.-primary .card-footer {
  border-color: rgba(0,0,0,0.125);
}
.panel.-bordered-primary,
.card.-bordered-primary {
  border-color: #ec6ead;
}
.panel .panel-heading.-primary,
.card .panel-heading.-primary,
.panel .card-header.-primary,
.card .card-header.-primary,
.panel .panel-footer.-primary,
.card .panel-footer.-primary,
.panel .card-footer.-primary,
.card .card-footer.-primary,
.panel .panel-body.-primary,
.card .panel-body.-primary,
.panel .card-body.-primary,
.card .card-body.-primary {
  background: -webkit-linear-gradient(bottom right, rgba(236,110,173,0.4), rgba(236,110,173,0.8));
  background: -moz-linear-gradient(bottom right, rgba(236,110,173,0.4), rgba(236,110,173,0.8));
  background: -o-linear-gradient(bottom right, rgba(236,110,173,0.4), rgba(236,110,173,0.8));
  background: -ms-linear-gradient(bottom right, rgba(236,110,173,0.4), rgba(236,110,173,0.8));
  background: linear-gradient(to top left, rgba(236,110,173,0.4), rgba(236,110,173,0.8));
}
.panel.-secondary,
.card.-secondary {
  color: #fff;
  background: -webkit-linear-gradient(bottom right, rgba(52,148,230,0.4), rgba(52,148,230,0.8));
  background: -moz-linear-gradient(bottom right, rgba(52,148,230,0.4), rgba(52,148,230,0.8));
  background: -o-linear-gradient(bottom right, rgba(52,148,230,0.4), rgba(52,148,230,0.8));
  background: -ms-linear-gradient(bottom right, rgba(52,148,230,0.4), rgba(52,148,230,0.8));
  background: linear-gradient(to top left, rgba(52,148,230,0.4), rgba(52,148,230,0.8));
}
.panel.-secondary .panel-heading,
.card.-secondary .panel-heading,
.panel.-secondary .card-header,
.card.-secondary .card-header,
.panel.-secondary .panel-footer,
.card.-secondary .panel-footer,
.panel.-secondary .card-footer,
.card.-secondary .card-footer {
  border-color: rgba(0,0,0,0.125);
}
.panel.-bordered-secondary,
.card.-bordered-secondary {
  border-color: #3494e6;
}
.panel .panel-heading.-secondary,
.card .panel-heading.-secondary,
.panel .card-header.-secondary,
.card .card-header.-secondary,
.panel .panel-footer.-secondary,
.card .panel-footer.-secondary,
.panel .card-footer.-secondary,
.card .card-footer.-secondary,
.panel .panel-body.-secondary,
.card .panel-body.-secondary,
.panel .card-body.-secondary,
.card .card-body.-secondary {
  background: -webkit-linear-gradient(bottom right, rgba(52,148,230,0.4), rgba(52,148,230,0.8));
  background: -moz-linear-gradient(bottom right, rgba(52,148,230,0.4), rgba(52,148,230,0.8));
  background: -o-linear-gradient(bottom right, rgba(52,148,230,0.4), rgba(52,148,230,0.8));
  background: -ms-linear-gradient(bottom right, rgba(52,148,230,0.4), rgba(52,148,230,0.8));
  background: linear-gradient(to top left, rgba(52,148,230,0.4), rgba(52,148,230,0.8));
}
.panel.-success,
.card.-success {
  color: #fff;
  background: -webkit-linear-gradient(bottom right, rgba(61,153,112,0.4), rgba(61,153,112,0.8));
  background: -moz-linear-gradient(bottom right, rgba(61,153,112,0.4), rgba(61,153,112,0.8));
  background: -o-linear-gradient(bottom right, rgba(61,153,112,0.4), rgba(61,153,112,0.8));
  background: -ms-linear-gradient(bottom right, rgba(61,153,112,0.4), rgba(61,153,112,0.8));
  background: linear-gradient(to top left, rgba(61,153,112,0.4), rgba(61,153,112,0.8));
}
.panel.-success .panel-heading,
.card.-success .panel-heading,
.panel.-success .card-header,
.card.-success .card-header,
.panel.-success .panel-footer,
.card.-success .panel-footer,
.panel.-success .card-footer,
.card.-success .card-footer {
  border-color: rgba(0,0,0,0.125);
}
.panel.-bordered-success,
.card.-bordered-success {
  border-color: #3d9970;
}
.panel .panel-heading.-success,
.card .panel-heading.-success,
.panel .card-header.-success,
.card .card-header.-success,
.panel .panel-footer.-success,
.card .panel-footer.-success,
.panel .card-footer.-success,
.card .card-footer.-success,
.panel .panel-body.-success,
.card .panel-body.-success,
.panel .card-body.-success,
.card .card-body.-success {
  background: -webkit-linear-gradient(bottom right, rgba(61,153,112,0.4), rgba(61,153,112,0.8));
  background: -moz-linear-gradient(bottom right, rgba(61,153,112,0.4), rgba(61,153,112,0.8));
  background: -o-linear-gradient(bottom right, rgba(61,153,112,0.4), rgba(61,153,112,0.8));
  background: -ms-linear-gradient(bottom right, rgba(61,153,112,0.4), rgba(61,153,112,0.8));
  background: linear-gradient(to top left, rgba(61,153,112,0.4), rgba(61,153,112,0.8));
}
.panel.-danger,
.card.-danger {
  color: #fff;
  background: -webkit-linear-gradient(bottom right, rgba(255,65,54,0.4), rgba(255,65,54,0.8));
  background: -moz-linear-gradient(bottom right, rgba(255,65,54,0.4), rgba(255,65,54,0.8));
  background: -o-linear-gradient(bottom right, rgba(255,65,54,0.4), rgba(255,65,54,0.8));
  background: -ms-linear-gradient(bottom right, rgba(255,65,54,0.4), rgba(255,65,54,0.8));
  background: linear-gradient(to top left, rgba(255,65,54,0.4), rgba(255,65,54,0.8));
}
.panel.-danger .panel-heading,
.card.-danger .panel-heading,
.panel.-danger .card-header,
.card.-danger .card-header,
.panel.-danger .panel-footer,
.card.-danger .panel-footer,
.panel.-danger .card-footer,
.card.-danger .card-footer {
  border-color: rgba(0,0,0,0.125);
}
.panel.-bordered-danger,
.card.-bordered-danger {
  border-color: #ff4136;
}
.panel .panel-heading.-danger,
.card .panel-heading.-danger,
.panel .card-header.-danger,
.card .card-header.-danger,
.panel .panel-footer.-danger,
.card .panel-footer.-danger,
.panel .card-footer.-danger,
.card .card-footer.-danger,
.panel .panel-body.-danger,
.card .panel-body.-danger,
.panel .card-body.-danger,
.card .card-body.-danger {
  background: -webkit-linear-gradient(bottom right, rgba(255,65,54,0.4), rgba(255,65,54,0.8));
  background: -moz-linear-gradient(bottom right, rgba(255,65,54,0.4), rgba(255,65,54,0.8));
  background: -o-linear-gradient(bottom right, rgba(255,65,54,0.4), rgba(255,65,54,0.8));
  background: -ms-linear-gradient(bottom right, rgba(255,65,54,0.4), rgba(255,65,54,0.8));
  background: linear-gradient(to top left, rgba(255,65,54,0.4), rgba(255,65,54,0.8));
}
.panel.-warning,
.card.-warning {
  color: #fff;
  background: -webkit-linear-gradient(bottom right, rgba(255,133,27,0.4), rgba(255,133,27,0.8));
  background: -moz-linear-gradient(bottom right, rgba(255,133,27,0.4), rgba(255,133,27,0.8));
  background: -o-linear-gradient(bottom right, rgba(255,133,27,0.4), rgba(255,133,27,0.8));
  background: -ms-linear-gradient(bottom right, rgba(255,133,27,0.4), rgba(255,133,27,0.8));
  background: linear-gradient(to top left, rgba(255,133,27,0.4), rgba(255,133,27,0.8));
}
.panel.-warning .panel-heading,
.card.-warning .panel-heading,
.panel.-warning .card-header,
.card.-warning .card-header,
.panel.-warning .panel-footer,
.card.-warning .panel-footer,
.panel.-warning .card-footer,
.card.-warning .card-footer {
  border-color: rgba(0,0,0,0.125);
}
.panel.-bordered-warning,
.card.-bordered-warning {
  border-color: #ff851b;
}
.panel .panel-heading.-warning,
.card .panel-heading.-warning,
.panel .card-header.-warning,
.card .card-header.-warning,
.panel .panel-footer.-warning,
.card .panel-footer.-warning,
.panel .card-footer.-warning,
.card .card-footer.-warning,
.panel .panel-body.-warning,
.card .panel-body.-warning,
.panel .card-body.-warning,
.card .card-body.-warning {
  background: -webkit-linear-gradient(bottom right, rgba(255,133,27,0.4), rgba(255,133,27,0.8));
  background: -moz-linear-gradient(bottom right, rgba(255,133,27,0.4), rgba(255,133,27,0.8));
  background: -o-linear-gradient(bottom right, rgba(255,133,27,0.4), rgba(255,133,27,0.8));
  background: -ms-linear-gradient(bottom right, rgba(255,133,27,0.4), rgba(255,133,27,0.8));
  background: linear-gradient(to top left, rgba(255,133,27,0.4), rgba(255,133,27,0.8));
}
.panel.-info,
.card.-info {
  color: #fff;
  background: -webkit-linear-gradient(bottom right, rgba(0,116,217,0.4), rgba(0,116,217,0.8));
  background: -moz-linear-gradient(bottom right, rgba(0,116,217,0.4), rgba(0,116,217,0.8));
  background: -o-linear-gradient(bottom right, rgba(0,116,217,0.4), rgba(0,116,217,0.8));
  background: -ms-linear-gradient(bottom right, rgba(0,116,217,0.4), rgba(0,116,217,0.8));
  background: linear-gradient(to top left, rgba(0,116,217,0.4), rgba(0,116,217,0.8));
}
.panel.-info .panel-heading,
.card.-info .panel-heading,
.panel.-info .card-header,
.card.-info .card-header,
.panel.-info .panel-footer,
.card.-info .panel-footer,
.panel.-info .card-footer,
.card.-info .card-footer {
  border-color: rgba(0,0,0,0.125);
}
.panel.-bordered-info,
.card.-bordered-info {
  border-color: #0074d9;
}
.panel .panel-heading.-info,
.card .panel-heading.-info,
.panel .card-header.-info,
.card .card-header.-info,
.panel .panel-footer.-info,
.card .panel-footer.-info,
.panel .card-footer.-info,
.card .card-footer.-info,
.panel .panel-body.-info,
.card .panel-body.-info,
.panel .card-body.-info,
.card .card-body.-info {
  background: -webkit-linear-gradient(bottom right, rgba(0,116,217,0.4), rgba(0,116,217,0.8));
  background: -moz-linear-gradient(bottom right, rgba(0,116,217,0.4), rgba(0,116,217,0.8));
  background: -o-linear-gradient(bottom right, rgba(0,116,217,0.4), rgba(0,116,217,0.8));
  background: -ms-linear-gradient(bottom right, rgba(0,116,217,0.4), rgba(0,116,217,0.8));
  background: linear-gradient(to top left, rgba(0,116,217,0.4), rgba(0,116,217,0.8));
}
.popover {
  border-radius: 2px;
  background: rgba(0,0,0,0.8);
  color: #fff;
  font-size: 1rem;
}
.popover.popover-top::before,
.popover.bs-tether-element-attached-bottom::before,
.popover.popover-top::after,
.popover.bs-tether-element-attached-bottom::after,
.popover.popover-top .popover-title::before,
.popover.bs-tether-element-attached-bottom .popover-title::before {
  border-top-color: rgba(0,0,0,0.5);
}
.popover.popover-right::before,
.popover.bs-tether-element-attached-left::before,
.popover.popover-right::after,
.popover.bs-tether-element-attached-left::after,
.popover.popover-right .popover-title::before,
.popover.bs-tether-element-attached-left .popover-title::before {
  border-right-color: rgba(0,0,0,0.5);
}
.popover.popover-bottom::before,
.popover.bs-tether-element-attached-top::before,
.popover.popover-bottom::after,
.popover.bs-tether-element-attached-top::after,
.popover.popover-bottom .popover-title::before,
.popover.bs-tether-element-attached-top .popover-title::before {
  border-bottom-color: rgba(0,0,0,0.5);
}
.popover.popover-left::before,
.popover.bs-tether-element-attached-right::before,
.popover.popover-left::after,
.popover.bs-tether-element-attached-right::after,
.popover.popover-left .popover-title::before,
.popover.bs-tether-element-attached-right .popover-title::before {
  border-left-color: rgba(0,0,0,0.5);
}
.popover .popover-title {
  background: rgba(0,0,0,0.5);
  border-bottom-color: rgba(255,255,255,0.15);
  font-size: 1.1rem;
}
.progress {
  height: 7px;
  width: 100%;
  display: block;
  position: relative;
}
.progress.-dark {
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -moz-linear-gradient(left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -o-linear-gradient(left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -ms-linear-gradient(left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
}
.progress.-light {
  background: -webkit-linear-gradient(left, rgba(255,255,255,0.5), rgba(255,255,255,0.15));
  background: -moz-linear-gradient(left, rgba(255,255,255,0.5), rgba(255,255,255,0.15));
  background: -o-linear-gradient(left, rgba(255,255,255,0.5), rgba(255,255,255,0.15));
  background: -ms-linear-gradient(left, rgba(255,255,255,0.5), rgba(255,255,255,0.15));
  background: linear-gradient(to right, rgba(255,255,255,0.5), rgba(255,255,255,0.15));
}
.progress .bar {
  position: absolute;
  display: block;
  height: 100%;
  width: auto;
  top: 0;
  left: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.progress .bar.-dark {
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.7));
  background: -moz-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.7));
  background: -o-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.7));
  background: -ms-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.7));
  background: linear-gradient(to right, rgba(0,0,0,0.8), rgba(0,0,0,0.7));
  border-bottom-color: rgba(26,26,26,0.8);
}
.progress .bar.-light {
  background: -webkit-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  background: -moz-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  background: -o-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  background: -ms-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  background: linear-gradient(to right, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  border-bottom-color: rgba(255,255,255,0.95);
}
.progress .bar.-primary {
  background: #ec6ead;
  background: -webkit-linear-gradient(left, #ec6ead, rgba(236,110,173,0.8));
  background: -moz-linear-gradient(left, #ec6ead, rgba(236,110,173,0.8));
  background: -o-linear-gradient(left, #ec6ead, rgba(236,110,173,0.8));
  background: -ms-linear-gradient(left, #ec6ead, rgba(236,110,173,0.8));
  background: linear-gradient(to right, #ec6ead, rgba(236,110,173,0.8));
  border-bottom-color: #ed75b1;
}
.progress .bar.-secondary {
  background: #3494e6;
  background: -webkit-linear-gradient(left, #3494e6, rgba(52,148,230,0.8));
  background: -moz-linear-gradient(left, #3494e6, rgba(52,148,230,0.8));
  background: -o-linear-gradient(left, #3494e6, rgba(52,148,230,0.8));
  background: -ms-linear-gradient(left, #3494e6, rgba(52,148,230,0.8));
  background: linear-gradient(to right, #3494e6, rgba(52,148,230,0.8));
  border-bottom-color: #3e99e7;
}
.progress .bar.-success {
  background: #3d9970;
  background: -webkit-linear-gradient(left, #3d9970, rgba(61,153,112,0.8));
  background: -moz-linear-gradient(left, #3d9970, rgba(61,153,112,0.8));
  background: -o-linear-gradient(left, #3d9970, rgba(61,153,112,0.8));
  background: -ms-linear-gradient(left, #3d9970, rgba(61,153,112,0.8));
  background: linear-gradient(to right, #3d9970, rgba(61,153,112,0.8));
  border-bottom-color: #41a478;
}
.progress .bar.-danger {
  background: #ff4136;
  background: -webkit-linear-gradient(left, #ff4136, rgba(255,65,54,0.8));
  background: -moz-linear-gradient(left, #ff4136, rgba(255,65,54,0.8));
  background: -o-linear-gradient(left, #ff4136, rgba(255,65,54,0.8));
  background: -ms-linear-gradient(left, #ff4136, rgba(255,65,54,0.8));
  background: linear-gradient(to right, #ff4136, rgba(255,65,54,0.8));
  border-bottom-color: #ff4a40;
}
.progress .bar.-warning {
  background: #ff851b;
  background: -webkit-linear-gradient(left, #ff851b, rgba(255,133,27,0.8));
  background: -moz-linear-gradient(left, #ff851b, rgba(255,133,27,0.8));
  background: -o-linear-gradient(left, #ff851b, rgba(255,133,27,0.8));
  background: -ms-linear-gradient(left, #ff851b, rgba(255,133,27,0.8));
  background: linear-gradient(to right, #ff851b, rgba(255,133,27,0.8));
  border-bottom-color: #ff8b26;
}
.progress .bar.-info {
  background: #0074d9;
  background: -webkit-linear-gradient(left, #0074d9, rgba(0,116,217,0.8));
  background: -moz-linear-gradient(left, #0074d9, rgba(0,116,217,0.8));
  background: -o-linear-gradient(left, #0074d9, rgba(0,116,217,0.8));
  background: -ms-linear-gradient(left, #0074d9, rgba(0,116,217,0.8));
  background: linear-gradient(to right, #0074d9, rgba(0,116,217,0.8));
  border-bottom-color: #007ce8;
}
.navbar {
  margin: 0;
  padding: 0;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 9999;
  width: 100%;
  height: 70px;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}
@media screen and (max-width: 991px) {
  .navbar {
    height: auto;
  }
}
.navbar.-fixed-top,
.navbar.-fixed-bottom {
  position: fixed;
  left: 0;
}
.navbar.-fixed-top {
  top: 0;
}
.navbar.-fixed-bottom {
  bottom: 0;
}
.navbar .navbar-wrapper {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  background: transparent;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  zoom: 1;
}
.navbar .navbar-wrapper:before,
.navbar .navbar-wrapper:after {
  content: "";
  display: table;
}
.navbar .navbar-wrapper:after {
  clear: both;
}
@media screen and (max-width: 575px) {
  .navbar.-has-1-items .navbar-brand {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .navbar.-has-2-items .navbar-brand {
    width: 50%;
  }
  .navbar.-has-2-items .navbar-nav {
    width: 50%;
  }
  .navbar.-has-2-items .navbar-nav > li {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .navbar.-has-3-items .navbar-brand {
    width: 33.33333333333333%;
  }
  .navbar.-has-3-items .navbar-nav {
    width: 66.66666666666666%;
  }
  .navbar.-has-3-items .navbar-nav > li {
    width: 50%;
  }
}
@media screen and (max-width: 575px) {
  .navbar.-has-4-items .navbar-brand {
    width: 25%;
  }
  .navbar.-has-4-items .navbar-nav {
    width: 75%;
  }
  .navbar.-has-4-items .navbar-nav > li {
    width: 33.33333333333333%;
  }
}
@media screen and (max-width: 575px) {
  .navbar.-has-5-items .navbar-brand {
    width: 20%;
  }
  .navbar.-has-5-items .navbar-nav {
    width: 80%;
  }
  .navbar.-has-5-items .navbar-nav > li {
    width: 25%;
  }
}
@media screen and (max-width: 575px) {
  .navbar.-has-6-items .navbar-brand {
    width: 16.666666666666664%;
  }
  .navbar.-has-6-items .navbar-nav {
    width: 83.33333333333334%;
  }
  .navbar.-has-6-items .navbar-nav > li {
    width: 20%;
  }
}
@media screen and (max-width: 575px) {
  .navbar.-has-7-items .navbar-brand {
    width: 14.285714285714285%;
  }
  .navbar.-has-7-items .navbar-nav {
    width: 85.71428571428571%;
  }
  .navbar.-has-7-items .navbar-nav > li {
    width: 16.666666666666664%;
  }
}
@media screen and (max-width: 575px) {
  .navbar.-has-8-items .navbar-brand {
    width: 12.5%;
  }
  .navbar.-has-8-items .navbar-nav {
    width: 87.5%;
  }
  .navbar.-has-8-items .navbar-nav > li {
    width: 14.285714285714285%;
  }
}
.navbar .navbar-brand {
  padding: 0 1rem;
  width: 280px;
  font-weight: 300;
  font-size: 1.25rem;
  text-align: center;
  line-height: 70px;
  margin: 0;
  z-index: 99;
  display: block;
  float: left;
  clear: both;
  -webkit-transition: background-position 0.5s ease;
  -moz-transition: background-position 0.5s ease;
  -o-transition: background-position 0.5s ease;
  -ms-transition: background-position 0.5s ease;
  transition: background-position 0.5s ease;
  -webkit-background-size: 420px 1px;
  -moz-background-size: 420px 1px;
  background-size: 420px 1px;
}
@media screen and (max-width: 767px) {
  .navbar .navbar-brand {
    width: 210px;
  }
}
.navbar .navbar-brand:hover,
.navbar .navbar-brand:focus,
.navbar .navbar-brand:active {
  background-position: -140px;
}
.navbar .navbar-nav {
  position: relative;
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
}
.navbar .navbar-nav.-right {
  float: right;
}
.navbar .navbar-nav.-left {
  float: left;
}
@media screen and (max-width: 991px) {
  .navbar .navbar-nav {
    margin: 0;
  }
}
@media screen and (max-width: 575px) {
  .navbar .navbar-nav {
    float: left;
  }
}
.navbar .navbar-nav > li {
  text-align: center;
  display: block;
  float: left;
}
.navbar .navbar-nav > li > a {
  font-size: 1.5rem;
  line-height: 70px;
  height: 70px;
  width: 70px;
  display: block;
  position: relative;
  -webkit-transition: background 0.5s ease, color 0.5s ease;
  -moz-transition: background 0.5s ease, color 0.5s ease;
  -o-transition: background 0.5s ease, color 0.5s ease;
  -ms-transition: background 0.5s ease, color 0.5s ease;
  transition: background 0.5s ease, color 0.5s ease;
  border-left-width: 1px;
  border-left-style: solid;
}
@media screen and (max-width: 575px) {
  .navbar .navbar-nav > li > a {
    width: 100%;
  }
}
.navbar .navbar-nav > li > a:last-child {
  border-right-width: 1px;
  border-right-style: solid;
}
.navbar .navbar-profile i {
  position: relative;
  display: inline-block;
  margin-top: 20px;
  font-size: 1.75rem;
}
.navbar .navbar-profile img {
  width: 25px;
  height: 25px;
  margin-top: -5px;
  opacity: 0.75;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
  filter: alpha(opacity=75);
}
.navbar a:hover,
.navbar a:focus,
.navbar a:active {
  text-decoration: none;
}
.navbar .navbar-item-count {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border-radius: 2px;
  font-size: 10px;
  line-height: 10px;
  padding: 4px;
  background: #ec6ead;
}
.navbar.-light {
  background: rgba(255,255,255,0.05);
  background: -webkit-linear-gradient(right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -moz-linear-gradient(right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -o-linear-gradient(right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -ms-linear-gradient(right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: linear-gradient(to left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  border-bottom: 1px solid rgba(255,255,255,0.05);
}
.navbar.-light.-opaque .navbar-wrapper {
  background: rgba(255,255,255,0.5) !important;
}
.navbar.-light,
.navbar.-light a {
  color: #000;
}
.navbar.-light .navbar-brand {
  background-image: -webkit-linear-gradient(left, rgba(255,255,255,0.15), rgba(255,255,255,0));
  background-image: -moz-linear-gradient(left, rgba(255,255,255,0.15), rgba(255,255,255,0));
  background-image: -o-linear-gradient(left, rgba(255,255,255,0.15), rgba(255,255,255,0));
  background-image: -ms-linear-gradient(left, rgba(255,255,255,0.15), rgba(255,255,255,0));
  background-image: linear-gradient(to right, rgba(255,255,255,0.15), rgba(255,255,255,0));
  border-right: rgba(255,255,255,0.05);
}
.navbar.-light .navbar-nav > li > a {
  background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.05), rgba(255,255,255,0));
  background-image: -moz-linear-gradient(top, rgba(255,255,255,0.05), rgba(255,255,255,0));
  background-image: -o-linear-gradient(top, rgba(255,255,255,0.05), rgba(255,255,255,0));
  background-image: -ms-linear-gradient(top, rgba(255,255,255,0.05), rgba(255,255,255,0));
  background-image: linear-gradient(to bottom, rgba(255,255,255,0.05), rgba(255,255,255,0));
  border-left-color: rgba(255,255,255,0.05);
  border-right-color: rgba(255,255,255,0.05);
}
.navbar.-light .navbar-nav > li > a:hover,
.navbar.-light .navbar-nav > li > a:focus,
.navbar.-light .navbar-nav > li > a:active {
  background-color: rgba(255,255,255,0.05);
}
.navbar.-light .navbar-nav > li > a.-active {
  background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background-image: -moz-linear-gradient(top, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background-image: -o-linear-gradient(top, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background-image: -ms-linear-gradient(top, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background-image: linear-gradient(to bottom, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
}
.navbar.-dark {
  background: rgba(0,0,0,0.125);
  background: -webkit-linear-gradient(right, rgba(0,0,0,0.125), rgba(0,0,0,0.25));
  background: -moz-linear-gradient(right, rgba(0,0,0,0.125), rgba(0,0,0,0.25));
  background: -o-linear-gradient(right, rgba(0,0,0,0.125), rgba(0,0,0,0.25));
  background: -ms-linear-gradient(right, rgba(0,0,0,0.125), rgba(0,0,0,0.25));
  background: linear-gradient(to left, rgba(0,0,0,0.125), rgba(0,0,0,0.25));
  border-bottom: 1px solid rgba(0,0,0,0.125);
}
.navbar.-dark.-opaque .navbar-wrapper {
  background: rgba(0,0,0,0.5) !important;
}
.navbar.-dark,
.navbar.-dark a {
  color: #fff;
}
.navbar.-dark .navbar-brand {
  background-image: -webkit-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background-image: -moz-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background-image: -o-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background-image: -ms-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background-image: linear-gradient(to right, rgba(0,0,0,0.25), rgba(0,0,0,0));
  border-right: rgba(0,0,0,0.125);
}
.navbar.-dark .navbar-nav > li > a {
  background-image: -webkit-linear-gradient(top, rgba(0,0,0,0.125), rgba(0,0,0,0));
  background-image: -moz-linear-gradient(top, rgba(0,0,0,0.125), rgba(0,0,0,0));
  background-image: -o-linear-gradient(top, rgba(0,0,0,0.125), rgba(0,0,0,0));
  background-image: -ms-linear-gradient(top, rgba(0,0,0,0.125), rgba(0,0,0,0));
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.125), rgba(0,0,0,0));
  border-left-color: rgba(0,0,0,0.125);
  border-right-color: rgba(0,0,0,0.125);
}
.navbar.-dark .navbar-nav > li > a:hover,
.navbar.-dark .navbar-nav > li > a:focus,
.navbar.-dark .navbar-nav > li > a:active {
  background-color: rgba(0,0,0,0.125);
}
.navbar.-dark .navbar-nav > li > a.-active {
  background-image: -webkit-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -moz-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -o-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -ms-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
}
#gritter-notice-wrapper {
  position: fixed;
  top: 86px;
  right: 16px;
  width: 380px;
  max-width: 90%;
  z-index: 9999;
}
#gritter-notice-wrapper.top-left {
  left: 296px;
  right: auto;
}
#gritter-notice-wrapper.bottom-right {
  top: auto;
  left: auto;
  bottom: 16px;
  right: 16px;
}
#gritter-notice-wrapper.bottom-left {
  top: auto;
  right: auto;
  bottom: 16px;
  left: 296px;
}
.gritter-close {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: #fff;
}
.gritter-close:active {
  outline: none;
}
.gritter-title {
  font-size: 1rem;
  font-weight: normal;
  margin: 0 0 0.5rem 0;
  display: block;
}
.gritter-image-wrapper {
  zoom: 1;
  float: left;
}
.gritter-image-wrapper:before,
.gritter-image-wrapper:after {
  content: "";
  display: table;
}
.gritter-image-wrapper:after {
  clear: both;
}
.gritter-image {
  width: 70px;
  height: 70px;
  float: left;
  margin-right: 1rem;
}
.gritter-item-wrapper {
  position: relative;
  margin: 0 0 1rem 0;
}
.gritter-item {
  display: block;
  padding: 1rem;
  position: relative;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
.gritter-item.-social {
  padding-left: 116px;
}
.gritter-item.-social .gritter-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  display: block;
  background: rgba(0,0,0,0.125);
}
.gritter-item.-social .gritter-image {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.gritter-item p {
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
  word-wrap: break-word;
}
.gritter-item.-light {
  background: rgba(255,255,255,0.95);
  background: -webkit-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.5));
  background: -moz-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.5));
  background: -o-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.5));
  background: -ms-linear-gradient(left, rgba(255,255,255,0.95), rgba(255,255,255,0.5));
  background: linear-gradient(to right, rgba(255,255,255,0.95), rgba(255,255,255,0.5));
  color: #000;
}
.gritter-item.-light p {
  color: rgba(0,0,0,0.75);
}
.gritter-item.-dark {
  background: rgba(0,0,0,0.8);
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.5));
  background: -moz-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.5));
  background: -o-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.5));
  background: -ms-linear-gradient(left, rgba(0,0,0,0.8), rgba(0,0,0,0.5));
  background: linear-gradient(to right, rgba(0,0,0,0.8), rgba(0,0,0,0.5));
  color: #fff;
}
.gritter-item.-dark p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-primary {
  background: #ec6ead;
  background: -webkit-linear-gradient(left, rgba(236,110,173,0.8), rgba(236,110,173,0.5));
  background: -moz-linear-gradient(left, rgba(236,110,173,0.8), rgba(236,110,173,0.5));
  background: -o-linear-gradient(left, rgba(236,110,173,0.8), rgba(236,110,173,0.5));
  background: -ms-linear-gradient(left, rgba(236,110,173,0.8), rgba(236,110,173,0.5));
  background: linear-gradient(to right, rgba(236,110,173,0.8), rgba(236,110,173,0.5));
  color: #fff;
}
.gritter-item.-primary p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-secondary {
  background: #3494e6;
  background: -webkit-linear-gradient(left, rgba(52,148,230,0.8), rgba(52,148,230,0.5));
  background: -moz-linear-gradient(left, rgba(52,148,230,0.8), rgba(52,148,230,0.5));
  background: -o-linear-gradient(left, rgba(52,148,230,0.8), rgba(52,148,230,0.5));
  background: -ms-linear-gradient(left, rgba(52,148,230,0.8), rgba(52,148,230,0.5));
  background: linear-gradient(to right, rgba(52,148,230,0.8), rgba(52,148,230,0.5));
  color: #fff;
}
.gritter-item.-secondary p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-success {
  background: #3d9970;
  background: -webkit-linear-gradient(left, rgba(61,153,112,0.8), rgba(61,153,112,0.5));
  background: -moz-linear-gradient(left, rgba(61,153,112,0.8), rgba(61,153,112,0.5));
  background: -o-linear-gradient(left, rgba(61,153,112,0.8), rgba(61,153,112,0.5));
  background: -ms-linear-gradient(left, rgba(61,153,112,0.8), rgba(61,153,112,0.5));
  background: linear-gradient(to right, rgba(61,153,112,0.8), rgba(61,153,112,0.5));
  color: #fff;
}
.gritter-item.-success p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-danger {
  background: #ff4136;
  background: -webkit-linear-gradient(left, rgba(255,65,54,0.8), rgba(255,65,54,0.5));
  background: -moz-linear-gradient(left, rgba(255,65,54,0.8), rgba(255,65,54,0.5));
  background: -o-linear-gradient(left, rgba(255,65,54,0.8), rgba(255,65,54,0.5));
  background: -ms-linear-gradient(left, rgba(255,65,54,0.8), rgba(255,65,54,0.5));
  background: linear-gradient(to right, rgba(255,65,54,0.8), rgba(255,65,54,0.5));
  color: #fff;
}
.gritter-item.-danger p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-warning {
  background: #ff851b;
  background: -webkit-linear-gradient(left, rgba(255,133,27,0.8), rgba(255,133,27,0.5));
  background: -moz-linear-gradient(left, rgba(255,133,27,0.8), rgba(255,133,27,0.5));
  background: -o-linear-gradient(left, rgba(255,133,27,0.8), rgba(255,133,27,0.5));
  background: -ms-linear-gradient(left, rgba(255,133,27,0.8), rgba(255,133,27,0.5));
  background: linear-gradient(to right, rgba(255,133,27,0.8), rgba(255,133,27,0.5));
  color: #fff;
}
.gritter-item.-warning p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-info {
  background: #0074d9;
  background: -webkit-linear-gradient(left, rgba(0,116,217,0.8), rgba(0,116,217,0.5));
  background: -moz-linear-gradient(left, rgba(0,116,217,0.8), rgba(0,116,217,0.5));
  background: -o-linear-gradient(left, rgba(0,116,217,0.8), rgba(0,116,217,0.5));
  background: -ms-linear-gradient(left, rgba(0,116,217,0.8), rgba(0,116,217,0.5));
  background: linear-gradient(to right, rgba(0,116,217,0.8), rgba(0,116,217,0.5));
  color: #fff;
}
.gritter-item.-info p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-facebook {
  background: #3b5998;
  background: -webkit-linear-gradient(left, rgba(59,89,152,0.8), rgba(59,89,152,0.5));
  background: -moz-linear-gradient(left, rgba(59,89,152,0.8), rgba(59,89,152,0.5));
  background: -o-linear-gradient(left, rgba(59,89,152,0.8), rgba(59,89,152,0.5));
  background: -ms-linear-gradient(left, rgba(59,89,152,0.8), rgba(59,89,152,0.5));
  background: linear-gradient(to right, rgba(59,89,152,0.8), rgba(59,89,152,0.5));
  color: #fff;
}
.gritter-item.-facebook p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-twitter {
  background: #1da1f2;
  background: -webkit-linear-gradient(left, rgba(29,161,242,0.8), rgba(29,161,242,0.5));
  background: -moz-linear-gradient(left, rgba(29,161,242,0.8), rgba(29,161,242,0.5));
  background: -o-linear-gradient(left, rgba(29,161,242,0.8), rgba(29,161,242,0.5));
  background: -ms-linear-gradient(left, rgba(29,161,242,0.8), rgba(29,161,242,0.5));
  background: linear-gradient(to right, rgba(29,161,242,0.8), rgba(29,161,242,0.5));
  color: #fff;
}
.gritter-item.-twitter p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-google-plus {
  background: #dd4b39;
  background: -webkit-linear-gradient(left, rgba(221,75,57,0.8), rgba(221,75,57,0.5));
  background: -moz-linear-gradient(left, rgba(221,75,57,0.8), rgba(221,75,57,0.5));
  background: -o-linear-gradient(left, rgba(221,75,57,0.8), rgba(221,75,57,0.5));
  background: -ms-linear-gradient(left, rgba(221,75,57,0.8), rgba(221,75,57,0.5));
  background: linear-gradient(to right, rgba(221,75,57,0.8), rgba(221,75,57,0.5));
  color: #fff;
}
.gritter-item.-google-plus p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-dribbble {
  background: #ea4c89;
  background: -webkit-linear-gradient(left, rgba(234,76,137,0.8), rgba(234,76,137,0.5));
  background: -moz-linear-gradient(left, rgba(234,76,137,0.8), rgba(234,76,137,0.5));
  background: -o-linear-gradient(left, rgba(234,76,137,0.8), rgba(234,76,137,0.5));
  background: -ms-linear-gradient(left, rgba(234,76,137,0.8), rgba(234,76,137,0.5));
  background: linear-gradient(to right, rgba(234,76,137,0.8), rgba(234,76,137,0.5));
  color: #fff;
}
.gritter-item.-dribbble p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-behance {
  background: #1769ff;
  background: -webkit-linear-gradient(left, rgba(23,105,255,0.8), rgba(23,105,255,0.5));
  background: -moz-linear-gradient(left, rgba(23,105,255,0.8), rgba(23,105,255,0.5));
  background: -o-linear-gradient(left, rgba(23,105,255,0.8), rgba(23,105,255,0.5));
  background: -ms-linear-gradient(left, rgba(23,105,255,0.8), rgba(23,105,255,0.5));
  background: linear-gradient(to right, rgba(23,105,255,0.8), rgba(23,105,255,0.5));
  color: #fff;
}
.gritter-item.-behance p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-flickr {
  background: #ff0084;
  background: -webkit-linear-gradient(left, rgba(255,0,132,0.8), rgba(255,0,132,0.5));
  background: -moz-linear-gradient(left, rgba(255,0,132,0.8), rgba(255,0,132,0.5));
  background: -o-linear-gradient(left, rgba(255,0,132,0.8), rgba(255,0,132,0.5));
  background: -ms-linear-gradient(left, rgba(255,0,132,0.8), rgba(255,0,132,0.5));
  background: linear-gradient(to right, rgba(255,0,132,0.8), rgba(255,0,132,0.5));
  color: #fff;
}
.gritter-item.-flickr p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-linkedin {
  background: #0077b5;
  background: -webkit-linear-gradient(left, rgba(0,119,181,0.8), rgba(0,119,181,0.5));
  background: -moz-linear-gradient(left, rgba(0,119,181,0.8), rgba(0,119,181,0.5));
  background: -o-linear-gradient(left, rgba(0,119,181,0.8), rgba(0,119,181,0.5));
  background: -ms-linear-gradient(left, rgba(0,119,181,0.8), rgba(0,119,181,0.5));
  background: linear-gradient(to right, rgba(0,119,181,0.8), rgba(0,119,181,0.5));
  color: #fff;
}
.gritter-item.-linkedin p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-youtube {
  background: #cd201f;
  background: -webkit-linear-gradient(left, rgba(205,32,31,0.8), rgba(205,32,31,0.5));
  background: -moz-linear-gradient(left, rgba(205,32,31,0.8), rgba(205,32,31,0.5));
  background: -o-linear-gradient(left, rgba(205,32,31,0.8), rgba(205,32,31,0.5));
  background: -ms-linear-gradient(left, rgba(205,32,31,0.8), rgba(205,32,31,0.5));
  background: linear-gradient(to right, rgba(205,32,31,0.8), rgba(205,32,31,0.5));
  color: #fff;
}
.gritter-item.-youtube p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-pinterest {
  background: #bd081c;
  background: -webkit-linear-gradient(left, rgba(189,8,28,0.8), rgba(189,8,28,0.5));
  background: -moz-linear-gradient(left, rgba(189,8,28,0.8), rgba(189,8,28,0.5));
  background: -o-linear-gradient(left, rgba(189,8,28,0.8), rgba(189,8,28,0.5));
  background: -ms-linear-gradient(left, rgba(189,8,28,0.8), rgba(189,8,28,0.5));
  background: linear-gradient(to right, rgba(189,8,28,0.8), rgba(189,8,28,0.5));
  color: #fff;
}
.gritter-item.-pinterest p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-github {
  background: #333;
  background: -webkit-linear-gradient(left, rgba(51,51,51,0.8), rgba(51,51,51,0.5));
  background: -moz-linear-gradient(left, rgba(51,51,51,0.8), rgba(51,51,51,0.5));
  background: -o-linear-gradient(left, rgba(51,51,51,0.8), rgba(51,51,51,0.5));
  background: -ms-linear-gradient(left, rgba(51,51,51,0.8), rgba(51,51,51,0.5));
  background: linear-gradient(to right, rgba(51,51,51,0.8), rgba(51,51,51,0.5));
  color: #fff;
}
.gritter-item.-github p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-tumblr {
  background: #35465c;
  background: -webkit-linear-gradient(left, rgba(53,70,92,0.8), rgba(53,70,92,0.5));
  background: -moz-linear-gradient(left, rgba(53,70,92,0.8), rgba(53,70,92,0.5));
  background: -o-linear-gradient(left, rgba(53,70,92,0.8), rgba(53,70,92,0.5));
  background: -ms-linear-gradient(left, rgba(53,70,92,0.8), rgba(53,70,92,0.5));
  background: linear-gradient(to right, rgba(53,70,92,0.8), rgba(53,70,92,0.5));
  color: #fff;
}
.gritter-item.-tumblr p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-twitch {
  background: #6441a5;
  background: -webkit-linear-gradient(left, rgba(100,65,165,0.8), rgba(100,65,165,0.5));
  background: -moz-linear-gradient(left, rgba(100,65,165,0.8), rgba(100,65,165,0.5));
  background: -o-linear-gradient(left, rgba(100,65,165,0.8), rgba(100,65,165,0.5));
  background: -ms-linear-gradient(left, rgba(100,65,165,0.8), rgba(100,65,165,0.5));
  background: linear-gradient(to right, rgba(100,65,165,0.8), rgba(100,65,165,0.5));
  color: #fff;
}
.gritter-item.-twitch p {
  color: rgba(255,255,255,0.85);
}
.gritter-item.-envato {
  background: #82b541;
  background: -webkit-linear-gradient(left, rgba(130,181,65,0.8), rgba(130,181,65,0.5));
  background: -moz-linear-gradient(left, rgba(130,181,65,0.8), rgba(130,181,65,0.5));
  background: -o-linear-gradient(left, rgba(130,181,65,0.8), rgba(130,181,65,0.5));
  background: -ms-linear-gradient(left, rgba(130,181,65,0.8), rgba(130,181,65,0.5));
  background: linear-gradient(to right, rgba(130,181,65,0.8), rgba(130,181,65,0.5));
  color: #fff;
}
.gritter-item.-envato p {
  color: rgba(255,255,255,0.85);
}
.sidebar {
  width: 280px;
  height: 100%;
  z-index: 98;
  display: block;
  position: fixed;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-style: solid;
  font-size: 1rem;
  border-width: 0;
  top: 0;
}
.sidebar.-left {
  left: 0;
}
.sidebar.-right {
  right: 0;
}
.sidebar .nano-content {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
@media screen and (max-width: 991px) {
  .sidebar.-collapsible {
    width: 60px;
  }
  .sidebar.-collapsible.-open {
    width: 340px;
  }
  .sidebar.-collapsible.-open .nano-pane {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  .sidebar.-collapsible .sidebar-heading {
    display: none !important;
  }
  .sidebar.-collapsible .sidebar-wrapper {
    width: 60px;
  }
  .sidebar.-collapsible .sidebar-wrapper > .sidebar-menu:focus {
    outline: 0;
  }
  .sidebar.-collapsible .sidebar-wrapper > .sidebar-menu > li > ul {
    visibility: visible;
    width: 280px;
    position: absolute;
    top: 0;
    right: -280px;
  }
  .sidebar.-collapsible .sidebar-wrapper > .sidebar-menu > li > a {
    padding: 0;
  }
  .sidebar.-collapsible .sidebar-wrapper > .sidebar-menu > li > a span {
    display: none;
  }
}
.sidebar .tag {
  margin-right: 10px;
  margin-top: 20px;
  float: right;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.sidebar .nano-pane {
  margin-top: 70px;
}
.sidebar .sidebar-wrapper ul {
  list-style: none;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 0;
  margin-right: 0;
  padding: 0;
  width: 100%;
}
.sidebar .sidebar-wrapper ul li {
  width: 100%;
  display: block;
  position: relative;
  display: block;
}
.sidebar .sidebar-wrapper ul li.sidebar-heading {
  padding: 1rem 1rem;
  font-size: 0.9rem;
}
.sidebar .sidebar-wrapper ul li a {
  display: block;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.sidebar .sidebar-wrapper ul li a:hover,
.sidebar .sidebar-wrapper ul li a:focus,
.sidebar .sidebar-wrapper ul li a:active {
  text-decoration: none;
}
.sidebar .sidebar-wrapper > ul {
  margin-top: 70px;
}
.sidebar .sidebar-wrapper > ul > li > a {
  padding: 0 1rem 0 75px;
  height: 60px;
  line-height: 60px;
  border: 0;
}
.sidebar .sidebar-wrapper > ul > li > a .arrow {
  line-height: 60px;
  margin-right: 1rem;
}
.sidebar .sidebar-wrapper > ul > li > a::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
}
.sidebar .sidebar-wrapper > ul > li > a i {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-size: 1.25rem;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 59px;
  line-height: 60px;
  width: 60px;
  text-align: center;
}
.sidebar .sidebar-wrapper > ul > li ul li a {
  padding: 0 1rem;
  height: 45px;
  line-height: 45px;
  font-size: 0.9rem;
}
@media screen and (max-width: 991px) {
  .sidebar.-light.-collapsible .sidebar-wrapper ul > li > ul {
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    background: rgba(0,0,0,0.7);
    background: -webkit-linear-gradient(top left, rgba(255,255,255,0.85), rgba(255,255,255,0.5));
    background: -moz-linear-gradient(top left, rgba(255,255,255,0.85), rgba(255,255,255,0.5));
    background: -o-linear-gradient(top left, rgba(255,255,255,0.85), rgba(255,255,255,0.5));
    background: -ms-linear-gradient(top left, rgba(255,255,255,0.85), rgba(255,255,255,0.5));
    background: linear-gradient(to bottom right, rgba(255,255,255,0.85), rgba(255,255,255,0.5));
  }
}
.sidebar.-light .sidebar-wrapper ul {
  border-color: rgba(255,255,255,0.05);
}
.sidebar.-light .sidebar-wrapper ul li.sidebar-heading {
  color: #000;
}
.sidebar.-light .sidebar-wrapper ul li.-active {
  background: -webkit-linear-gradient(right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -moz-linear-gradient(right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -o-linear-gradient(right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: -ms-linear-gradient(right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background: linear-gradient(to left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
}
.sidebar.-light .sidebar-wrapper ul li.-active > a {
  font-weight: bold;
  color: #000;
}
.sidebar.-light .sidebar-wrapper ul li.-active > a i {
  background: #ec6ead;
}
.sidebar.-light .sidebar-wrapper ul li.-active > a:hover,
.sidebar.-light .sidebar-wrapper ul li.-active > a:focus,
.sidebar.-light .sidebar-wrapper ul li.-active > a:active,
.sidebar.-light .sidebar-wrapper ul li.-active > a[aria-expanded="true"] {
  background-position: -280px;
}
.sidebar.-light .sidebar-wrapper ul li.-active > a:hover i,
.sidebar.-light .sidebar-wrapper ul li.-active > a:focus i,
.sidebar.-light .sidebar-wrapper ul li.-active > a:active i,
.sidebar.-light .sidebar-wrapper ul li.-active > a[aria-expanded="true"] i {
  background: #ec6ead;
}
.sidebar.-light .sidebar-wrapper ul li:last-child a::after {
  display: none;
}
.sidebar.-light .sidebar-wrapper ul li a {
  background: -webkit-linear-gradient(left, rgba(255,255,255,0.15), rgba(255,255,255,0));
  background: -moz-linear-gradient(left, rgba(255,255,255,0.15), rgba(255,255,255,0));
  background: -o-linear-gradient(left, rgba(255,255,255,0.15), rgba(255,255,255,0));
  background: -ms-linear-gradient(left, rgba(255,255,255,0.15), rgba(255,255,255,0));
  background: linear-gradient(to right, rgba(255,255,255,0.15), rgba(255,255,255,0));
  color: rgba(0,0,0,0.75);
}
.sidebar.-light .sidebar-wrapper ul li a[aria-expanded="true"] i {
  color: #000;
  background: rgba(236,110,173,0.33);
}
.sidebar.-light .sidebar-wrapper ul li a:hover,
.sidebar.-light .sidebar-wrapper ul li a:focus,
.sidebar.-light .sidebar-wrapper ul li a:active {
  color: #000;
  background-position: -280px;
}
.sidebar.-light .sidebar-wrapper ul li a:hover i,
.sidebar.-light .sidebar-wrapper ul li a:focus i,
.sidebar.-light .sidebar-wrapper ul li a:active i {
  background: rgba(236,110,173,0.66);
}
.sidebar.-light .sidebar-wrapper ul li i {
  background: rgba(255,255,255,0.05);
  background: -webkit-linear-gradient(left, rgba(255,255,255,0.05), rgba(255,255,255,0));
  background: -moz-linear-gradient(left, rgba(255,255,255,0.05), rgba(255,255,255,0));
  background: -o-linear-gradient(left, rgba(255,255,255,0.05), rgba(255,255,255,0));
  background: -ms-linear-gradient(left, rgba(255,255,255,0.05), rgba(255,255,255,0));
  background: linear-gradient(to right, rgba(255,255,255,0.05), rgba(255,255,255,0));
}
.sidebar.-light .sidebar-wrapper > ul {
  background: rgba(255,255,255,0.05);
  background: -webkit-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0));
  background: -moz-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0));
  background: -o-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0));
  background: -ms-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0));
  background: linear-gradient(to bottom right, rgba(255,255,255,0.15), rgba(255,255,255,0));
  border-color: rgba(0,0,0,0.25);
}
.sidebar.-light .sidebar-wrapper > ul > li > a::after {
  background: -webkit-linear-gradient(left, rgba(255,255,255,0.15), rgba(255,255,255,0));
  background: -moz-linear-gradient(left, rgba(255,255,255,0.15), rgba(255,255,255,0));
  background: -o-linear-gradient(left, rgba(255,255,255,0.15), rgba(255,255,255,0));
  background: -ms-linear-gradient(left, rgba(255,255,255,0.15), rgba(255,255,255,0));
  background: linear-gradient(to right, rgba(255,255,255,0.15), rgba(255,255,255,0));
}
.sidebar.-light .sidebar-wrapper > ul > li ul {
  border-top: 1px solid rgba(255,255,255,0.15);
  border-bottom: 1px solid rgba(255,255,255,0.15);
  background: -webkit-linear-gradient(top, rgba(255,255,255,0.15), rgba(255,255,255,0.05), rgba(255,255,255,0.05));
  background: -moz-linear-gradient(top, rgba(255,255,255,0.15), rgba(255,255,255,0.05), rgba(255,255,255,0.05));
  background: -o-linear-gradient(top, rgba(255,255,255,0.15), rgba(255,255,255,0.05), rgba(255,255,255,0.05));
  background: -ms-linear-gradient(top, rgba(255,255,255,0.15), rgba(255,255,255,0.05), rgba(255,255,255,0.05));
  background: linear-gradient(to bottom, rgba(255,255,255,0.15), rgba(255,255,255,0.05), rgba(255,255,255,0.05));
}
@media screen and (max-width: 991px) {
  .sidebar.-dark.-collapsible .sidebar-wrapper ul > li > ul {
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    background: rgba(0,0,0,0.7);
    background: -webkit-linear-gradient(top left, rgba(0,0,0,0.7), rgba(0,0,0,0.5));
    background: -moz-linear-gradient(top left, rgba(0,0,0,0.7), rgba(0,0,0,0.5));
    background: -o-linear-gradient(top left, rgba(0,0,0,0.7), rgba(0,0,0,0.5));
    background: -ms-linear-gradient(top left, rgba(0,0,0,0.7), rgba(0,0,0,0.5));
    background: linear-gradient(to bottom right, rgba(0,0,0,0.7), rgba(0,0,0,0.5));
  }
}
.sidebar.-dark .sidebar-wrapper ul {
  border-color: rgba(0,0,0,0.125);
}
.sidebar.-dark .sidebar-wrapper ul li.sidebar-heading {
  color: #fff;
}
.sidebar.-dark .sidebar-wrapper ul li.-active {
  background: -webkit-linear-gradient(right, rgba(0,0,0,0.125), rgba(0,0,0,0.25));
  background: -moz-linear-gradient(right, rgba(0,0,0,0.125), rgba(0,0,0,0.25));
  background: -o-linear-gradient(right, rgba(0,0,0,0.125), rgba(0,0,0,0.25));
  background: -ms-linear-gradient(right, rgba(0,0,0,0.125), rgba(0,0,0,0.25));
  background: linear-gradient(to left, rgba(0,0,0,0.125), rgba(0,0,0,0.25));
}
.sidebar.-dark .sidebar-wrapper ul li.-active > a {
  font-weight: bold;
  color: #fff;
}
.sidebar.-dark .sidebar-wrapper ul li.-active > a i {
  background: #ec6ead;
}
.sidebar.-dark .sidebar-wrapper ul li.-active > a:hover,
.sidebar.-dark .sidebar-wrapper ul li.-active > a:focus,
.sidebar.-dark .sidebar-wrapper ul li.-active > a:active,
.sidebar.-dark .sidebar-wrapper ul li.-active > a[aria-expanded="true"] {
  background-position: -280px;
}
.sidebar.-dark .sidebar-wrapper ul li.-active > a:hover i,
.sidebar.-dark .sidebar-wrapper ul li.-active > a:focus i,
.sidebar.-dark .sidebar-wrapper ul li.-active > a:active i,
.sidebar.-dark .sidebar-wrapper ul li.-active > a[aria-expanded="true"] i {
  background: #ec6ead;
}
.sidebar.-dark .sidebar-wrapper ul li:last-child a::after {
  display: none;
}
.sidebar.-dark .sidebar-wrapper ul li a {
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background: -moz-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background: -o-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background: -ms-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background: linear-gradient(to right, rgba(0,0,0,0.25), rgba(0,0,0,0));
  color: rgba(255,255,255,0.85);
}
.sidebar.-dark .sidebar-wrapper ul li a[aria-expanded="true"] i {
  color: #fff;
  background: rgba(236,110,173,0.33);
}
.sidebar.-dark .sidebar-wrapper ul li a:hover,
.sidebar.-dark .sidebar-wrapper ul li a:focus,
.sidebar.-dark .sidebar-wrapper ul li a:active {
  color: #fff;
  background-position: -280px;
}
.sidebar.-dark .sidebar-wrapper ul li a:hover i,
.sidebar.-dark .sidebar-wrapper ul li a:focus i,
.sidebar.-dark .sidebar-wrapper ul li a:active i {
  background: rgba(236,110,173,0.66);
}
.sidebar.-dark .sidebar-wrapper ul li i {
  background: rgba(0,0,0,0.125);
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.125), rgba(0,0,0,0));
  background: -moz-linear-gradient(left, rgba(0,0,0,0.125), rgba(0,0,0,0));
  background: -o-linear-gradient(left, rgba(0,0,0,0.125), rgba(0,0,0,0));
  background: -ms-linear-gradient(left, rgba(0,0,0,0.125), rgba(0,0,0,0));
  background: linear-gradient(to right, rgba(0,0,0,0.125), rgba(0,0,0,0));
}
.sidebar.-dark .sidebar-wrapper > ul {
  background: rgba(0,0,0,0.125);
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background: -o-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background: -ms-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background: linear-gradient(to bottom right, rgba(0,0,0,0.25), rgba(0,0,0,0));
  border-color: rgba(0,0,0,0.25);
}
.sidebar.-dark .sidebar-wrapper > ul > li > a::after {
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background: -moz-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background: -o-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background: -ms-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0));
  background: linear-gradient(to right, rgba(0,0,0,0.25), rgba(0,0,0,0));
}
.sidebar.-dark .sidebar-wrapper > ul > li ul {
  border-top: 1px solid rgba(0,0,0,0.25);
  border-bottom: 1px solid rgba(0,0,0,0.25);
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.125), rgba(0,0,0,0.125));
  background: -moz-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.125), rgba(0,0,0,0.125));
  background: -o-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.125), rgba(0,0,0,0.125));
  background: -ms-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.125), rgba(0,0,0,0.125));
  background: linear-gradient(to bottom, rgba(0,0,0,0.25), rgba(0,0,0,0.125), rgba(0,0,0,0.125));
}
.sidebar.-slideable {
  color: rgba(255,255,255,0.5);
  z-index: 999;
  overflow: hidden;
  -webkit-transition: left 0.5s ease, right 0.5s ease;
  -moz-transition: left 0.5s ease, right 0.5s ease;
  -o-transition: left 0.5s ease, right 0.5s ease;
  -ms-transition: left 0.5s ease, right 0.5s ease;
  transition: left 0.5s ease, right 0.5s ease;
}
.sidebar.-slideable.-right {
  right: -280px;
}
.sidebar.-slideable.-right.-open {
  right: 0;
}
.sidebar.-slideable.-left {
  left: -280px;
}
.sidebar.-slideable.-left.-open {
  left: 0;
}
.sidebar.-slideable .sidebar-content {
  margin-top: 70px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  background: rgba(0,0,0,0.7);
}
.sidebar .sidebar-tabs {
  display: block;
  position: absolute;
  top: 70px;
  right: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  z-index: 1;
  background: rgba(0,0,0,0.5);
  zoom: 1;
}
.sidebar .sidebar-tabs:before,
.sidebar .sidebar-tabs:after {
  content: "";
  display: table;
}
.sidebar .sidebar-tabs:after {
  clear: both;
}
.sidebar .sidebar-tabs .sidebar-tab {
  display: inline-block;
  float: left;
  position: relative;
  width: 50%;
  text-align: center;
  padding: 1rem 0.5rem;
  border-right: 1px solid rgba(255,255,255,0.05);
  background: rgba(255,255,255,0.05);
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.sidebar .sidebar-tabs .sidebar-tab.-active {
  background: rgba(255,255,255,0.15);
  color: #fff;
}
.sidebar .sidebar-tabs .sidebar-tab:hover,
.sidebar .sidebar-tabs .sidebar-tab:focus,
.sidebar .sidebar-tabs .sidebar-tab:active {
  color: rgba(255,255,255,0.85);
}
.sidebar .sidebar-tabs .sidebar-tab:last-child {
  border-right: 0px;
}
.sidebar .sidebar-tab-content {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -o-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  display: none;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.sidebar .sidebar-tab-content.-active {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -o-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  position: relative;
  display: block;
}
.sidebar .sidebar-content-section {
  padding: 5rem 1rem 1rem;
  display: block;
}
.sidebar .sidebar-content-section h1,
.sidebar .sidebar-content-section h2,
.sidebar .sidebar-content-section h3,
.sidebar .sidebar-content-section h4,
.sidebar .sidebar-content-section h5,
.sidebar .sidebar-content-section h6 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.sidebar .sidebar-content-section h1:first-child,
.sidebar .sidebar-content-section h2:first-child,
.sidebar .sidebar-content-section h3:first-child,
.sidebar .sidebar-content-section h4:first-child,
.sidebar .sidebar-content-section h5:first-child,
.sidebar .sidebar-content-section h6:first-child {
  margin-top: 0rem;
}
.sidebar .chat-list-item {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 1rem;
  zoom: 1;
}
.sidebar .chat-list-item:before,
.sidebar .chat-list-item:after {
  content: "";
  display: table;
}
.sidebar .chat-list-item:after {
  clear: both;
}
.sidebar .chat-list-item.-right {
  text-align: right;
}
.sidebar .chat-list-item.-right .chat-list-avatar {
  float: right;
  margin-right: 0;
  margin-left: 0.75rem;
}
.sidebar .chat-list-item.-right .chat-list-status {
  left: auto;
  right: 3px;
}
.sidebar .chat-list-item.-right .chat-user-back {
  position: absolute;
  top: 50%;
  left: 1rem;
  margin-top: -10px;
}
.sidebar .chat-list-item:hover,
.sidebar .chat-list-item:focus,
.sidebar .chat-list-item:active {
  background: rgba(255,255,255,0.05);
}
.sidebar .chat-list-item:hover .chat-list-status,
.sidebar .chat-list-item:focus .chat-list-status,
.sidebar .chat-list-item:active .chat-list-status {
  -webkit-transform: scale(1.5) translate(-25%, -25%);
  -moz-transform: scale(1.5) translate(-25%, -25%);
  -o-transform: scale(1.5) translate(-25%, -25%);
  -ms-transform: scale(1.5) translate(-25%, -25%);
  transform: scale(1.5) translate(-25%, -25%);
}
.sidebar .chat-list-item .chat-list-avatar {
  float: left;
  width: 42px;
  height: 42px;
  border-radius: 2px;
  margin-right: 0.75rem;
}
.sidebar .chat-list-item .chat-list-user {
  color: #fff;
}
.sidebar .chat-list-item .chat-list-excerpt {
  font-size: 0.8rem;
}
.sidebar .chat-list-item .chat-list-status {
  position: absolute;
  font-size: 0.6rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: 3px;
  left: 3px;
}
.sidebar .chat-list-item.-available .chat-list-status {
  color: #3d9970;
}
.sidebar .chat-list-item.-busy .chat-list-status {
  color: #ff4136;
}
.sidebar .chat-list-item.-away .chat-list-status {
  color: #ff851b;
}
.sidebar .tasks-list .task {
  color: #fff;
  margin-bottom: 0;
}
.sidebar .tasks-list .task label {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 0;
}
.sidebar .tasks-list .task label::before {
  border: 1px solid rgba(255,255,255,0.15);
  margin-bottom: 0.1rem;
}
.sidebar .tasks-list .task input:checked + label {
  color: rgba(255,255,255,0.5);
  text-decoration: line-through;
}
.sidebar .settings-list .form-group {
  color: #fff;
}
.sidebar .settings-list .form-group label {
  margin-bottom: 0;
}
.sidebar .settings-list .form-group label::before {
  border: 1px solid rgba(255,255,255,0.15);
  margin-bottom: 0.1rem;
}
.sidebar .chat-user {
  position: fixed;
  width: 100%;
  top: 55px;
  left: 0;
  padding-top: 0.75rem;
  padding-right: 1rem;
  background: rgba(0,0,0,0.5);
  z-index: 11;
}
.sidebar .chat-form {
  position: fixed;
  width: 100%;
  height: auto;
  display: block;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(0,0,0,0.7);
}
.sidebar .chat-form input {
  position: relative;
  display: block;
  background-image: -webkit-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background-image: -moz-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background-image: -o-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background-image: -ms-linear-gradient(top left, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  background-image: linear-gradient(to bottom right, rgba(255,255,255,0.05), rgba(255,255,255,0.15));
  width: 100%;
  height: auto;
  border: 0;
  padding: 1rem;
}
.sidebar .chat {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: block;
}
.sidebar .chat .chat-items {
  padding-top: 140px;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  display: block;
  padding-bottom: 3rem;
}
.sidebar .chat .chat-item {
  position: relative;
  display: block;
  padding: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}
.sidebar .chat .chat-item::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -7px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
.sidebar .chat .chat-item.-from {
  background: rgba(255,255,255,0.15);
  color: #fff;
  margin-right: 1rem;
}
.sidebar .chat .chat-item.-from::after {
  border-right: 7px solid rgba(255,255,255,0.15);
  left: -7px;
}
.sidebar .chat .chat-item.-to {
  background: rgba(236,110,173,0.9);
  color: #fff;
  margin-left: 1rem;
}
.sidebar .chat .chat-item.-to::after {
  border-left: 7px solid rgba(236,110,173,0.9);
  right: -7px;
}
.wizard,
.tabcontrol {
  display: block;
  width: 100%;
  overflow: hidden;
}
.wizard a,
.tabcontrol a {
  outline: 0;
}
.wizard ul,
.tabcontrol ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}
.wizard ul > li,
.tabcontrol ul > li {
  display: block;
  padding: 0;
}
.wizard > .steps .current-info,
.tabcontrol > .steps .current-info {
  position: absolute;
  left: -999em;
}
.wizard > .content > .title,
.tabcontrol > .content > .title {
  position: absolute;
  left: -999em;
}
.wizard > .steps {
  position: relative;
  display: block;
  width: 100%;
}
.wizard > .steps .number {
  font-weight: bold;
}
.wizard > .steps > ul > li {
  width: 25%;
}
.wizard.vertical > .steps {
  display: inline;
  float: left;
  width: 30%;
}
.wizard.vertical > .steps > ul > li {
  float: none;
  width: 100%;
}
.wizard.vertical > .content {
  display: inline;
  float: left;
  margin: 0;
  width: 65%;
}
.wizard.vertical > .actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%;
}
.wizard.vertical > .actions > ul > li {
  margin: 0 0 0 1em;
}
.wizard > .content {
  display: block;
  margin: 0;
  padding: 0;
  min-height: 15rem;
  position: relative;
  width: auto;
}
.wizard > .content > .body {
  position: absolute;
  width: 100%;
  height: auto;
}
.wizard > .content > .body.current {
  position: relative;
}
.wizard > .content > .body ul {
  list-style: disc !important;
}
.wizard > .content > .body ul > li {
  display: list-item;
}
.wizard > .content > .body > iframe {
  border: 0 none;
  width: 100%;
  height: 100%;
}
.wizard > .actions {
  position: relative;
  display: block;
  text-align: center;
  width: 100%;
}
.wizard > .actions > ul {
  display: block;
}
.wizard > .actions > ul > li {
  display: block;
  width: 50%;
}
.wizard > .actions > ul > li > a {
  padding: 1rem;
}
.wizard > .steps > ul > li,
.wizard > .actions > ul > li {
  float: left;
}
.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active {
  display: block;
  width: auto;
  padding: 1em 1em;
  text-decoration: none;
}
.wizard.-dark > .steps {
  border-bottom: 1px solid rgba(0,0,0,0.125);
}
.wizard.-dark > .steps li a,
.wizard.-dark > .steps li a:hover,
.wizard.-dark > .steps li a:active {
  border-top: 1px solid rgba(0,0,0,0.125);
  border-right: 1px solid rgba(0,0,0,0.125);
  border-left: 1px solid rgba(0,0,0,0.125);
  color: #fff;
}
.wizard.-dark > .steps li .number {
  color: rgba(255,255,255,0.5);
}
.wizard.-dark > .steps .disabled a,
.wizard.-dark > .steps .disabled a:hover,
.wizard.-dark > .steps .disabled a:active {
  background: rgba(0,0,0,0.125);
  color: rgba(255,255,255,0.5);
  cursor: default;
}
.wizard.-dark > .steps .current a,
.wizard.-dark > .steps .current a:hover,
.wizard.-dark > .steps .current a:active {
  background: rgba(0,0,0,0.5);
  cursor: default;
}
.wizard.-dark > .steps .done a,
.wizard.-dark > .steps .done a:hover,
.wizard.-dark > .steps .done a:active {
  background: rgba(0,0,0,0.25);
  color: rgba(255,255,255,0.85);
}
.wizard.-dark > .steps .error a,
.wizard.-dark > .steps .error a:hover,
.wizard.-dark > .steps .error a:active {
  background: #ff4136;
  color: #fff;
}
.wizard.-dark > .actions a,
.wizard.-dark > .actions a:hover,
.wizard.-dark > .actions a:active {
  background: rgba(0,0,0,0.5);
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -o-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -ms-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: linear-gradient(to bottom right, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  display: block;
  text-decoration: none;
}
.wizard.-dark > .actions .disabled a,
.wizard.-dark > .actions .disabled a:hover,
.wizard.-dark > .actions .disabled a:active {
  cursor: default;
  color: rgba(255,255,255,0.5);
  background: rgba(0,0,0,0.25);
  border-right: 1px solid rgba(0,0,0,0.125);
}
.wizard.-light > .steps {
  border-bottom: 1px solid rgba(255,255,255,0.05);
}
.wizard.-light > .steps li a,
.wizard.-light > .steps li a:hover,
.wizard.-light > .steps li a:active {
  border-top: 1px solid rgba(255,255,255,0.05);
  border-right: 1px solid rgba(255,255,255,0.05);
  border-left: 1px solid rgba(255,255,255,0.05);
  color: #fff;
}
.wizard.-light > .steps li .number {
  color: rgba(255,255,255,0.5);
}
.wizard.-light > .steps .disabled a,
.wizard.-light > .steps .disabled a:hover,
.wizard.-light > .steps .disabled a:active {
  background: rgba(255,255,255,0);
  color: rgba(255,255,255,0.5);
  cursor: default;
}
.wizard.-light > .steps .current a,
.wizard.-light > .steps .current a:hover,
.wizard.-light > .steps .current a:active {
  background: rgba(255,255,255,0.15);
  cursor: default;
}
.wizard.-light > .steps .done a,
.wizard.-light > .steps .done a:hover,
.wizard.-light > .steps .done a:active {
  background: rgba(255,255,255,0.05);
  color: rgba(255,255,255,0.85);
}
.wizard.-light > .steps .error a,
.wizard.-light > .steps .error a:hover,
.wizard.-light > .steps .error a:active {
  background: #ff4136;
  color: #fff;
}
.wizard.-light > .actions a,
.wizard.-light > .actions a:hover,
.wizard.-light > .actions a:active {
  background: rgba(255,255,255,0.5);
  background: -webkit-linear-gradient(bottom left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -moz-linear-gradient(bottom left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -o-linear-gradient(bottom left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -ms-linear-gradient(bottom left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: linear-gradient(to top right, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  display: block;
  text-decoration: none;
}
.wizard.-light > .actions .disabled a,
.wizard.-light > .actions .disabled a:hover,
.wizard.-light > .actions .disabled a:active {
  cursor: default;
  color: rgba(255,255,255,0.5);
  background: rgba(255,255,255,0.15);
  border-right: 1px solid rgba(255,255,255,0.05);
}
.tabcontrol > .steps {
  position: relative;
  display: block;
  width: 100%;
}
.tabcontrol > .steps > ul {
  position: relative;
  margin: 6px 0 0 0;
  top: 1px;
  z-index: 1;
}
.tabcontrol > .steps > ul > li {
  float: left;
  margin: 5px 2px 0 0;
  padding: 1px;
}
.tabcontrol > .steps > ul > li:hover {
  background: #edecec;
  border: 1px solid #bbb;
  padding: 0;
}
.tabcontrol > .steps > ul > li.current {
  background: #fff;
  border: 1px solid #bbb;
  border-bottom: 0 none;
  padding: 0 0 1px 0;
  margin-top: 0;
}
.tabcontrol > .steps > ul > li.current > a {
  padding: 15px 30px 10px 30px;
}
.tabcontrol > .steps > ul > li > a {
  color: #5f5f5f;
  display: inline-block;
  border: 0 none;
  margin: 0;
  padding: 10px 30px;
  text-decoration: none;
}
.tabcontrol > .content {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 35em;
  overflow: hidden;
  padding-top: 20px;
}
.tabcontrol > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}
.tabcontrol > .content > .body ul {
  list-style: disc !important;
}
.tabcontrol > .content > .body ul > li {
  display: list-item;
}
table thead tr,
.table thead tr {
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  background-size: 100%;
}
table thead th,
.table thead th {
  border-bottom-width: 1px;
}
table.-bordered,
.table.-bordered {
  border-width: 1px;
  border-style: solid;
}
table.-bordered th,
.table.-bordered th,
table.-bordered td,
.table.-bordered td {
  border-width: 1px;
  border-style: solid;
  -webkit-transition: background 0.3s ease, color 0.3s ease;
  -moz-transition: background 0.3s ease, color 0.3s ease;
  -o-transition: background 0.3s ease, color 0.3s ease;
  -ms-transition: background 0.3s ease, color 0.3s ease;
  transition: background 0.3s ease, color 0.3s ease;
}
table.-light,
.table.-light {
  background-image: -webkit-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background-image: -moz-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background-image: -o-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background-image: -ms-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background-image: linear-gradient(to bottom right, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
}
table.-light thead,
.table.-light thead {
  background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background-image: -moz-linear-gradient(top, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background-image: -o-linear-gradient(top, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background-image: -ms-linear-gradient(top, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background-image: linear-gradient(to bottom, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
}
table.-light th,
.table.-light th,
table.-light td,
.table.-light td {
  border-color: rgba(255,255,255,0.05);
}
table.-light.-bordered,
.table.-light.-bordered {
  border-color: rgba(255,255,255,0.15);
}
table.-light.-bordered th,
.table.-light.-bordered th,
table.-light.-bordered td,
.table.-light.-bordered td {
  border-color: rgba(255,255,255,0.15);
}
table.-light.-hovered tr:hover td,
.table.-light.-hovered tr:hover td {
  background-color: rgba(255,255,255,0.05);
}
table.-light.-striped tr:nth-child(2n+2),
.table.-light.-striped tr:nth-child(2n+2) {
  background-color: rgba(255,255,255,0.05);
}
table.-dark,
.table.-dark {
  background-image: -webkit-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -moz-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -o-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -ms-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: linear-gradient(to bottom right, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
}
table.-dark thead,
.table.-dark thead {
  background-image: -webkit-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -moz-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -o-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: -ms-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
}
table.-dark th,
.table.-dark th,
table.-dark td,
.table.-dark td {
  border-color: rgba(0,0,0,0.125);
}
table.-dark.-bordered,
.table.-dark.-bordered {
  border-color: rgba(0,0,0,0.25);
}
table.-dark.-bordered th,
.table.-dark.-bordered th,
table.-dark.-bordered td,
.table.-dark.-bordered td {
  border-color: rgba(0,0,0,0.25);
}
table.-dark.-hovered tr:hover td,
.table.-dark.-hovered tr:hover td {
  background-color: rgba(0,0,0,0.05);
}
table.-dark.-striped tr:nth-child(2n+2),
.table.-dark.-striped tr:nth-child(2n+2) {
  background-color: rgba(0,0,0,0.125);
}
table tr.-primary,
.table tr.-primary {
  background-color: rgba(236,110,173,0.33);
}
table tr.-secondary,
.table tr.-secondary {
  background-color: rgba(52,148,230,0.33);
}
table tr.-success,
.table tr.-success {
  background-color: rgba(61,153,112,0.33);
}
table tr.-danger,
.table tr.-danger {
  background-color: rgba(255,65,54,0.33);
}
table tr.-warning,
.table tr.-warning {
  background-color: rgba(255,133,27,0.33);
}
table tr.-info,
.table tr.-info {
  background-color: rgba(0,116,217,0.33);
}
table td.-user img,
.table td.-user img {
  width: 23px;
  height: 23px;
  float: left;
  border-radius: 3px;
  margin-right: 0.75rem;
}
table td.-user.-detailed img,
.table td.-user.-detailed img {
  width: 44px;
  height: 44px;
}
table td.-detailed span,
.table td.-detailed span {
  display: block;
  font-size: 80%;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
table td.-valign-middle,
.table td.-valign-middle {
  vertical-align: middle;
}
table td.-valign-top,
.table td.-valign-top {
  vertical-align: top;
}
table td.-valign-bottom,
.table td.-valign-bottom {
  vertical-align: bottom;
}
table td .progress,
.table td .progress {
  margin-bottom: 0.5rem;
}
.nav .nav-link {
  border-radius: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.nav .nav-link.active {
  -webkit-background-size: 200% 100%;
  -moz-background-size: 200% 100%;
  background-size: 200% 100%;
  background-position: 0% 0%;
}
.nav.-light {
  background: -webkit-linear-gradient(left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -moz-linear-gradient(left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -o-linear-gradient(left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -ms-linear-gradient(left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: linear-gradient(to right, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  border-color: rgba(255,255,255,0.05);
}
.nav.-light .nav-link {
  color: #fff;
}
.nav.-light .nav-link.active {
  background: rgba(255,255,255,0.15);
  background: -webkit-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -moz-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -o-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -ms-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: linear-gradient(to bottom right, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  border-color: rgba(255,255,255,0);
}
.nav.-light .nav-link.active:hover,
.nav.-light .nav-link.active:focus,
.nav.-light .nav-link.active:active {
  background: -webkit-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -moz-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -o-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -ms-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: linear-gradient(to bottom right, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  color: #fff;
  border-color: rgba(255,255,255,0.05);
}
.nav.-light .nav-link:hover,
.nav.-light .nav-link:focus,
.nav.-light .nav-link:active {
  background: rgba(255,255,255,0.05);
  border-color: rgba(255,255,255,0.05);
}
.nav.-dark {
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -moz-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -o-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -ms-linear-gradient(left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: linear-gradient(to right, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  border-color: rgba(0,0,0,0.125);
}
.nav.-dark .nav-link {
  color: #fff;
}
.nav.-dark .nav-link.active {
  background: rgba(0,0,0,0.25);
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -o-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -ms-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: linear-gradient(to bottom right, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  border-color: rgba(0,0,0,0);
}
.nav.-dark .nav-link.active:hover,
.nav.-dark .nav-link.active:focus,
.nav.-dark .nav-link.active:active {
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -o-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -ms-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: linear-gradient(to bottom right, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  color: #fff;
  border-color: rgba(0,0,0,0.125);
}
.nav.-dark .nav-link:hover,
.nav.-dark .nav-link:focus,
.nav.-dark .nav-link:active {
  background: rgba(0,0,0,0.125);
  border-color: rgba(0,0,0,0.125);
}
.nav.-primary {
  background: -webkit-linear-gradient(left, rgba(236,110,173,0.5), rgba(236,110,173,0.25));
  background: -moz-linear-gradient(left, rgba(236,110,173,0.5), rgba(236,110,173,0.25));
  background: -o-linear-gradient(left, rgba(236,110,173,0.5), rgba(236,110,173,0.25));
  background: -ms-linear-gradient(left, rgba(236,110,173,0.5), rgba(236,110,173,0.25));
  background: linear-gradient(to right, rgba(236,110,173,0.5), rgba(236,110,173,0.25));
  border-color: rgba(236,110,173,0.25);
}
.nav.-primary .nav-link {
  color: #fff;
}
.nav.-primary .nav-link.active {
  background: rgba(236,110,173,0.25);
  background: -webkit-linear-gradient(top left, rgba(236,110,173,0.25), rgba(236,110,173,0.5));
  background: -moz-linear-gradient(top left, rgba(236,110,173,0.25), rgba(236,110,173,0.5));
  background: -o-linear-gradient(top left, rgba(236,110,173,0.25), rgba(236,110,173,0.5));
  background: -ms-linear-gradient(top left, rgba(236,110,173,0.25), rgba(236,110,173,0.5));
  background: linear-gradient(to bottom right, rgba(236,110,173,0.25), rgba(236,110,173,0.5));
  border-color: rgba(236,110,173,0);
}
.nav.-primary .nav-link.active:hover,
.nav.-primary .nav-link.active:focus,
.nav.-primary .nav-link.active:active {
  background: -webkit-linear-gradient(top left, rgba(236,110,173,0.25), rgba(236,110,173,0.5));
  background: -moz-linear-gradient(top left, rgba(236,110,173,0.25), rgba(236,110,173,0.5));
  background: -o-linear-gradient(top left, rgba(236,110,173,0.25), rgba(236,110,173,0.5));
  background: -ms-linear-gradient(top left, rgba(236,110,173,0.25), rgba(236,110,173,0.5));
  background: linear-gradient(to bottom right, rgba(236,110,173,0.25), rgba(236,110,173,0.5));
  color: #fff;
  border-color: rgba(236,110,173,0.5);
}
.nav.-primary .nav-link:hover,
.nav.-primary .nav-link:focus,
.nav.-primary .nav-link:active {
  background: rgba(236,110,173,0.1);
  border-color: rgba(236,110,173,0.5);
}
.nav.-secondary {
  background: -webkit-linear-gradient(left, rgba(52,148,230,0.5), rgba(52,148,230,0.25));
  background: -moz-linear-gradient(left, rgba(52,148,230,0.5), rgba(52,148,230,0.25));
  background: -o-linear-gradient(left, rgba(52,148,230,0.5), rgba(52,148,230,0.25));
  background: -ms-linear-gradient(left, rgba(52,148,230,0.5), rgba(52,148,230,0.25));
  background: linear-gradient(to right, rgba(52,148,230,0.5), rgba(52,148,230,0.25));
  border-color: rgba(52,148,230,0.25);
}
.nav.-secondary .nav-link {
  color: #fff;
}
.nav.-secondary .nav-link.active {
  background: rgba(52,148,230,0.25);
  background: -webkit-linear-gradient(top left, rgba(52,148,230,0.25), rgba(52,148,230,0.5));
  background: -moz-linear-gradient(top left, rgba(52,148,230,0.25), rgba(52,148,230,0.5));
  background: -o-linear-gradient(top left, rgba(52,148,230,0.25), rgba(52,148,230,0.5));
  background: -ms-linear-gradient(top left, rgba(52,148,230,0.25), rgba(52,148,230,0.5));
  background: linear-gradient(to bottom right, rgba(52,148,230,0.25), rgba(52,148,230,0.5));
  border-color: rgba(52,148,230,0);
}
.nav.-secondary .nav-link.active:hover,
.nav.-secondary .nav-link.active:focus,
.nav.-secondary .nav-link.active:active {
  background: -webkit-linear-gradient(top left, rgba(52,148,230,0.25), rgba(52,148,230,0.5));
  background: -moz-linear-gradient(top left, rgba(52,148,230,0.25), rgba(52,148,230,0.5));
  background: -o-linear-gradient(top left, rgba(52,148,230,0.25), rgba(52,148,230,0.5));
  background: -ms-linear-gradient(top left, rgba(52,148,230,0.25), rgba(52,148,230,0.5));
  background: linear-gradient(to bottom right, rgba(52,148,230,0.25), rgba(52,148,230,0.5));
  color: #fff;
  border-color: rgba(52,148,230,0.5);
}
.nav.-secondary .nav-link:hover,
.nav.-secondary .nav-link:focus,
.nav.-secondary .nav-link:active {
  background: rgba(52,148,230,0.1);
  border-color: rgba(52,148,230,0.5);
}
.nav.-success {
  background: -webkit-linear-gradient(left, rgba(61,153,112,0.5), rgba(61,153,112,0.25));
  background: -moz-linear-gradient(left, rgba(61,153,112,0.5), rgba(61,153,112,0.25));
  background: -o-linear-gradient(left, rgba(61,153,112,0.5), rgba(61,153,112,0.25));
  background: -ms-linear-gradient(left, rgba(61,153,112,0.5), rgba(61,153,112,0.25));
  background: linear-gradient(to right, rgba(61,153,112,0.5), rgba(61,153,112,0.25));
  border-color: rgba(61,153,112,0.25);
}
.nav.-success .nav-link {
  color: #fff;
}
.nav.-success .nav-link.active {
  background: rgba(61,153,112,0.25);
  background: -webkit-linear-gradient(top left, rgba(61,153,112,0.25), rgba(61,153,112,0.5));
  background: -moz-linear-gradient(top left, rgba(61,153,112,0.25), rgba(61,153,112,0.5));
  background: -o-linear-gradient(top left, rgba(61,153,112,0.25), rgba(61,153,112,0.5));
  background: -ms-linear-gradient(top left, rgba(61,153,112,0.25), rgba(61,153,112,0.5));
  background: linear-gradient(to bottom right, rgba(61,153,112,0.25), rgba(61,153,112,0.5));
  border-color: rgba(61,153,112,0);
}
.nav.-success .nav-link.active:hover,
.nav.-success .nav-link.active:focus,
.nav.-success .nav-link.active:active {
  background: -webkit-linear-gradient(top left, rgba(61,153,112,0.25), rgba(61,153,112,0.5));
  background: -moz-linear-gradient(top left, rgba(61,153,112,0.25), rgba(61,153,112,0.5));
  background: -o-linear-gradient(top left, rgba(61,153,112,0.25), rgba(61,153,112,0.5));
  background: -ms-linear-gradient(top left, rgba(61,153,112,0.25), rgba(61,153,112,0.5));
  background: linear-gradient(to bottom right, rgba(61,153,112,0.25), rgba(61,153,112,0.5));
  color: #fff;
  border-color: rgba(61,153,112,0.5);
}
.nav.-success .nav-link:hover,
.nav.-success .nav-link:focus,
.nav.-success .nav-link:active {
  background: rgba(61,153,112,0.1);
  border-color: rgba(61,153,112,0.5);
}
.nav.-danger {
  background: -webkit-linear-gradient(left, rgba(255,65,54,0.5), rgba(255,65,54,0.25));
  background: -moz-linear-gradient(left, rgba(255,65,54,0.5), rgba(255,65,54,0.25));
  background: -o-linear-gradient(left, rgba(255,65,54,0.5), rgba(255,65,54,0.25));
  background: -ms-linear-gradient(left, rgba(255,65,54,0.5), rgba(255,65,54,0.25));
  background: linear-gradient(to right, rgba(255,65,54,0.5), rgba(255,65,54,0.25));
  border-color: rgba(255,65,54,0.25);
}
.nav.-danger .nav-link {
  color: #fff;
}
.nav.-danger .nav-link.active {
  background: rgba(255,65,54,0.25);
  background: -webkit-linear-gradient(top left, rgba(255,65,54,0.25), rgba(255,65,54,0.5));
  background: -moz-linear-gradient(top left, rgba(255,65,54,0.25), rgba(255,65,54,0.5));
  background: -o-linear-gradient(top left, rgba(255,65,54,0.25), rgba(255,65,54,0.5));
  background: -ms-linear-gradient(top left, rgba(255,65,54,0.25), rgba(255,65,54,0.5));
  background: linear-gradient(to bottom right, rgba(255,65,54,0.25), rgba(255,65,54,0.5));
  border-color: rgba(255,65,54,0);
}
.nav.-danger .nav-link.active:hover,
.nav.-danger .nav-link.active:focus,
.nav.-danger .nav-link.active:active {
  background: -webkit-linear-gradient(top left, rgba(255,65,54,0.25), rgba(255,65,54,0.5));
  background: -moz-linear-gradient(top left, rgba(255,65,54,0.25), rgba(255,65,54,0.5));
  background: -o-linear-gradient(top left, rgba(255,65,54,0.25), rgba(255,65,54,0.5));
  background: -ms-linear-gradient(top left, rgba(255,65,54,0.25), rgba(255,65,54,0.5));
  background: linear-gradient(to bottom right, rgba(255,65,54,0.25), rgba(255,65,54,0.5));
  color: #fff;
  border-color: rgba(255,65,54,0.5);
}
.nav.-danger .nav-link:hover,
.nav.-danger .nav-link:focus,
.nav.-danger .nav-link:active {
  background: rgba(255,65,54,0.1);
  border-color: rgba(255,65,54,0.5);
}
.nav.-warning {
  background: -webkit-linear-gradient(left, rgba(255,133,27,0.5), rgba(255,133,27,0.25));
  background: -moz-linear-gradient(left, rgba(255,133,27,0.5), rgba(255,133,27,0.25));
  background: -o-linear-gradient(left, rgba(255,133,27,0.5), rgba(255,133,27,0.25));
  background: -ms-linear-gradient(left, rgba(255,133,27,0.5), rgba(255,133,27,0.25));
  background: linear-gradient(to right, rgba(255,133,27,0.5), rgba(255,133,27,0.25));
  border-color: rgba(255,133,27,0.25);
}
.nav.-warning .nav-link {
  color: #fff;
}
.nav.-warning .nav-link.active {
  background: rgba(255,133,27,0.25);
  background: -webkit-linear-gradient(top left, rgba(255,133,27,0.25), rgba(255,133,27,0.5));
  background: -moz-linear-gradient(top left, rgba(255,133,27,0.25), rgba(255,133,27,0.5));
  background: -o-linear-gradient(top left, rgba(255,133,27,0.25), rgba(255,133,27,0.5));
  background: -ms-linear-gradient(top left, rgba(255,133,27,0.25), rgba(255,133,27,0.5));
  background: linear-gradient(to bottom right, rgba(255,133,27,0.25), rgba(255,133,27,0.5));
  border-color: rgba(255,133,27,0);
}
.nav.-warning .nav-link.active:hover,
.nav.-warning .nav-link.active:focus,
.nav.-warning .nav-link.active:active {
  background: -webkit-linear-gradient(top left, rgba(255,133,27,0.25), rgba(255,133,27,0.5));
  background: -moz-linear-gradient(top left, rgba(255,133,27,0.25), rgba(255,133,27,0.5));
  background: -o-linear-gradient(top left, rgba(255,133,27,0.25), rgba(255,133,27,0.5));
  background: -ms-linear-gradient(top left, rgba(255,133,27,0.25), rgba(255,133,27,0.5));
  background: linear-gradient(to bottom right, rgba(255,133,27,0.25), rgba(255,133,27,0.5));
  color: #fff;
  border-color: rgba(255,133,27,0.5);
}
.nav.-warning .nav-link:hover,
.nav.-warning .nav-link:focus,
.nav.-warning .nav-link:active {
  background: rgba(255,133,27,0.1);
  border-color: rgba(255,133,27,0.5);
}
.nav.-info {
  background: -webkit-linear-gradient(left, rgba(0,116,217,0.5), rgba(0,116,217,0.25));
  background: -moz-linear-gradient(left, rgba(0,116,217,0.5), rgba(0,116,217,0.25));
  background: -o-linear-gradient(left, rgba(0,116,217,0.5), rgba(0,116,217,0.25));
  background: -ms-linear-gradient(left, rgba(0,116,217,0.5), rgba(0,116,217,0.25));
  background: linear-gradient(to right, rgba(0,116,217,0.5), rgba(0,116,217,0.25));
  border-color: rgba(0,116,217,0.25);
}
.nav.-info .nav-link {
  color: #fff;
}
.nav.-info .nav-link.active {
  background: rgba(0,116,217,0.25);
  background: -webkit-linear-gradient(top left, rgba(0,116,217,0.25), rgba(0,116,217,0.5));
  background: -moz-linear-gradient(top left, rgba(0,116,217,0.25), rgba(0,116,217,0.5));
  background: -o-linear-gradient(top left, rgba(0,116,217,0.25), rgba(0,116,217,0.5));
  background: -ms-linear-gradient(top left, rgba(0,116,217,0.25), rgba(0,116,217,0.5));
  background: linear-gradient(to bottom right, rgba(0,116,217,0.25), rgba(0,116,217,0.5));
  border-color: rgba(0,116,217,0);
}
.nav.-info .nav-link.active:hover,
.nav.-info .nav-link.active:focus,
.nav.-info .nav-link.active:active {
  background: -webkit-linear-gradient(top left, rgba(0,116,217,0.25), rgba(0,116,217,0.5));
  background: -moz-linear-gradient(top left, rgba(0,116,217,0.25), rgba(0,116,217,0.5));
  background: -o-linear-gradient(top left, rgba(0,116,217,0.25), rgba(0,116,217,0.5));
  background: -ms-linear-gradient(top left, rgba(0,116,217,0.25), rgba(0,116,217,0.5));
  background: linear-gradient(to bottom right, rgba(0,116,217,0.25), rgba(0,116,217,0.5));
  color: #fff;
  border-color: rgba(0,116,217,0.5);
}
.nav.-info .nav-link:hover,
.nav.-info .nav-link:focus,
.nav.-info .nav-link:active {
  background: rgba(0,116,217,0.1);
  border-color: rgba(0,116,217,0.5);
}
.tag {
  padding: 0.33rem 0.5rem;
  border-radius: 2px;
}
.tag.-dark {
  background: rgba(0,0,0,0.5);
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -o-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: -ms-linear-gradient(top left, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
  background: linear-gradient(to bottom right, rgba(0,0,0,0.5), rgba(0,0,0,0.25));
}
.tag.-light {
  background: rgba(255,255,255,0.5);
  background: -webkit-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -moz-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -o-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: -ms-linear-gradient(top left, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
  background: linear-gradient(to bottom right, rgba(255,255,255,0.15), rgba(255,255,255,0.05));
}
.tag.-primary {
  background: #ec6ead;
  background: -webkit-linear-gradient(top left, rgba(236,110,173,0.8), rgba(236,110,173,0.4));
  background: -moz-linear-gradient(top left, rgba(236,110,173,0.8), rgba(236,110,173,0.4));
  background: -o-linear-gradient(top left, rgba(236,110,173,0.8), rgba(236,110,173,0.4));
  background: -ms-linear-gradient(top left, rgba(236,110,173,0.8), rgba(236,110,173,0.4));
  background: linear-gradient(to bottom right, rgba(236,110,173,0.8), rgba(236,110,173,0.4));
}
.tag.-secondary {
  background: #3494e6;
  background: -webkit-linear-gradient(top left, rgba(52,148,230,0.8), rgba(52,148,230,0.4));
  background: -moz-linear-gradient(top left, rgba(52,148,230,0.8), rgba(52,148,230,0.4));
  background: -o-linear-gradient(top left, rgba(52,148,230,0.8), rgba(52,148,230,0.4));
  background: -ms-linear-gradient(top left, rgba(52,148,230,0.8), rgba(52,148,230,0.4));
  background: linear-gradient(to bottom right, rgba(52,148,230,0.8), rgba(52,148,230,0.4));
}
.tag.-success {
  background: #3d9970;
  background: -webkit-linear-gradient(top left, rgba(61,153,112,0.8), rgba(61,153,112,0.4));
  background: -moz-linear-gradient(top left, rgba(61,153,112,0.8), rgba(61,153,112,0.4));
  background: -o-linear-gradient(top left, rgba(61,153,112,0.8), rgba(61,153,112,0.4));
  background: -ms-linear-gradient(top left, rgba(61,153,112,0.8), rgba(61,153,112,0.4));
  background: linear-gradient(to bottom right, rgba(61,153,112,0.8), rgba(61,153,112,0.4));
}
.tag.-danger {
  background: #ff4136;
  background: -webkit-linear-gradient(top left, rgba(255,65,54,0.8), rgba(255,65,54,0.4));
  background: -moz-linear-gradient(top left, rgba(255,65,54,0.8), rgba(255,65,54,0.4));
  background: -o-linear-gradient(top left, rgba(255,65,54,0.8), rgba(255,65,54,0.4));
  background: -ms-linear-gradient(top left, rgba(255,65,54,0.8), rgba(255,65,54,0.4));
  background: linear-gradient(to bottom right, rgba(255,65,54,0.8), rgba(255,65,54,0.4));
}
.tag.-warning {
  background: #ff851b;
  background: -webkit-linear-gradient(top left, rgba(255,133,27,0.8), rgba(255,133,27,0.4));
  background: -moz-linear-gradient(top left, rgba(255,133,27,0.8), rgba(255,133,27,0.4));
  background: -o-linear-gradient(top left, rgba(255,133,27,0.8), rgba(255,133,27,0.4));
  background: -ms-linear-gradient(top left, rgba(255,133,27,0.8), rgba(255,133,27,0.4));
  background: linear-gradient(to bottom right, rgba(255,133,27,0.8), rgba(255,133,27,0.4));
}
.tag.-info {
  background: #0074d9;
  background: -webkit-linear-gradient(top left, rgba(0,116,217,0.8), rgba(0,116,217,0.4));
  background: -moz-linear-gradient(top left, rgba(0,116,217,0.8), rgba(0,116,217,0.4));
  background: -o-linear-gradient(top left, rgba(0,116,217,0.8), rgba(0,116,217,0.4));
  background: -ms-linear-gradient(top left, rgba(0,116,217,0.8), rgba(0,116,217,0.4));
  background: linear-gradient(to bottom right, rgba(0,116,217,0.8), rgba(0,116,217,0.4));
}
pre .tag,
code .tag {
  padding: 0;
  border-radius: 0;
  font-size: inherit;
  line-height: inherit;
}
@font-face {
  font-family: "summernote";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/summernote/summernote.eot?ad8d7e2d177d2473aecd9b35d16211fb");
  src: url("../../fonts/summernote/summernote.eot?#iefix") format("embedded-opentype"), url("../../fonts/summernote/summernote.woff?ad8d7e2d177d2473aecd9b35d16211fb") format("woff"), url("../../fonts/summernote/summernote.ttf?ad8d7e2d177d2473aecd9b35d16211fb") format("truetype");
}
[class^="note-icon-"]:before,
[class*=" note-icon-"]:before {
  display: inline-block;
  font: normal normal normal 14px summernote;
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  text-decoration: inherit;
  text-rendering: auto;
  text-transform: none;
  vertical-align: middle;
  speak: none;
  -moz-osx-font-smoothing: grayscale;
}
.note-icon-align-center:before {
  content: "\f101";
}
.note-icon-align-indent:before {
  content: "\f102";
}
.note-icon-align-justify:before {
  content: "\f103";
}
.note-icon-align-left:before {
  content: "\f104";
}
.note-icon-align-outdent:before {
  content: "\f105";
}
.note-icon-align-right:before {
  content: "\f106";
}
.note-icon-align:before {
  content: "\f107";
}
.note-icon-arrows-alt:before {
  content: "\f108";
}
.note-icon-bold:before {
  content: "\f109";
}
.note-icon-caret:before {
  content: "\f10a";
}
.note-icon-chain-broken:before {
  content: "\f10b";
}
.note-icon-circle:before {
  content: "\f10c";
}
.note-icon-close:before {
  content: "\f10d";
}
.note-icon-code:before {
  content: "\f10e";
}
.note-icon-eraser:before {
  content: "\f10f";
}
.note-icon-font:before {
  content: "\f110";
}
.note-icon-frame:before {
  content: "\f111";
}
.note-icon-italic:before {
  content: "\f112";
}
.note-icon-link:before {
  content: "\f113";
}
.note-icon-magic:before {
  content: "\f114";
}
.note-icon-menu-check:before {
  content: "\f115";
}
.note-icon-minus:before {
  content: "\f116";
}
.note-icon-orderedlist:before {
  content: "\f117";
}
.note-icon-pencil:before {
  content: "\f118";
}
.note-icon-picture:before {
  content: "\f119";
}
.note-icon-question:before {
  content: "\f11a";
}
.note-icon-redo:before {
  content: "\f11b";
}
.note-icon-special-character:before {
  content: "\f11c";
}
.note-icon-square:before {
  content: "\f11d";
}
.note-icon-strikethrough:before {
  content: "\f11e";
}
.note-icon-subscript:before {
  content: "\f11f";
}
.note-icon-summernote:before {
  content: "\f120";
}
.note-icon-superscript:before {
  content: "\f121";
}
.note-icon-table:before {
  content: "\f122";
}
.note-icon-text-height:before {
  content: "\f123";
}
.note-icon-trash:before {
  content: "\f124";
}
.note-icon-underline:before {
  content: "\f125";
}
.note-icon-undo:before {
  content: "\f126";
}
.note-icon-unorderedlist:before {
  content: "\f127";
}
.note-icon-video:before {
  content: "\f128";
}
.note-editor {
  position: relative;
}
.note-editor .note-dropzone {
  position: absolute;
  display: none;
  z-index: 100;
  color: #fff;
  opacity: 0.95;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=95)";
  filter: alpha(opacity=95);
  width: 100%;
  height: 100%;
}
.note-editor .note-dropzone .note-dropzone-message {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 28px;
}
.note-editor .note-dropzone.hover {
  color: rgba(255,255,255,0.5);
}
.note-editor.dragover .note-dropzone {
  display: table;
}
.note-editor .note-editing-area {
  position: relative;
  width: 100%;
}
.note-editor .note-editing-area .note-editable {
  outline: none;
}
.note-editor .note-editing-area .note-editable sup {
  vertical-align: super;
}
.note-editor .note-editing-area .note-editable sub {
  vertical-align: sub;
}
.note-editor.note-frame.codeview .note-editing-area .note-editable {
  display: none;
}
.note-editor.note-frame.codeview .note-editing-area .note-codable {
  display: block;
}
.note-editor.note-frame .note-editing-area {
  overflow: hidden;
}
.note-editor.note-frame .note-editing-area .note-editable {
  background: rgba(0,0,0,0.25);
  background: -webkit-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -moz-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -o-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: -ms-linear-gradient(top left, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  background: linear-gradient(to bottom right, rgba(0,0,0,0.25), rgba(0,0,0,0.125));
  color: #fff;
  padding: 1rem;
  height: 15rem;
  overflow: auto;
  font-family: "Helvetica", "Arial", "sans-serif";
}
.note-editor.note-frame .note-editing-area .note-editable[contenteditable="false"] {
  background-color: rgba(0,0,0,0.25);
}
.note-editor.note-frame .note-editing-area .note-codable {
  display: none;
  width: 100%;
  padding: 10px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: Menlo, Monaco, monospace, sans-serif;
  font-size: 14px;
  color: #ccc;
  background-color: #222;
  resize: none;
  margin-bottom: 0;
}
@include box-sizing(border-box);
@include rounded(0);
.note-editor.note-frame.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  z-index: 1050;
}
.note-editor.note-frame.fullscreen .note-toolbar {
  margin-top: 70px;
  background-color: rgba(0,0,0,0.8);
}
.note-editor.note-frame.fullscreen .note-editable {
  background-color: rgba(0,0,0,0.7);
}
.note-editor.note-frame.fullscreen .note-toolbar,
.note-editor.note-frame.fullscreen .note-editable {
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}
.note-editor.note-frame.fullscreen .note-resizebar {
  display: none;
}
.note-editor.note-frame .note-statusbar {
  background-color: rgba(0,0,0,0.25);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.note-editor.note-frame .note-statusbar .note-resizebar {
  padding-top: 1px;
  height: 8px;
  width: 100%;
  cursor: ns-resize;
}
.note-editor.note-frame .note-statusbar .note-resizebar .note-icon-bar {
  width: 20px;
  margin: 1px auto;
  border-top: 1px solid rgba(0,0,0,0.125);
}
.note-editor.note-frame .note-placeholder {
  padding: 10px;
}
.note-popover.popover {
  display: none;
  max-width: none;
}
.note-popover.popover .popover-content a {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.note-popover.popover .arrow {
  left: 20px !important;
}
.note-popover .popover-content,
.note-toolbar {
  padding: 1rem;
  margin-bottom: -0.33rem;
}
.note-popover .popover-content > .btn-group,
.note-toolbar > .btn-group {
  margin-left: 0;
  margin-right: 0;
}
.note-popover .popover-content .btn-group,
.note-toolbar .btn-group {
  display: inline-block;
  position: relative;
  margin-right: 0.5rem;
  margin-bottom: 0.33rem;
}
.note-popover .popover-content .btn-group .btn,
.note-toolbar .btn-group .btn {
  padding-left: 1rem;
  padding-right: 1rem;
}
.note-popover .popover-content .btn-group .note-table,
.note-toolbar .btn-group .note-table {
  min-width: 0;
  padding: 1rem;
}
.note-popover .popover-content .btn-group .note-table .note-dimension-picker,
.note-toolbar .btn-group .note-table .note-dimension-picker {
  font-size: 18px;
}
.note-popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher,
.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher {
  position: absolute !important;
  z-index: 3;
  width: 10em;
  height: 10em;
  cursor: pointer;
}
.note-popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted,
.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted {
  border: 1px solid rgba(255,255,255,0.15);
  position: relative !important;
  z-index: 1;
  width: 5em;
  height: 5em;
}
.note-popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted,
.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted {
  border: 1px solid rgba(255,255,255,0.85);
  position: absolute !important;
  z-index: 2;
  width: 1em;
  height: 1em;
}
.note-popover .popover-content .btn-group .note-dimension-display,
.note-toolbar .btn-group .note-dimension-display {
  padding-top: 0.5rem;
  color: #fff;
}
.note-popover .popover-content .note-style .dropdown-menu,
.note-toolbar .note-style .dropdown-menu {
  padding: 1rem;
  color: #fff;
}
.note-popover .popover-content .note-style .dropdown-menu pre,
.note-toolbar .note-style .dropdown-menu pre {
  color: #fff;
}
.note-popover .popover-content .note-style .dropdown-menu blockquote,
.note-toolbar .note-style .dropdown-menu blockquote {
  padding: 0;
}
.note-popover .popover-content .note-style h1,
.note-toolbar .note-style h1,
.note-popover .popover-content .note-style h2,
.note-toolbar .note-style h2,
.note-popover .popover-content .note-style h3,
.note-toolbar .note-style h3,
.note-popover .popover-content .note-style h4,
.note-toolbar .note-style h4,
.note-popover .popover-content .note-style h5,
.note-toolbar .note-style h5,
.note-popover .popover-content .note-style h6,
.note-toolbar .note-style h6,
.note-popover .popover-content .note-style blockquote,
.note-toolbar .note-style blockquote,
.note-popover .popover-content .note-style pre,
.note-toolbar .note-style pre,
.note-popover .popover-content .note-style p,
.note-toolbar .note-style p {
  margin: 0;
}
.note-popover .popover-content .note-color .dropdown-toggle,
.note-toolbar .note-color .dropdown-toggle {
  width: 20px;
  padding-left: 5px;
}
.note-popover .popover-content .note-color .dropdown-menu,
.note-toolbar .note-color .dropdown-menu {
  min-width: 360px;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group,
.note-toolbar .note-color .dropdown-menu .btn-group {
  margin: 0;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group:first-child,
.note-toolbar .note-color .dropdown-menu .btn-group:first-child {
  margin: 0 5px;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-palette-title,
.note-toolbar .note-color .dropdown-menu .btn-group .note-palette-title {
  padding: 0.5rem 0;
  text-align: center;
  color: #fff;
  border-bottom: 1px solid rgba(255,255,255,0.15);
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset {
  font-size: 12px;
  padding: 6px 0;
  cursor: pointer;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-color-row,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-row {
  height: 20px;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset:hover,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset:hover {
  background: rgba(0,0,0,0.25);
}
.note-popover .popover-content .note-para .dropdown-menu,
.note-toolbar .note-para .dropdown-menu {
  min-width: 216px;
  padding: 1rem;
}
.note-popover .popover-content .note-para .dropdown-menu>div:first-child,
.note-toolbar .note-para .dropdown-menu>div:first-child {
  margin-right: 5px;
}
.note-popover .popover-content .note-btn::after,
.note-toolbar .note-btn::after {
  display: none;
}
.note-popover .popover-content .dropdown-menu,
.note-toolbar .dropdown-menu {
  padding: 0.5rem;
  min-width: 90px;
}
.note-popover .popover-content .dropdown-menu.right,
.note-toolbar .dropdown-menu.right {
  right: 0;
  left: auto;
}
.note-popover .popover-content .dropdown-menu.right::before,
.note-toolbar .dropdown-menu.right::before {
  right: 9px;
  left: auto !important;
}
.note-popover .popover-content .dropdown-menu.right::after,
.note-toolbar .dropdown-menu.right::after {
  right: 10px;
  left: auto !important;
}
.note-popover .popover-content .dropdown-menu.note-check li a i,
.note-toolbar .dropdown-menu.note-check li a i {
  color: #00bfff;
  visibility: hidden;
}
.note-popover .popover-content .dropdown-menu.note-check li a.checked i,
.note-toolbar .dropdown-menu.note-check li a.checked i {
  visibility: visible;
}
.note-popover .popover-content .note-fontsize-10,
.note-toolbar .note-fontsize-10 {
  font-size: 10px;
}
.note-popover .popover-content .note-color-palette,
.note-toolbar .note-color-palette {
  line-height: 1;
  margin-right: 0.25rem;
}
.note-popover .popover-content .note-color-palette div .note-color-btn,
.note-toolbar .note-color-palette div .note-color-btn {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
}
.note-popover .popover-content .note-color-palette div .note-color-btn:hover,
.note-toolbar .note-color-palette div .note-color-btn:hover {
  border: 1px solid #000;
}
.note-dialog>div {
  display: none;
}
.note-dialog .form-group {
  margin-left: 0;
  margin-right: 0;
}
.note-dialog .note-modal-form {
  margin: 0;
}
.note-dialog .note-image-dialog .note-dropzone {
  min-height: 100px;
  font-size: 30px;
  line-height: 4;
  color: #d3d3d3;
  text-align: center;
  border: 4px dashed #d3d3d3;
  margin-bottom: 10px;
}
.note-placeholder {
  position: absolute;
  display: none;
  color: #808080;
}
.note-handle .note-control-selection {
  position: absolute;
  display: none;
  border: 1px solid #000;
}
.note-handle .note-control-selection>div position: absolute,
.note-handle .note-control-selection .note-control-selection-bg {
  width: 100%;
  height: 100%;
  background-color: #000;
}
@include opacity(0.3);
.note-handle .note-control-selection .note-control-handle,
.note-handle .note-control-selection .note-control-holder,
.note-handle .note-control-selection .note-control-sizing {
  width: 7px;
  height: 7px;
  border: 1px solid #000;
}
.note-handle .note-control-selection .note-control-sizing {
  background-color: #fff;
}
.note-handle .note-control-selection .note-control-nw {
  top: -5px;
  left: -5px;
  border-right: none;
  border-bottom: none;
}
.note-handle .note-control-selection .note-control-ne {
  top: -5px;
  right: -5px;
  border-bottom: none;
  border-left: none;
}
.note-handle .note-control-selection .note-control-sw {
  bottom: -5px;
  left: -5px;
  border-top: none;
  border-right: none;
}
.note-handle .note-control-selection .note-control-se {
  right: -5px;
  bottom: -5px;
  cursor: se-resize;
}
.note-handle .note-control-selection .note-control-se.note-control-holder {
  cursor: default;
  border-top: none;
  border-left: none;
}
.note-handle .note-control-selection .note-control-selection-info {
  right: 0;
  bottom: 0;
  padding: 5px;
  margin: 5px;
  color: #fff;
  background-color: #000;
  font-size: 12px;
}
@include rounded(5px);
@include opacity(0.7);
.note-hint-popover {
  min-width: 100px;
  padding: 2px;
}
.note-hint-popover .popover-content {
  padding: 3px;
  max-height: 150px;
  overflow: auto;
}
.note-hint-popover .popover-content .note-hint-group .note-hint-item {
  display: block !important;
  padding: 3px;
}
.note-hint-popover .popover-content .note-hint-group .note-hint-item.active,
.note-hint-popover .popover-content .note-hint-group .note-hint-item:hover {
  display: block;
  clear: both;
  font-weight: 400;
  line-height: 1.4;
  color: #fff;
  white-space: nowrap;
  text-decoration: none;
  background-color: #ec6ead;
  outline: 0;
  cursor: pointer;
}
